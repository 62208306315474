import { useState, useEffect } from 'react'
import { HiPlus } from 'react-icons/hi'
import Card from 'components/card'
import { FaRegDotCircle } from 'react-icons/fa'
import { GrUpdate } from 'react-icons/gr'
import ApiCaller from 'common/services/apiServices'
import apiConfig from 'common/config/apiConfig'
import { useToaster } from 'common/Toaster'
import appConstants from 'common/config/appConstants'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import CustomMultiSelect from 'common/CustomMultiSelect'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { FaCircleExclamation } from 'react-icons/fa6'
import LabelCard from './LabelCard'
import CustomSelectLabel from 'common/CustomSelectLabel'
import CustomSelect from 'common/CustomSelect'
import { TbProgressAlert } from 'react-icons/tb'
import io from 'socket.io-client'
import PaginationSkeleton from 'components/skeletons/PaginationSkeleton'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
} from '@chakra-ui/modal'
const { TOAST, REQUIRED, BUTTON_TEXT, LABEL, TABLE_HEADER, NO_DATA } =
  appConstants
const apiService = ApiCaller()
/**
 * Generates a string describing the details of an application issue.
 *
 * @param {Object} row - The row object containing issue details
 * @return {string} The formatted string describing the issue details
 */
const issueDetails = (row) => {
  const value = moment(row?.createdAt)
  const date = value.fromNow()
  const totalToken =
    row?.totalCompletionToken || 0 - +row?.totalPromptToken || 0
  const totalPrice = (row?.totalPrice ?? 0).toFixed(3)
  return `#${row?.issueId} opened ${date} by ${row?.prDetails?.issueAuthor} ${
    row?.prDetails?.status !== appConstants?.ISSUE_STATUS?.failed
      ? `(Token Consumed: ${totalToken || 0} -> $${totalPrice})`
      : ''
  }`
}

const ApplicationIssuesList = () => {
  const { id } = useParams()
  const [pageIndex, setPageIndex] = useState(0)
  const [modalHeader, setModalHeader] = useState('Add')
  const [issue_title, setIssue_title] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const [titleError, setTitleError] = useState('')
  const [description, setDescription] = useState('')
  const [labelPage, setLabelPage] = useState(false)
  const [issuesList, setIssuesList] = useState([])
  const [labelList, setLabelList] = useState([])
  const [logId, setLogId] = useState()
  const { addToast } = useToaster()
  const [selectedLabels, setSelectedLabels] = useState([])
  const [authorList, setAuthorList] = useState([])
  const [author, setAuthor] = useState([])
  const [totalPageCount, setPageCount] = useState(0)
  const [openCount, setOpenCount] = useState(0)
  const [closedCount, setClosedCount] = useState(0)
  const [issueListStatus, setIssueListStatus] = useState(true)
  const [selectedValues, setSelectedValues] = useState([])
  const [isAPILoading, setAPILoading] = useState(false)
  const newSocket = io(process.env.REACT_APP_NEW_API_BASE_URL)
  const [isLabelAPILoading, setLabelAPI] = useState(false)
  const [isButtonFlag, setIsButtonFlag] = useState(false)
  const tableSkeletons = Array.from({ length: 4 })
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image'],
      ['clean'],
    ],
  }
  const itemsPerPage = 10

  const transformedLabelList = labelList.map((label) => ({
    label: label.name,
    value: label.name,
    color: `#${label.color}`,
  }))

  /**
   * A function that handles the change of labels.
   *
   * @param {type} selectedOptions - description of the selected options
   * @return {type} no return value
   */
  const handleLabelChange = (selectedOptions) => {
    setSelectedLabels(selectedOptions)
  }
  /**
   * Handles the change of the title input field.
   *
   * @param {Event} e - The event object representing the change event.
   * @return {void} This function does not return anything.
   */
  const handleTitle = (e) => {
    const value = e.target.value
    if (!value.trim()) {
      setTitleError(REQUIRED.titleRequired)
      setIssue_title('')
    } else {
      setTitleError('')
      setIssue_title(value)
    }
  }

  /**
   * Updates the page index and navigates to the specified page.
   *
   * @param {number} page - The page number to navigate to.
   * @return {void} This function does not return anything.
   */
  const changePage = (page) => {
    setPageIndex(page)
  }

  /**
   * A function that updates an issue with the provided data.
   *
   * @return {Promise<void>} This function does not return anything.
   */
  const updateIssue = async () => {
    try {
      setIsButtonFlag(true)
      const labels = selectedLabels?.map((value) => {
        return value.label
      })
      const descriptionValue = description.replace(/<\/?p>/g, '')
      const apiUrl = apiConfig.UPDATE_ISSUE
      const payload = {
        issueText: issue_title,
        description: descriptionValue,
        logId: logId,
        issueLabels: labels,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response.data.code === 200) {
        addToast({
          title: TOAST.MESSAGES.SUCCESS.updatedIssue,
          type: 'success',
        })
        issueListning()
        setIsOpen(false)
      } else {
        addToast({
          title: response.data.error,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      setIsButtonFlag(false)
    }
  }

  /**
   * A function that adds a new issue with the provided data.
   *
   * @return {Promise<void>} This function does not return anything.
   */
  const addNewIssue = async () => {
    try {
      setIsButtonFlag(true)
      const selectedLabel = selectedLabels?.map((value) => {
        return value.label
      })
      const descriptionValue = description?.replace(/<\/?[^>]+(>|$)/g, '')
      const apiUrl = apiConfig.ADD_ISSUE
      const payload = {
        issueText: issue_title,
        description: descriptionValue,
        projectId: id,
        issueLabels: selectedLabel,
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response.data.code === 200) {
        setIsOpen(false)
        issueListning()
        addToast({
          title: TOAST.MESSAGES.SUCCESS.addedIssue,
          type: 'success',
        })
        newSocket.emit('joinRoom', response?.data?.data?.issueId)
      } else {
        addToast({
          title: response.data.error,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      setIsButtonFlag(false)
    }
  }

  /**
   * Retrieves all labels from the API.
   *
   * @return {Promise<void>} A promise that resolves when the labels are retrieved.
   */
  const getAllLabels = async () => {
    try {
      setLabelAPI(true)
      const apiUrl = apiConfig.GET_ALL_LABEL
      const response = await apiService.apiCall('get', apiUrl)
      if (response.status === 200) {
        setLabelList(response.data.data || [])
      } else {
        addToast({
          title: response?.msg || 'Unable to fetch labels',
          type: 'error',
        })
      }
      setLabelAPI(false)
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    }
  }

  /**
   * Retrieves all authors from the API.
   *
   * @return {Promise<void>} A promise that resolves when the authors are retrieved.
   */
  const getAllAuthors = async () => {
    try {
      // showSpinner()
      const payload = {
        projectId: id,
      }
      const apiUrl = apiConfig.GET_ALL_AUTHORS
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response.status === 200) {
        const results = response.data.data.results
        const authorData = results?.map((aitem) => ({
          label: aitem,
          value: aitem,
        }))
        setAuthorList(authorData)
      } else {
        addToast({
          title: response?.msg || 'Unable to fetch Authors',
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    }
  }

  /**
   * Fetches the list of issues based on the provided parameters and updates the state accordingly.
   *
   * @return {Promise<void>} A promise that resolves when the issues list has been fetched and the state has been updated.
   */
  const issueListning = async () => {
    try {
      setAPILoading(true)
      // showSpinner()
      const payload = {
        projectId: id,
        page: pageIndex + 1,
        issueStatus: issueListStatus,
      }
      if (selectedValues.length > 0) {
        payload.issueLabels = selectedValues
      }
      if (author.length > 0) {
        payload.issueAuthors = author
      }
      const apiUrl = apiConfig.ISSUES_LISTNING
      const response = await apiService.apiCall('post', apiUrl, payload)
      setAPILoading(false)
      if (response.status === 200) {
        setOpenCount(response.data.data?.issueStatusCount?.openCount)
        setClosedCount(response.data.data?.issueStatusCount?.closedCount)
        setIssuesList(response.data.data.results)
        setPageCount(response.data.data.count)
      } else {
        addToast({
          title: response?.msg || 'Unable to fetch issues',
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      // hideSpinner()
      setIsOpen(false)
    }
  }

  useEffect(() => {
    setAPILoading(false)
    issueListning()
    getAllAuthors()
  }, [pageIndex, author, selectedValues, issueListStatus])

  useEffect(() => {
    getAllLabels()
    newSocket.on('updateStatus', (data) => {
      issueListning()
      newSocket.emit('leaveRoom', data)
    })

    // Handle socket disconnection on page change
    return () => {
      if (newSocket) {
        newSocket.disconnect()
      }
    }
  }, [])

  newSocket.on('updateStatus', (data) => {
    issueListning()
    newSocket.emit('leaveRoom', data)
  })

  /**
   * Updates the label information based on the provided data.
   *
   * @param {object} data - The data containing issue labels and details.
   * @return {void} No return value.
   */
  const handleUpdateLabel = (data) => {
    const labels = data.prDetails.issueLabels
    const selectedlabelList = labels.map((label) => ({
      label: label.name,
      value: label.name,
      color: `#${label.color}`,
    }))
    setSelectedLabels(selectedlabelList)
    setIssue_title(data.prDetails?.issueText)
    setDescription(data.prDetails?.description)
    setLogId(data._id)
    setModalHeader('Update')
    setIsOpen(true)
  }
  /**
   * Updates the selected values by setting the new selected values.
   *
   * @param {Array} newSelectedValues - The new selected values to set.
   * @return {void} This function does not return anything.
   */
  const filterLabel = (newSelectedValues) => {
    setSelectedValues(newSelectedValues)
  }

  /**
   * Updates the author state by adding the selected option value to the previous author values.
   *
   * @param {Object} selectedOption - The selected option object containing a value property.
   * @return {void} This function does not return anything.
   */
  const handleAuthor = (selectedOption) => {
    setAuthor((prevAuthor) => {
      const updatedAuthor = [...prevAuthor, selectedOption.value]
      return updatedAuthor
    })
  }

  /**
   * Sets the issue list status to true, indicating that the issue list is open.
   *
   * @return {void}
   */

  const handleOpenIssue = () => {
    setIssueListStatus(true)
  }

  /**
   * Sets the issue list status to false, indicating that the issue list is closed.
   *
   * @return {void}
   */
  const handleClosedIssue = () => {
    setIssueListStatus(false)
  }

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false)
          setSelectedLabels([])
        }}
        className="!z-[1010]"
      >
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="!z-[1004] !m-auto max-h-[550px] max-w-[450px] rounded-[20px] bg-white bg-clip-border px-[30px] pb-[18px] pt-[16px] dark:!bg-navy-800 dark:text-white">
          <div className="mb-[4px] flex w-full items-center justify-between">
            <ModalHeader className="!grow text-2xl font-bold">
              {modalHeader} Issue
            </ModalHeader>
            <ModalCloseButton />
          </div>
          <ModalBody>
            <div>
              <div className="my-4 mt-[15px] grid">
                <div>
                  <div className="mb-2">
                    {LABEL.TITLE}
                    <span className="text-red-500">*</span>
                  </div>
                  <div className="flex w-full items-center rounded-2xl border outline-none dark:!border-white/10 dark:!bg-navy-800">
                    <input
                      extra="mb-4 w-full"
                      value={issue_title}
                      onChange={handleTitle}
                      placeholder="Issue Name"
                      className="placeholder:text-lightFourth md:text-md h-full w-full rounded-2xl p-3 text-sm text-navy-700 outline-none dark:border dark:!bg-navy-800 dark:text-white"
                    />
                  </div>
                  {titleError && (
                    <div className="mt-1 text-sm text-red-500">
                      {titleError}
                    </div>
                  )}
                </div>
              </div>
              <div className="my-4 mt-[15px] grid">
                <div>
                  <div className="mb-2">{LABEL.DESCRIPTION}</div>
                  <div className="flex w-full items-center rounded-2xl border border-gray-200 outline-none dark:border dark:!border-white/10 dark:!bg-navy-800">
                    <ReactQuill
                      className=" placeholder:text-lightFourth md:text-md quill w-full rounded-2xl text-sm text-navy-700 outline-none dark:border dark:!bg-navy-800 dark:text-white"
                      theme="snow"
                      value={description}
                      onChange={setDescription}
                      modules={modules}
                      placeholder="Start typing your text here..."
                      style={{
                        minHeight: '50px',
                        maxHeight: '120px',
                        overflowY: 'auto',
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-2">{LABEL.LABELS}</div>
              <div className="flex w-full items-center rounded-2xl border border-gray-200 outline-none dark:border dark:!border-white/10 dark:!bg-navy-800">
                <CustomMultiSelect
                  options={transformedLabelList}
                  onChange={handleLabelChange}
                  placeholderText="Select Label"
                  isMultiValue={true}
                  value={selectedLabels}
                />
              </div>
              {modalHeader === 'Add' ? (
                <div className="text-right">
                  <button
                    onClick={addNewIssue}
                    disabled={!issue_title?.length || isButtonFlag}
                    className={`
                  'w-40 md:text-base' mt-4 rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200 dark:bg-brand-400 dark:text-white  
                  ${
                    !issue_title?.length || isButtonFlag
                      ? 'cursor-not-allowed opacity-50'
                      : 'hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:hover:bg-brand-300 dark:active:bg-brand-200'
                  }`}
                  >
                    <span>{BUTTON_TEXT.SAVE}</span>
                  </button>
                </div>
              ) : (
                <div className="text-right">
                  <button
                    onClick={updateIssue}
                    disabled={!issue_title?.length || isButtonFlag}
                    className={`
                      'w-40 md:text-base' mt-4 rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200 dark:bg-brand-400 dark:text-white  
                      ${
                        !issue_title?.length || isButtonFlag
                          ? 'cursor-not-allowed opacity-50'
                          : 'hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:hover:bg-brand-300 dark:active:bg-brand-200'
                      }`}
                  >
                    <span>{BUTTON_TEXT.UPDATE}</span>
                  </button>
                </div>
              )}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>

      <div className={`mt-4  ${!labelPage ? 'flex justify-between' : ''}`}>
        {!labelPage && (
          <div className="flex justify-start">
            <div>
              <CustomSelectLabel
                options={transformedLabelList}
                selectedValues={selectedValues}
                setSelectedValues={filterLabel}
                Multiselect={true}
              />
            </div>
            <div className="ml-2 flex w-[200px] items-center rounded-2xl border border-gray-200 bg-white outline-none dark:!border-white/10 dark:!bg-navy-800">
              <CustomSelect
                options={authorList}
                onChange={handleAuthor}
                placeholderText="Author"
                className="placeholder:text-lightFourth md:text-md h-full rounded-2xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
              />
            </div>
          </div>
        )}
        <div className="flex justify-end">
          {isLabelAPILoading ? (
            <div className="flex animate-pulse">
              <button className="mx-2 flex items-center justify-center rounded-2xl border bg-white p-3 px-6 text-sm transition duration-200">
                <span className="h-6 w-16 self-start rounded-xl bg-gray-200 dark:bg-gray-800"></span>
              </button>
            </div>
          ) : (
            <button
              onClick={() => setLabelPage(!labelPage)}
              className="mx-2 flex items-center justify-center rounded-2xl border bg-white p-3 px-8 text-sm transition duration-200"
            >
              <span>
                {!labelPage ? `Labels (${labelList.length})` : 'Back to Issues'}
              </span>
            </button>
          )}

          {!labelPage && (
            <button
              onClick={() => {
                setIsOpen(true)
                setIssue_title('')
                setDescription('')
                setModalHeader('Add')
                setSelectedLabels([])
              }}
              className="mx-2 flex items-center justify-center rounded-2xl bg-brand-500 p-3 px-8 text-sm text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:text-base"
            >
              <span>Add</span> <HiPlus />
            </button>
          )}
        </div>
      </div>
      {labelPage ? (
        <LabelCard labelList={labelList} labelListChange={getAllLabels} />
      ) : (
        <Card extra={'w-full h-full bg-white mt-3'}>
          <div className="h-full w-full p-2 md:px-4">
            {/* Table */}
            {isAPILoading ? (
              <div className="flex flex-col">
                {tableSkeletons.map((_item) => (
                  <div className="flex animate-pulse border-b border-gray-200 py-4 dark:!border-white/10">
                    <div className="w-1/5">
                      <div className="ml-4 h-2 w-1/6 rounded bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                    <div className="w-2/5">
                      <div className="ml-4 h-2 w-1/3 rounded bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                    <div className="w-2/5">
                      <div className="ml-8 h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="mt-2 min-h-[150px] w-full overflow-x-scroll xl:overflow-hidden">
                <table className="w-full">
                  <thead className="w-full">
                    <tr className="items-start border-b border-gray-200 text-sm dark:!border-white/10">
                      <th className="w-24 px-4 pb-[10px] text-start text-gray-600">
                        {TABLE_HEADER.SR_NO}
                      </th>
                      <th className="px-4 pb-[10px] text-start">
                        <FaRegDotCircle className="h-5 w-5 text-gray-600" />
                      </th>
                      <th className="flex px-4 pb-[10px] text-start hover:cursor-pointer">
                        <div
                          onClick={() => handleOpenIssue()}
                          className={`flex items-start ${
                            issueListStatus
                              ? 'text-gray-800 dark:text-gray-700'
                              : 'text-gray-700 hover:text-gray-600 dark:text-gray-800 dark:hover:text-gray-500'
                          }`}
                        >
                          <FaRegDotCircle className="success-message mr-1 h-4 w-4" />{' '}
                          {TABLE_HEADER.OPEN} ({openCount})
                        </div>
                        <div
                          onClick={() => handleClosedIssue()}
                          className={`ml-4 flex items-start  ${
                            issueListStatus
                              ? 'text-gray-700 hover:text-gray-600 dark:text-gray-800 dark:hover:text-gray-500'
                              : 'text-gray-800 dark:text-gray-700'
                          }`}
                        >
                          <FaRegDotCircle className="mr-1 h-4 w-4 text-gray-600" />{' '}
                          {TABLE_HEADER.CLOSED} ({closedCount})
                        </div>
                      </th>
                      <th className="px-4 pb-[10px] text-start text-gray-600">
                        {TABLE_HEADER.AUTHOR}
                      </th>
                      <th className="px-4 pb-[10px] text-start text-gray-600">
                        {TABLE_HEADER.PR_STATUS}
                      </th>
                      <th className="px-4 pb-[10px] text-start text-gray-600">
                        {TABLE_HEADER.ACTION}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {issuesList?.map((row, index) => (
                      <tr
                        className="border-b border-gray-200 dark:!border-white/10"
                        key={index}
                      >
                        <td className="w-24 px-4 text-start font-medium text-navy-700 dark:text-white">
                          {pageIndex * itemsPerPage + index + 1}
                        </td>
                        <td className="w-10 px-4 text-start font-medium">
                          {row.issueStatus ? (
                            <FaRegDotCircle className="success-message h-5 w-5" />
                          ) : (
                            <FaRegDotCircle className="h-5 w-5 text-gray-600" />
                          )}
                        </td>
                        <td className="w-[400px] px-4  py-2.5 text-start dark:text-white">
                          {row.prDetails?.status ===
                            appConstants?.ISSUE_STATUS?.inProcess ||
                          row.prDetails?.status ===
                            appConstants?.ISSUE_STATUS?.failed ? (
                            <span>{row.prDetails?.issueText}</span>
                          ) : (
                            <a
                              href={`${row.issueLink}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {row.prDetails?.issueText}
                            </a>
                          )}
                          {row.prDetails?.issueLabels?.map((label) => (
                            <span
                              key={label.id}
                              className="ml-1 inline-block rounded-full px-2 py-1 text-sm"
                              style={{ backgroundColor: `#${label.color}` }}
                            >
                              {label.name}
                            </span>
                          ))}
                          {row.prDetails?.status !==
                            appConstants?.ISSUE_STATUS?.inProcess &&
                            row.prDetails?.status !==
                              appConstants?.ISSUE_STATUS?.failed && (
                              <div className="font-small text-gray-600">
                                {issueDetails(row)}
                              </div>
                            )}
                        </td>
                        <td className="px-4 text-start font-medium text-navy-700  dark:text-white">
                          {row.prDetails?.issueAuthor}
                        </td>
                        <td className="px-4 text-start font-medium">
                          <div className="flex items-center justify-start">
                            {row.prDetails?.status ===
                            appConstants.ISSUE_STATUS.inProcess ? (
                              <div className="flex items-start text-yellow-500">
                                <span>
                                  In {appConstants.ISSUE_STATUS.inProcess}
                                </span>
                                <TbProgressAlert className="ml-1" />
                              </div>
                            ) : (
                              <div
                                className={`flex items-center ${
                                  row.prDetails?.status ===
                                    appConstants?.ISSUE_STATUS?.open ||
                                  row.prDetails?.status ===
                                    appConstants.ISSUE_STATUS.merge
                                    ? 'text-green-600'
                                    : 'text-red-600'
                                }`}
                              >
                                <span className="capitalize">
                                  {row.prDetails?.status ===
                                  appConstants.ISSUE_STATUS.merge
                                    ? appConstants.ISSUE_STATUS.close
                                    : row.prDetails?.status}
                                </span>
                                {row.prDetails?.status ===
                                  appConstants.ISSUE_STATUS.open ||
                                row.prDetails?.status ===
                                  appConstants.ISSUE_STATUS.merge ? (
                                  <BsFillCheckCircleFill className="ml-1" />
                                ) : (
                                  <FaCircleExclamation className="ml-1 text-red-600" />
                                )}
                              </div>
                            )}
                          </div>
                        </td>
                        <td className="px-4">
                          <div className="align-start flex justify-start text-sm font-medium text-brand-500 dark:text-white">
                            <div className="w-max rounded-xl py-2 text-sm ">
                              <a
                                href={
                                  row.prDetails?.status ===
                                    appConstants?.ISSUE_STATUS?.inProcess ||
                                  row.prDetails?.status ===
                                    appConstants?.ISSUE_STATUS?.failed
                                    ? '#'
                                    : `${row.prDetails?.prLink}`
                                }
                                target="_blank"
                                rel="noreferrer"
                                className={`underline ${
                                  row.prDetails?.status ===
                                    appConstants?.ISSUE_STATUS?.inProcess ||
                                  row.prDetails?.status ===
                                    appConstants?.ISSUE_STATUS?.failed
                                    ? 'pointer-events-none cursor-not-allowed opacity-50'
                                    : ''
                                }`}
                              >
                                {BUTTON_TEXT.CHECK_PR}
                              </a>
                            </div>
                            <div>
                              <button
                                onClick={() => handleUpdateLabel(row)}
                                disabled={
                                  row.prDetails?.status ===
                                    appConstants?.ISSUE_STATUS?.inProcess ||
                                  row.prDetails?.status ===
                                    appConstants?.ISSUE_STATUS?.failed
                                }
                                className={`ml-4 flex items-start justify-center rounded-3xl bg-brand-50 p-3 px-4 py-2 text-sm font-bold text-brand-500 transition ${
                                  row.prDetails?.status ===
                                    appConstants?.ISSUE_STATUS?.inProcess &&
                                  row.prDetails?.status ===
                                    appConstants?.ISSUE_STATUS?.failed
                                    ? 'cursor-not-allowed opacity-50'
                                    : ''
                                }`}
                              >
                                <span>{BUTTON_TEXT.UPDATE}</span>
                                <GrUpdate className="ml-2" />
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* Display message if no data */}
                {!issuesList?.length && (
                  <div className="text-md mt-3 flex w-full justify-center text-center text-gray-500 dark:text-gray-400">
                    {NO_DATA}
                  </div>
                )}
              </div>
            )}

            {isAPILoading ? (
              <PaginationSkeleton />
            ) : (
              issuesList.length > 0 && (
                <div className="mt-2 flex h-12 w-full items-center justify-between px-6">
                  <div className="text-sm text-gray-700">
                    {/* Display current range of entries */}
                    Showing {pageIndex * itemsPerPage + 1} to{' '}
                    {totalPageCount <
                    pageIndex * itemsPerPage + issuesList.length
                      ? totalPageCount
                      : pageIndex * itemsPerPage + issuesList.length}{' '}
                    of {totalPageCount} entries
                  </div>

                  <div className="flex items-center">
                    {/* Render pagination buttons */}
                    <div className="ml-3 flex">
                      {/* First button */}
                      <button
                        className={`h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                          pageIndex === 0 ? 'cursor-not-allowed opacity-50' : ''
                        }`}
                        onClick={() => changePage(0)}
                        disabled={pageIndex === 0}
                      >
                        {'<<'}
                      </button>

                      {/* Previous button */}
                      <button
                        className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                          pageIndex === 0 ? 'cursor-not-allowed opacity-50' : ''
                        }`}
                        onClick={() => changePage(pageIndex - 1)}
                        disabled={pageIndex === 0}
                      >
                        {'<'}
                      </button>

                      {/* Pagination buttons */}
                      {Array.from(
                        { length: Math.min(5, Math.ceil(totalPageCount / 10)) }, // Limit to a maximum of 5 pages
                        (_, index) => {
                          const page =
                            Math.max(
                              0,
                              Math.min(
                                Math.ceil(totalPageCount / 10) - 5,
                                pageIndex
                              )
                            ) + index
                          return (
                            <button
                              key={page}
                              className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                                pageIndex === page
                                  ? 'cursor-not-allowed opacity-50'
                                  : ''
                              }`}
                              onClick={() => changePage(page)}
                              disabled={pageIndex === page}
                            >
                              {page + 1}
                            </button>
                          )
                        }
                      )}

                      {/* Next button */}
                      <button
                        className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                          pageIndex >= Math.ceil(totalPageCount / 10) - 1
                            ? 'cursor-not-allowed opacity-50'
                            : ''
                        }`}
                        onClick={() => changePage(pageIndex + 1)}
                        disabled={
                          pageIndex >= Math.ceil(totalPageCount / 10) - 1
                        }
                      >
                        {'>'}
                      </button>

                      {/* Last button */}
                      <button
                        className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                          pageIndex >= Math.ceil(totalPageCount / 10) - 1
                            ? 'cursor-not-allowed opacity-50'
                            : ''
                        }`}
                        onClick={() =>
                          changePage(Math.ceil(totalPageCount / 10) - 1)
                        }
                        disabled={
                          pageIndex >= Math.ceil(totalPageCount / 10) - 1
                        }
                      >
                        {'>>'}
                      </button>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </Card>
      )}
    </>
  )
}

export default ApplicationIssuesList
