import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import WebserviceEventLogs from './components/WebserviceEventLogs'
import Card from 'components/card'
import DomainManagement from './components/DomainManagement'
import ConfigMap from './components/ConfigMap'
import Secrets from './components/Secrets'
import { useState, useEffect, useRef } from 'react'
import { PiArrowSquareOutBold } from 'react-icons/pi'
import { FaGithub } from 'react-icons/fa'
import { useToaster } from 'common/Toaster'
import { useLocation, useParams } from 'react-router-dom'
import appConstants from 'common/config/appConstants'
import {
  decryptFunction,
  getSingleApplicationInfo,
} from 'common/commonFunction'

const apiService = ApiCaller()
const { TOAST, WEB_SERVICES, TABS, GITHUB_URL } = appConstants

function CoursePage() {
  const { addToast } = useToaster()
  const location = useLocation()
  const [toggleState, setToggleState] = useState(WEB_SERVICES.EVENTS)
  const [workflowJobsDetailsPageData, setWorkflowJobsDetailsPageData] =
    useState('')
  const [selectedTableRows, handleSelectedRows] = useState('')
  const [selectedSecretsTableRows, handleSelectedSecretsRows] = useState('')
  const [configRepoName, setConfigRepoName] = useState('')
  const [secretRepoName, setSecretRepoName] = useState('')
  const [configData, setConfigData] = useState('')
  const [secretsData, setSecretsData] = useState('')
  const [totalRunsCount, setTotalRunsCount] = useState(0)
  const [workflowData, setWorkflowData] = useState('')
  const [workflowRunsData, setWorkflowRunsData] = useState('')
  const [orgName, setOrgName] = useState('')
  const [orgRepo, setOrgRepo] = useState('')
  const searchParams = new URLSearchParams(location.search)
  const repoName = searchParams.get('repo')
  const [currentDomainPage, setCurrentDomainPage] = useState(0)
  const [currentRunsPage, setCurrentRunsPage] = useState(0)
  const [configMapLoading, setConfigMapLoading] = useState(false)
  const [secretsLoading, setSecretsLoading] = useState(false)
  const [domainLoading, setDomainLoading] = useState(false)
  const [domainListData, setDomainListData] = useState('')
  const [branchNameList, setBranchNameList] = useState()
  const [totalDomainCount, setTotalDomainCount] = useState(0)
  const itemsPerPage = 10
  const { application_id, id } = useParams()
  const [appType, setAppType] = useState()
  const intervalRef = useRef(null)

  const getDomainManagementData = async () => {
    const apiUrl = apiConfig.GET_DOMAINS_LIST.replace(
      ':applicationId',
      application_id
    )
    try {
      setDomainLoading(true)
      const response = await apiService.apiCall('get', apiUrl)
      if (response?.data?.code === 200) {
        const responseData = response?.data?.data
        setDomainListData(responseData?.domainList)
        setTotalDomainCount(responseData.totalCount)
      } else {
        console.error('Error response from API:', response)
      }
    } catch (error) {
      console.error('Error fetching config table data:', error)
    } finally {
      setDomainLoading(false)
    }
  }

  const getSelectedEnv = async () => {
    try {
      const apiUrl = apiConfig.GET_SELECTED_ENV.replace(':projectId', id)
      const response = await apiService.apiCall('get', apiUrl)
      if (response?.data?.code === 200) {
        const responseData = response?.data?.data
        setBranchNameList(responseData)
      } else {
        addToast({
          title: TOAST.MESSAGES.ERROR.fetchBranchError,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    }
  }

  useEffect(() => {
    /**
     * Fetches data asynchronously, processes and updates orgName and orgRepo based on FullBranchName, and invokes getWorkFlows.
     *
     * @return {Promise<void>} A promise that resolves after fetching and processing data.
     */
    const getApplicationInfo = async () => {
      try {
        const response = await getSingleApplicationInfo(application_id)
        if (response?.data?.code === 200) {
          const data = response?.data?.data
          setAppType(data?.appType)

          const fullBranchName = data?.[data?.appType]?.gitRepo?.replace(
            /"/g,
            ''
          )
          const parts = fullBranchName.split('/')
          let orgName = parts[3]
          let orgRepo = parts[4]
          if (repoName) {
            orgRepo = decryptFunction(repoName)
          }
          setOrgName(orgName)
          setOrgRepo(orgRepo)
          await getWorkFlows(1)
        } else {
          addToast({
            title: TOAST.MESSAGES.ERROR.pgrError,
            type: 'error',
          })
        }
      } catch (error) {
        console.log('error>>', error)
      }
    }
    getApplicationInfo()
  }, [])

  useEffect(() => {
    if (configRepoName) {
      getConfigTableData()
    }
    setConfigData(null)
  }, [configRepoName])

  useEffect(() => {
    if (secretRepoName) {
      getSecretsTableData()
    }
    setSecretsData(null)
  }, [secretRepoName])

  useEffect(() => {
    if (appType === 'webApp' || appType === 'frontend') {
      getSelectedEnv()
      getDomainManagementData()
    }
  }, [appType])
  /**
   * Asynchronously handles the verification process.
   *
   * @return {Promise<void>} Resolves after handling the verification process.
   */
  const handleVerify = async () => {
    const apiUrl = apiConfig.UPDATE_CONFIG_TABLE_DATA.replace(
      ':application_id',
      application_id
    ).replace(':branch_id', configRepoName)

    const payloadData = selectedTableRows.map(({ id, ...rest }) => rest)
    try {
      const response = await apiService.apiCall('put', apiUrl, payloadData)
      if (response?.data?.code === 200) {
        addToast({
          title: response?.data?.msg,
          type: 'success',
        })
        getConfigTableData()
      } else {
        addToast({
          title: response?.data?.msg,
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error verifying token:', error)
    }
  }

  /**
   * Retrieves the configuration table data from the API.
   *
   * @return {Promise<void>} A promise that resolves when the data is retrieved.
   */
  const getConfigTableData = async () => {
    const apiUrl = apiConfig.GET_CONFIG_TABLE_DATA.replace(
      ':application_id',
      application_id
    ).replace(':branch_id', configRepoName)

    try {
      setConfigMapLoading(true)
      const response = await apiService.apiCall('get', apiUrl)
      if (response?.data?.code === 200) {
        const responseData = response?.data?.data || [{ key: '', value: '' }]
        if (Array.isArray(responseData)) {
          setConfigData(responseData)
        } else {
          console.error('Data returned from API is not an array:', responseData)
        }
      } else {
        console.error('Error response from API:', response)
      }
    } catch (error) {
      console.error('Error fetching config table data:', error)
    } finally {
      setConfigMapLoading(false)
    }
  }
  /**
   * Retrieves the secrets table data from the API.
   *
   * @return {Promise<void>} A promise that resolves when the data is retrieved.
   */
  const getSecretsTableData = async () => {
    const apiUrl = apiConfig.GET_SECRET_TABLE_DATA.replace(
      ':application_id',
      application_id
    ).replace(':branch_id', secretRepoName)

    try {
      setSecretsLoading(true)
      const response = await apiService.apiCall('get', apiUrl)
      if (response?.data?.code === 200) {
        const responseData = response?.data?.data || [{ key: '', value: '' }]
        if (Array.isArray(responseData)) {
          setSecretsData(responseData)
        } else {
          console.error('Data returned from API is not an array:', responseData)
        }
      } else {
        console.error('Error response from API:', response)
      }
    } catch (error) {
      console.error('Error fetching config table data:', error)
    } finally {
      setSecretsLoading(false)
    }
  }

  /**
   * Asynchronously handles the verification process of secrets.
   *
   * @return {Promise<void>} Resolves after handling the verification process.
   */
  const handleSecretsVerify = async () => {
    const apiUrl = apiConfig.UPDATE_SECRET_TABLE_DATA.replace(
      ':application_id',
      application_id
    ).replace(':branch_id', secretRepoName)

    const payloadData = selectedSecretsTableRows.map(({ id, ...rest }) => rest)
    try {
      const response = await apiService.apiCall('put', apiUrl, payloadData)
      if (response?.data?.code === 200) {
        addToast({
          title: response?.data?.msg,
          type: 'success',
        })
        getSecretsTableData()
      } else {
        addToast({
          title: response?.data?.msg,
          type: 'error',
        })
      }
    } catch (error) {
      console.error('Error verifying token:', error)
    }
  }

  /**
   * Updates the current runs page and fetches all events for the specified page number.
   *
   * @param {number} pageNumber - The page number to set as the current runs page.
   */
  const handleRunsPageChange = (pageNumber) => {
    setCurrentRunsPage(pageNumber)
    getWorkFlows(pageNumber)
  }

  /**
   * Toggles the tab based on the index and performs specific actions accordingly.
   *
   * @param {number} index - The index of the tab to toggle.
   */
  const toggleTab = (index) => {
    setToggleState(index)
    if (
      index === WEB_SERVICES.DEPLOYMENT_LOGS &&
      workflowData &&
      workflowData?.results?.length > 0
    ) {
      getWorkFlows(1)
    }
    if (index === WEB_SERVICES.CONFIG_MAP) {
      getConfigTableData()
    }
    if (index === WEB_SERVICES.SECRETS) {
      getSecretsTableData()
    }
    if (index === WEB_SERVICES.DOMAIN_MANAGEMENT) {
      getDomainManagementData()
    }
  }
  /**
   * A function to get workflows for a given organization and repository.
   *
   * @param {string} orgName - the name of the organization
   * @param {string} orgRepo - the name of the repository
   * @return {Promise<void>}
   */
  const getWorkFlows = async (page = 1) => {
    const apiUrl = apiConfig.WORK_FLOWS_GET_EVENTS.replace(
      ':application_id',
      application_id
    ).replace(':page', page)
    try {
      const response = await apiService.apiCall('get', apiUrl)
      if (response?.data?.code === 200) {
        setWorkflowData(response?.data?.data)
        const workflows = response?.data?.data?.results
        const allCompleted = workflows.every(
          (data) => data.status === 'completed'
        )
        setWorkflowRunsData(workflows)
        setTotalRunsCount(response?.data?.data?.options?.totalCount)
        if (allCompleted) {
          if (intervalRef.current) {
            clearInterval(intervalRef.current)
            intervalRef.current = null // Reset interval reference
          }
        } else {
          if (!intervalRef.current) {
            intervalRef.current = setInterval(() => {
              getWorkFlows(page)
            }, 5000)
          }
        }
      } else {
        addToast({
          title: TOAST.MESSAGES.ERROR.workflowError,
          type: 'error',
        })
      }
    } catch (error) {
      console.log('error>>', error)
    }
  }

  return (
    <div className="w-full grid-cols-1 gap-5 font-dm lg:grid-cols-12">
      <div className="h-full w-full rounded-[20px] lg:col-span-12 4xl:col-span-12">
        <a
          href={`${GITHUB_URL}${orgName}/${orgRepo}`}
          target="_blank"
          rel="noopener noreferrer"
          className="hover:underline"
        >
          <div className="mb-3 flex items-center ">
            <FaGithub className="mr-2 dark:text-white" />
            <span className="font-md  mr-2 dark:text-white">
              <p>{`${orgName} / ${orgRepo}`}</p>
            </span>
          </div>
        </a>

        {toggleState === WEB_SERVICES.EVENTS && (
          <a
            href={`${GITHUB_URL}${orgName}/${orgRepo}/actions`}
            target="_blank"
            rel="noopener noreferrer"
            className="my-3 flex w-fit items-center text-fixaText"
          >
            <span className="text-sm">
              {`${GITHUB_URL}${orgName}/${orgRepo}/actions`}
            </span>
            <PiArrowSquareOutBold className="ml-1" />
          </a>
        )}

        {toggleState === WEB_SERVICES.DEPLOYMENT_LOGS &&
          workflowData &&
          workflowData?.results &&
          workflowData?.results?.length > 0 &&
          workflowJobsDetailsPageData && (
            <a
              href={`${GITHUB_URL}${orgName}/${orgRepo}/blob/${
                workflowJobsDetailsPageData.headBranch
              }/${
                workflowData &&
                workflowData?.results &&
                workflowData?.results?.length > 0 &&
                workflowData?.results[0]?.path &&
                workflowData?.results[0]?.path
              }`}
              target="_blank"
              rel="noopener noreferrer"
              className="my-3 flex w-fit items-center text-fixaText"
            >
              <span className="text-sm">
                {`${GITHUB_URL}${orgName}/${orgRepo}/blob/${
                  workflowJobsDetailsPageData?.headBranch
                }/${
                  workflowData &&
                  workflowData?.results &&
                  workflowData?.results?.length > 0 &&
                  workflowData?.results[0]?.path &&
                  workflowData?.results[0]?.path
                }`}{' '}
              </span>
              <PiArrowSquareOutBold className="ml-1" />
            </a>
          )}

        <div className="mb-1 w-full rounded-xl xl:mb-3">
          <div className="mb-1 flex w-full flex-row gap-8 overflow-hidden text-3xl">
            <div
              className={
                toggleState === WEB_SERVICES.EVENTS
                  ? 'flex items-center gap-3 border-b-[4px] border-brand-500 pb-1 hover:cursor-pointer dark:border-brand-400'
                  : 'flex items-center gap-3 border-b-[4px] border-none pb-1 hover:cursor-pointer dark:!border-navy-800'
              }
              onClick={() => toggleTab(WEB_SERVICES.EVENTS)}
            >
              <p
                className={
                  toggleState === WEB_SERVICES.EVENTS
                    ? 'text-[18px] font-bold text-navy-700 dark:text-white'
                    : 'text-[18px] text-disableGray dark:text-white'
                }
              >
                {TABS.EVENTS}
              </p>
            </div>

            <div
              className={
                toggleState === WEB_SERVICES.DEPLOYMENT_LOGS
                  ? 'flex items-center gap-3 border-b-[4px] border-brand-500 pb-1 hover:cursor-pointer dark:border-brand-400'
                  : 'flex items-center gap-3 border-b-[4px] border-none pb-1 hover:cursor-pointer dark:!border-navy-800'
              }
            >
              <p
                className={
                  toggleState === WEB_SERVICES.DEPLOYMENT_LOGS
                    ? 'text-[18px] font-bold text-navy-700 dark:text-white'
                    : 'text-[18px] text-disableGray dark:text-white'
                }
              >
                {TABS.DEPLOY_LOGS}
              </p>
            </div>

            <div
              className={
                toggleState === WEB_SERVICES.CONFIG_MAP
                  ? 'flex items-center gap-3 border-b-[4px] border-brand-500 pb-1 hover:cursor-pointer dark:border-brand-400'
                  : 'flex items-center gap-3 border-b-[4px] border-none pb-1 hover:cursor-pointer dark:!border-navy-800'
              }
              onClick={() => toggleTab(WEB_SERVICES.CONFIG_MAP)}
            >
              <p
                className={
                  toggleState === WEB_SERVICES.CONFIG_MAP
                    ? 'text-[18px] font-bold text-navy-700 dark:text-white'
                    : 'text-[18px] text-disableGray dark:text-white'
                }
              >
                {TABS.CONFIG_MAP}
              </p>
            </div>

            <div
              className={
                toggleState === WEB_SERVICES.SECRETS
                  ? 'flex items-center gap-3 border-b-[4px] border-brand-500 pb-1 hover:cursor-pointer dark:border-brand-400'
                  : 'flex items-center gap-3 border-b-[4px] border-none pb-1 hover:cursor-pointer dark:!border-navy-800'
              }
              onClick={() => toggleTab(WEB_SERVICES.SECRETS)}
            >
              <p
                className={
                  toggleState === WEB_SERVICES.SECRETS
                    ? 'text-[18px] font-bold text-navy-700 dark:text-white'
                    : 'text-[18px] text-disableGray dark:text-white'
                }
              >
                {TABS.SECRETS}
              </p>
            </div>

            {(appType === 'webApp' || appType === 'frontend') && (
              <div
                className={
                  toggleState === WEB_SERVICES.DOMAIN_MANAGEMENT
                    ? 'flex items-center gap-3 border-b-[4px] border-brand-500 pb-1 hover:cursor-pointer dark:border-brand-400'
                    : 'flex items-center gap-3 border-b-[4px] border-none pb-1 hover:cursor-pointer dark:!border-navy-800'
                }
                onClick={() => toggleTab(WEB_SERVICES.DOMAIN_MANAGEMENT)}
              >
                <p
                  className={
                    toggleState === WEB_SERVICES.DOMAIN_MANAGEMENT
                      ? 'text-[18px] font-bold text-navy-700 dark:text-white'
                      : 'text-[18px] text-disableGray dark:text-white'
                  }
                >
                  {TABS.DOMAIN_MGMT}
                </p>
              </div>
            )}
          </div>
        </div>

        <Card extra={'w-full h-full bg-white mt-3'}>
          <DomainManagement
            toggleState={toggleState}
            setToggleState={setToggleState}
            tableData={domainListData}
            currentPage={currentDomainPage}
            itemsPerPage={itemsPerPage}
            totalDomainCount={totalDomainCount}
            loading={domainLoading}
            onPageChange={(pageNumber) => {
              setCurrentDomainPage(pageNumber)
            }}
            getDomainManagementData={getDomainManagementData}
          />

          <ConfigMap
            toggleState={toggleState}
            setToggleState={setToggleState}
            onRepoNameChange={setConfigRepoName}
            handleVerify={handleVerify}
            onSelectedRowsChange={handleSelectedRows}
            configData={configData}
            branchNameList={branchNameList}
            loading={configMapLoading}
          />

          <Secrets
            toggleState={toggleState}
            setToggleState={setToggleState}
            handleSecretsVerify={handleSecretsVerify}
            onSelectedSecretsRowsChange={handleSelectedSecretsRows}
            secretsData={secretsData}
            onSecretsRepoNameChange={setSecretRepoName}
            branchNameList={branchNameList}
            loading={secretsLoading}
          />
        </Card>
        {/* web services */}
        <WebserviceEventLogs
          toggleState={toggleState}
          setToggleState={setToggleState}
          workflowData={workflowData}
          workflowRuns={workflowRunsData}
          totalPageCount={totalRunsCount}
          currentPage={currentRunsPage}
          itemsPerPage={itemsPerPage}
          onPageChange={handleRunsPageChange}
          setWorkflowJobsDetailsPageData={setWorkflowJobsDetailsPageData}
          orgRepo={orgRepo}
          orgName={orgName}
        />
      </div>
    </div>
  )
}

export default CoursePage
