import { useState } from 'react'
import Card from 'components/card'
import Radio from 'components/radio'
import appConstants from 'common/config/appConstants'

const { BUILD_DEPLOY_GIT_REPOSITORY, CONNECT_GITHUB_REPOSITORY } = appConstants

/**
 * DeployGitFunc function to handle the deployment from a git repository
 */
const DeployGitFunc = () => {
  const [isChecked, setIsChecked] = useState(true)
  const handleRadioChange = () => {
    setIsChecked(!isChecked)
  }
  return (
    <div className="flex items-center md:gap-7">
      <div className="mx-3 md:mx-0">
        <Radio checked={isChecked} onChange={handleRadioChange} />
      </div>
      <Card
        extra={
          'col-span-10 w-full h-full pb-[35px] pt-[3px] pr-[28px] pl-[33px] shadow-md z-1'
        }
      >
        {/* Header */}
        <div>
          <h5 className="my-3 pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
            {BUILD_DEPLOY_GIT_REPOSITORY}
          </h5>
          <div className="text-md text-gray-10 dark:text-white">
            {CONNECT_GITHUB_REPOSITORY}
          </div>
        </div>
      </Card>
    </div>
  )
}

export default DeployGitFunc
