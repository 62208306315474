import React, { useState, useEffect } from 'react'
import { HiPlus } from 'react-icons/hi'
import { RiDeleteBinLine } from 'react-icons/ri'
import { useDialogContext } from 'contexts/DialogProvider'
import { RxCrossCircled } from 'react-icons/rx'
import { getOrganization } from 'common/commonFunction'
import TooltipHorizon from '../../../../components/tooltip'
import appConstants from 'common/config/appConstants'

function SearchTableOrders(props) {
  const {
    toggleState,
    valuesData,
    onSelectedRowsChange,
    handleVerify,
    onRepoNameChange,
    loading,
    setLoader,
  } = props
  const [inputFields, setInputFields] = useState([{ key: '', value: '' }])
  const [is_Branch_name, setIsBranchName] = useState([])
  const [valuesMapRepoName, setValuesMapRepoName] = useState('')
  const [validity, setValidity] = useState([{ key: false, value: false }])
  const [hasChanges, setHasChanges] = useState(false)
  const [branchName, setBranchNames] = useState([])
  const { openDialog, onClose, setModalSubmitHandler, setLoading } =
    useDialogContext()
  const { BUTTON_TEXT, TABS, LABEL } = appConstants
  const valuesSkeletons = Array.from({ length: 3 })

  /**
   * Updates the inputFields state and validity state based on the input change event.
   *
   * @param {number} index - The index of the input field.
   * @param {Event} event - The input change event.
   * @return {void}
   */
  const handleInputChange = (index, event) => {
    const values = [...inputFields]
    const isValid = event.target.value.trim() !== ''
    if (event.target.name === 'key') {
      values[index] = { ...values[index], key: event.target.value }
    } else {
      values[index] = { ...values[index], value: event.target.value }
    }
    setInputFields(values)

    // Update validity state
    const validities = [...validity]
    validities[index] = {
      key: event.target.name === 'key' ? isValid : validities[index].key,
      value: event.target.name === 'value' ? isValid : validities[index].value,
    }
    setValidity(validities)
    setHasChanges(true)
  }
  useEffect(() => {}, [hasChanges])
  /**
   * Deletes a field from the inputFields array at the specified index.
   *
   * @param {number} index - The index of the field to delete.
   * @return {void}
   */
  const handleDeleteField = (index) => {
    const values = [...inputFields]
    //eslint-disable-next-line
    const deletedRow = values.splice(index, 1)[0]
    setInputFields(values)
    setHasChanges(true)
  }
  useEffect(() => {
    /**
     * Fetches organization data asynchronously.
     */
    const fetchOrganizationData = async () => {
      try {
        setLoader(true)
        const response = await getOrganization()
        const envNames = response?.data?.data?.env?.map((env) => env.name)
        setBranchNames(envNames)
        if (envNames) {
          setIsBranchName(envNames)
          if (envNames && envNames.length > 0) {
            setValuesMapRepoName(envNames[0])
          }
        }
      } catch (error) {
        console.error('Error fetching organization data:', error)
      }
    }

    fetchOrganizationData()
    //eslint-disable-next-line
  }, [])
  /**
   * Updates the key field value in the inputFields state and sets validity state to false for the key field.
   *
   * @param {number} index - The index of the key field to clear.
   * @return {void}
   */
  const handleClearKeyField = (index) => {
    const values = [...inputFields]
    values[index] = { ...values[index], key: '' }
    setInputFields(values)

    // Update validity state
    const validities = [...validity]
    validities[index] = { ...validities[index], key: false }
    setValidity(validities)
  }

  /**
   * Updates the value field in the inputFields state and sets validity state to false for the value field.
   *
   * @param {number} index - The index of the value field to clear.
   * @return {void}
   */
  const handleClearValueField = (index) => {
    const values = [...inputFields]
    values[index] = { ...values[index], value: '' }
    setInputFields(values)

    // Update validity state
    const validities = [...validity]
    validities[index] = { ...validities[index], value: false }
    setValidity(validities)
  }

  /**
   * A description of the entire function.
   *
   * @return {void}
   */
  const handleAddFields = () => {
    setInputFields([...inputFields, { key: '', value: '' }])
    setValidity([...validity, { key: false, value: false }])
  }

  useEffect(() => {
    if (valuesData) {
      const initialInputFields = valuesData.map(({ key, value }) => ({
        key,
        value,
      }))
      setInputFields(initialInputFields)
      setValidity(initialInputFields.map(() => ({ key: true, value: true })))
    } else {
      setInputFields([{ key: '', value: '' }])
      setValidity([{ key: false, value: false }])
    }
  }, [valuesData])

  useEffect(() => {
    if (onRepoNameChange) {
      onRepoNameChange(valuesMapRepoName)
    }
  }, [valuesMapRepoName, onRepoNameChange])

  useEffect(() => {
    onSelectedRowsChange(inputFields)
  }, [inputFields, onSelectedRowsChange])

  /**
   * A description of the entire function.
   *
   */
  const handleSaveChanges = () => {
    openDialog(
      'Are you sure?',
      <div className="text-lg">You want to do this?</div>,
      'Save',
      'Cancel'
    )
  }

  useEffect(() => {
    /**
     * Handles the data asynchronously.
     *
     * @return {Promise<void>} A promise that resolves when the data handling is complete.
     */
    const handleData = async () => {
      try {
        setLoading(true)
        await handleVerify()
        onClose()
        setHasChanges(false)
      } catch (error) {
        console.error('Error while verifying:', error)
      } finally {
        setLoading(false)
      }
    }
    if (toggleState === `${TABS.VALUES}`) {
      setModalSubmitHandler(() => handleData)
    }
  }, [
    handleVerify,
    onClose,
    setLoading,
    setModalSubmitHandler,
    toggleState,
    TABS.VALUES,
  ])

  const handleBranchChange = (e) => {
    const selectedRepo = e.target.value
    setValuesMapRepoName(selectedRepo)
  }

  useEffect(() => {
    if (toggleState !== 5) {
      if (branchName) {
        setValuesMapRepoName(branchName[0])
      }
    }
    //eslint-disable-next-line
  }, [toggleState])

  return (
    <>
      {toggleState === `${TABS.VALUES}` &&
        (loading ? (
          <div className="flex animate-pulse flex-col gap-y-8 p-6">
            <div className="h-8 w-32 self-end rounded-xl bg-gray-200 dark:bg-gray-800"></div>
            <div className="flex flex-col gap-y-4">
              {valuesSkeletons.map((_item, index) => (
                <div key={index} className="flex gap-x-4">
                  <div className="flex w-full flex-col gap-y-2">
                    <div className="h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
                    <div className="h-8 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
                  </div>
                  <div className="flex w-full flex-col gap-y-2">
                    <div className="h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
                    <div className="h-8 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
                  </div>
                </div>
              ))}
            </div>

            <div className="flex justify-end gap-x-6">
              <div className="h-8 w-64 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
              <div className="h-8 w-28 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
            </div>
          </div>
        ) : (
          <div className="h-full w-full p-2 md:p-4">
            <div className="mt-[1%] flex items-center justify-end md:ml-4">
              <select
                className="text-lightFourth w-30 border-gray mr-4 rounded-xl border-2 border-solid p-1.5 pr-4 text-sm dark:bg-navy-800 dark:text-white"
                name=""
                id=""
                onChange={handleBranchChange}
                value={valuesMapRepoName}
                placeholderText="Select Repository"
              >
                {is_Branch_name.map((branch, index) => (
                  <option key={index} value={branch}>
                    {branch}
                  </option>
                ))}
              </select>
            </div>
            <div className="my-1">
              <div className="mb-2 flex gap-4">
                <div className="ml-2 w-[47%] text-start font-medium">Key</div>
                <div className="ml-2 w-[47%] text-start font-medium">Value</div>
              </div>
              {inputFields.map((inputField, index) => (
                <div key={index} className="mb-4">
                  <div className="relative flex gap-4">
                    <div className="relative flex w-1/2 flex-col">
                      <div className="relative flex w-full items-center gap-3 rounded-2xl border border-gray-200 pl-[8px] outline-none dark:!border-white/10 dark:!bg-navy-800">
                        <input
                          type="text"
                          className="placeholder:text-lightFourth md:text-md h-full w-full rounded-2xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                          placeholder="Enter NAME_OF_VARIABLE"
                          name="key"
                          value={inputField.key}
                          autoComplete="off"
                          onChange={(event) => handleInputChange(index, event)}
                        />
                        {inputField.key && (
                          <button
                            className="absolute right-2 top-2 rounded-full p-1 text-gray-500 hover:text-gray-700"
                            type="button"
                            onClick={() => handleClearKeyField(index)}
                          >
                            <RxCrossCircled />
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="relative flex w-1/2 flex-col">
                      <div className="relative flex w-full items-center gap-3 rounded-2xl border border-gray-200 pl-[8px] outline-none dark:!border-white/10 dark:!bg-navy-800">
                        <input
                          type="text"
                          className="placeholder:text-lightFourth md:text-md h-full w-full rounded-2xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                          placeholder="Enter Value"
                          name="value"
                          value={inputField.value}
                          title={inputField.value}
                          onMouseEnter={(e) => (e.target.type = 'text')}
                          // onMouseLeave={(e) => (e.target.type = 'password')}
                          autoComplete="off"
                          onChange={(event) => handleInputChange(index, event)}
                        />
                        {inputField.value && (
                          <button
                            className="absolute right-2 top-2 rounded-full p-1 text-gray-500 hover:text-gray-700"
                            type="button"
                            onClick={() => handleClearValueField(index)}
                            disabled={!validity[index]?.value}
                          >
                            <RxCrossCircled />
                          </button>
                        )}
                      </div>
                    </div>

                    <div className="flex items-center">
                      {inputFields?.length > 1 && (
                        <button
                          type="button"
                          onClick={() => handleDeleteField(index)}
                        >
                          <TooltipHorizon
                            extra="border border-gray-200 dark:border-gray-700"
                            trigger={
                              <p className="flex cursor-pointer items-center rounded-full border p-2 font-bold text-[#000] hover:font-medium hover:text-black dark:text-white">
                                <span>
                                  <RiDeleteBinLine className="text-xl" />
                                </span>
                              </p>
                            }
                            content="Delete"
                            placement="top"
                          />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="flex justify-end gap-4 pt-5">
              <button
                className="type-interface-01 text-default focus-visible:outline-focus-action flex h-10 cursor-pointer items-center rounded-xl border border-solid border-gray-800 px-3 py-2.5 outline-2 outline-offset-2 hover:bg-brand-500 hover:text-white focus-visible:outline active:bg-gray-900 active:text-white"
                type="submit"
                onClick={() => handleAddFields()}
              >
                {LABEL.ADD_LABEL}{' '}
                <span className="text-md ml-1">
                  <HiPlus />
                </span>
              </button>
              <button
                className={`cursor-pointer rounded-xl bg-blueSecondary px-4 py-2 text-sm font-medium capitalize text-white transition duration-200 ${
                  !hasChanges ||
                  inputFields.some((field) => !field.key || !field.value)
                    ? 'cursor-not-allowed opacity-50'
                    : 'hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90'
                } md:ml-2`}
                type="submit"
                onClick={() => handleSaveChanges()}
                disabled={
                  !hasChanges ||
                  inputFields.some((field) => !field.key || !field.value)
                }
              >
                {BUTTON_TEXT.SAVE_CHNG}
              </button>
            </div>
          </div>
        ))}
    </>
  )
}

export default SearchTableOrders
