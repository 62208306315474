import React, { useState, useEffect } from 'react'
import {
  MdSupervisedUserCircle,
  MdOutlineAccountBalanceWallet,
  MdSettings,
  MdOutlineFolderOpen,
  MdDashboardCustomize,
  MdCloudQueue,
} from 'react-icons/md'
import { TbBrandElastic } from 'react-icons/tb'
import DashboardsDefault from 'views/dashboards/default'
import NewService from 'views/newService'
// import SecurityAndPoliciesIFrame from 'views/securityAndPolicies/components/SecurityAndPoliciesIFrame'
import WebService from 'views/application/webServices'
import Billing from './views/billing'
import DeployingWebService from 'views/application/deployingWebService'
import MainProjectList from 'views/project/projectList'
import MainApplicationList from 'views/application/applicationList'
import CreateProjectList from 'views/project/createProject'
import CreateDomainList from '../src/views/application/webServices/components/AddDomain'

import USERSCardplace from 'views/application/applicationCards'
import InfoUrlComponent from './common/UrlConfigurationCheck'
// import GitInvite from 'views/settings/git'
// import ArgoCDInvite from 'views/settings/argoCD'
import ArtifactRepository from 'views/settings/artifactRepo'
import ChatContainer from './views/chatBot/index'
import OrganizationForm from 'views/settings/organization'
import KuberneteToken from 'views/settings/kuberneteToken'
import SubmitTicket from 'views/support/SubmitTickets'
import TicketsList from 'views/support/Tickets'
import TicketDetails from 'views/support/TicketDetails'
// import ElasticIFrame from 'views/observability'
// import { GrShieldSecurity } from 'react-icons/gr'
import { SiAzureartifacts } from 'react-icons/si'
// import { MdOutlineDns } from 'react-icons/md'
// import { SiArgo } from 'react-icons/si'
import { useParams } from 'react-router-dom'
import { SiUptimekuma } from 'react-icons/si'
import { AiOutlineDashboard } from 'react-icons/ai'
import { MdOutlineGeneratingTokens } from 'react-icons/md'
import { IoBarChartOutline, IoGitBranchOutline } from 'react-icons/io5'
import appConstants from 'common/config/appConstants'

const RoutesComponent = () => {
  const { id, applicationType, application_id } = useParams()
  const { URL_CONFIGURATION } = appConstants
  const [projectName, setProjectName] = useState()
  const [serviceName, setServiceName] = useState()
  const [routes, setRoutes] = useState([])
  const keycloakUrl = process.env.REACT_APP_KEYCLOAK_URL

  useEffect(() => {
    const storedProjectName = localStorage.getItem('projectName')
    const storedServiceName = localStorage.getItem('serviceName')
    if (storedServiceName) {
      setServiceName(storedServiceName)
    }
    if (storedProjectName) {
      setProjectName(storedProjectName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem('projectName'), localStorage.getItem('serviceName')])

  useEffect(() => {
    setRoutes([
      {
        name: 'Dashboard',
        path: '/',
        layout: '/afterLogin',
        icon: <AiOutlineDashboard className="text-inherit h-6 w-6" />,
        collapse: false,
        component: <DashboardsDefault />,
        isComing: false,
        isVisible: true,
      },
      {
        name: 'Create a new service',
        layout: '/afterLogin',
        path: '/new-service/:id/:applicationType',
        component: <NewService />,
        isVisible: false,
        breadcrum: [
          { label: 'Projects', link: '/projects' },
          {
            label: projectName,
            link: `/applications/${id}`,
            projectName: projectName,
          },
          {
            label: 'Services',
            link: `/application/${id}`,
          },
        ],
      },
      {
        name: 'Service',
        layout: '/afterLogin',
        path: '/application/:id',
        component: <USERSCardplace />,
        isComing: false,
        isVisible: false,
        breadcrum: [
          { label: 'Projects', link: '/projects' },
          {
            label: projectName,
            link: `/applications/${id}`,
            projectName: projectName,
          },
          { label: 'Services', link: '', breadCrumDisabled: true },
        ],
      },
      {
        name: 'Deploying Service',
        layout: '/afterLogin',
        path: '/deploying-service/:id/:applicationType',
        component: <DeployingWebService />,
        isVisible: false,
        isComing: false,
        breadcrum: [
          { label: 'Project', link: '/projects' },
          {
            label: projectName,
            link: `/applications/${id}`,
            projectName: projectName,
          },
          {
            label: 'Services',
            link:
              applicationType === 'postgresql' || applicationType === 'redis'
                ? `/application/${id}`
                : `new-service/${id}/${applicationType}`,
          },
        ],
      },
      {
        name: 'Environment Management',
        path: '/create-branch-management',
        layout: '/afterLogin',
        icon: <MdOutlineFolderOpen className="text-inherit h-6 w-6" />,
        component: <CreateDomainList />,
        isComing: false,
        isVisible: false,
        breadcrum: [
          { label: 'Project', link: '/projects' },
          {
            label: 'Applications',
            link: `/applications/${id}`,
            projectName: projectName,
          },
        ],
      },
      {
        name: 'Application Details',
        layout: '/afterLogin',
        path: '/web-services/:id/:application_id',
        component: <WebService />,
        isComing: false,
        isVisible: false,
        breadcrum: [
          { label: 'Project', link: '/projects' },
          { label: projectName, link: `/applications/${id}` },
          {
            label: serviceName,
            link: '',
            breadCrumDisabled: true,
            projectName: projectName,
            serviceName: serviceName,
          },
        ],
      },
      {
        name: 'Projects',
        path: '/projects',
        layout: '/afterLogin',
        icon: <MdOutlineFolderOpen className="text-inherit h-6 w-6" />,
        component: <MainProjectList />,
        isComing: false,
        isVisible: true,
      },
      {
        name: 'Applications',
        path: '/applications/:id',
        layout: '/afterLogin',
        icon: <MdOutlineFolderOpen className="text-inherit h-6 w-6" />,
        component: <MainApplicationList />,
        isComing: false,
        isVisible: false,
        breadcrum: [
          { label: 'Projects', link: '/projects' },
          {
            label: `${projectName}`,
            link: `/applications/${id}`,
            projectName: projectName,
            breadCrumDisabled: true,
          },
        ],
      },
      {
        name: 'Subscription Details',
        layout: '/afterLogin',
        path: '/subscription',
        component: <Billing />,
        isComing: false,
        isVisible: false,
        breadcrum: [{ label: 'subscription', link: '/subscription' }],
      },
      {
        name: 'Project',
        path: '/create-project',
        layout: '/afterLogin',
        icon: <MdOutlineFolderOpen className="text-inherit h-6 w-6" />,
        component: <CreateProjectList />,
        isComing: false,
        isVisible: false,
        breadcrum: [
          { label: 'Projects', link: '/projects' },
          { label: 'Create', link: '/create-project' },
        ],
      },
      {
        name: 'Project',
        path: '/edit-project/:id',
        layout: '/afterLogin',
        icon: <MdOutlineFolderOpen className="text-inherit h-6 w-6" />,
        component: <CreateProjectList />,
        isComing: false,
        isVisible: false,
        breadcrum: [
          { label: 'Project', link: '/projects' },
          { label: 'Edit', link: `/edit-project/${id}` },
        ],
      },
      // {
      //   name: 'Observability',
      //   path: observabilityUrl,
      //   // path: '/observability',
      //   layout: '/afterLogin',
      //   icon: <TbBrandElastic className="text-inherit h-6 w-6" />,
      //   // component: <ElasticIFrame />,
      //   collapse: false,
      //   isVisible: true,
      //   isComing: false,
      //   openInNewTab: true,
      // },
      {
        name: 'Observability',
        path: '/observability',
        icon: <TbBrandElastic className="text-inherit h-6 w-6" />,
        collapse: true,
        items: [
          // {
          //   name: 'ELK',
          //   layout: '/afterLogin',
          //   path: observabilityUrl,
          //   // path: '/observability/elk',
          //   component: <GitInvite />,
          //   icon: <SiElasticsearch className="text-inherit h-6 w-6" />,
          //   collapse: false,
          //   isVisible: true,
          //   isComing: false,
          //   openInNewTab: true,
          //   parentTitle: 'Observability',
          //   // breadcrum: [{ label: 'observability', link: '/observability' }],
          // },
          {
            name: 'Logs',
            layout: '/afterLogin',
            path: 'observability-logs',
            component: (
              <InfoUrlComponent
                url={URL_CONFIGURATION.LOG_MONITORING_URL}
                title={URL_CONFIGURATION.LOG_MONITORING_TITLE}
                text={URL_CONFIGURATION.LOG_MONITORING_INFO}
                img={URL_CONFIGURATION.LOG_MONITORING_IMG}
              />
            ),
            icon: <MdOutlineFolderOpen className="text-inherit h-6 w-6" />,
            collapse: false,
            isVisible: true,
            isComing: false,
            parentTitle: 'Observability',
            breadcrum: [{ label: 'Logs', link: '/logs' }],
          },
          {
            name: 'Metrics',
            layout: '/afterLogin',
            path: 'observability-metrics',
            component: (
              <InfoUrlComponent
                url={URL_CONFIGURATION.METRICS_MONITORING_URL}
                title={URL_CONFIGURATION.METRICS_MONITORING_TITLE}
                text={URL_CONFIGURATION.METRICS_MONITORING_INFO}
                img={URL_CONFIGURATION.METRICS_MONITORING_IMG}
              />
            ),
            icon: <IoBarChartOutline className="text-inherit h-6 w-6" />,
            collapse: false,
            isVisible: true,
            isComing: false,
            parentTitle: 'Observability',
            breadcrum: [{ label: 'metrics', link: '/metrics' }],
          },
          {
            name: 'Traces',
            layout: '/afterLogin',
            path: 'observability-traces',
            component: (
              <InfoUrlComponent
                url={URL_CONFIGURATION.TRACING_FOR_SYSTEMS_URL}
                title={URL_CONFIGURATION.TRACING_FOR_SYSTEMS_TITLE}
                text={URL_CONFIGURATION.TRACING_FOR_SYSTEMS_INFO}
                img={URL_CONFIGURATION.TRACING_FOR_SYSTEMS_IMG}
              />
            ),
            icon: <IoGitBranchOutline className="text-inherit h-6 w-6" />,
            collapse: false,
            isVisible: true,
            isComing: false,
            parentTitle: 'Observability',
            breadcrum: [{ label: 'tracing', link: '/tracing' }],
          },
        ],
      },
      {
        name: 'System Health',
        path: 'system-health',
        component: (
          <InfoUrlComponent
            url={URL_CONFIGURATION.SYSTEM_HEALTH_URL}
            title={URL_CONFIGURATION.SYSTEM_HEALTH_TITLE}
            text={URL_CONFIGURATION.SYSTEM_HEALTH_INFO}
            img={URL_CONFIGURATION.SYSTEM_HEALTH_IMG}
          />
        ),
        layout: '/afterLogin',
        icon: <SiUptimekuma className="text-inherit h-6 w-6" />,
        isComing: false,
        isVisible: true,
      },
      {
        name: 'Continuous Delivery',
        path: 'continuous-delivery',
        component: (
          <InfoUrlComponent
            url={URL_CONFIGURATION.CONTINUOUS_DELIVERY_URL}
            title={URL_CONFIGURATION.CONTINUOUS_DELIVERY_TITLE}
            text={URL_CONFIGURATION.CONTINUOUS_DELIVERY_INFO}
            img={URL_CONFIGURATION.CONTINUOUS_DELIVERY_IMG}
          />
        ),
        layout: '/afterLogin',
        icon: <MdCloudQueue className="text-inherit h-6 w-6" />,
        isVisible: true,
        isComing: false,
      },
      {
        name: 'IAM',
        layout: '/afterLogin',
        path: keycloakUrl,
        icon: <MdSupervisedUserCircle className="text-inherit h-6 w-6" />,
        isComing: false,
        isVisible: true,
        openInNewTab: true,
      },
      {
        name: 'FinOps',
        path: '/finOps',
        component: (
          <InfoUrlComponent
            url={URL_CONFIGURATION.FINOPS_URL}
            title={URL_CONFIGURATION.FINOPS_TITLE}
            text={URL_CONFIGURATION.FINOPS_INFO}
            img={URL_CONFIGURATION.FINOPS_IMG}
          />
        ),
        layout: '/afterLogin',
        icon: (
          <MdOutlineAccountBalanceWallet className="text-inherit h-6 w-6" />
        ),
        isVisible: true,
        isComing: false,
      },
      {
        name: 'Cluster Dashboard',
        path: '/clusterDashboard',
        component: (
          <InfoUrlComponent
            url={URL_CONFIGURATION.CLUSTER_URL}
            title={URL_CONFIGURATION.CLUSTER_TITLE}
            text={URL_CONFIGURATION.CLUSTER_INFO}
            img={URL_CONFIGURATION.CLUSTER_IMG}
          />
        ),
        layout: '/afterLogin',
        icon: <MdDashboardCustomize className="text-inherit h-6 w-6" />,
        isComing: false,
        isVisible: true,
      },
      // {
      //   name: 'Security and Policies',
      //   path: '/security-policies',
      //   layout: '/afterLogin',
      //   icon: <GrShieldSecurity className="text-inherit h-6 w-6" />,
      //   component: <SecurityAndPoliciesIFrame />,
      //   isComing: true,
      //   isVisible: true,
      // },
      {
        name: 'Create Ticket',
        layout: '/afterLogin',
        path: '/ticket-create',
        component: <SubmitTicket />,
        isComing: false,
        isVisible: false,
      },
      {
        name: 'Tickets',
        layout: '/afterLogin',
        path: '/ticket-list',
        component: <TicketsList />,
        isComing: false,
        isVisible: false,
      },
      {
        name: 'Ticket Details',
        layout: '/afterLogin',
        path: '/ticket-details/:id',
        component: <TicketDetails />,
        isComing: false,
        isVisible: false,
        breadcrum: [
          {
            label: 'Tickets',
            link: '/ticket-list',
          },
          {
            label: 'Ticket Details',
            link: '',
            breadCrumDisabled: true,
          },
          {
            label: `${id}`,
            link: '',
            breadCrumDisabled: true,
          },
        ],
      },
      {
        name: 'Settings',
        path: '/settings',
        icon: <MdSettings className="text-inherit h-6 w-6" />,
        collapse: true,
        items: [
          // {
          //   name: 'Argo CD',
          //   layout: '/afterLogin',
          //   path: '/settings/argoCD',
          //   icon: <SiArgo className="text-inherit h-6 w-6" />,
          //   component: <ArgoCDInvite />,
          //   isComing: false,
          //   isVisible: true,
          //   parentTitle: 'Settings',
          //   breadcrum: [
          //     { label: 'Settings', link: '/settings/argoCD' },
          //     { label: 'Argo CD', link: '/settings/argoCD' },
          //   ],
          // },
          {
            name: 'Organization',
            layout: '/afterLogin',
            path: '/settings/organization',
            icon: <MdOutlineFolderOpen className="text-inherit h-6 w-6" />,
            component: <OrganizationForm />,
            isComing: false,
            isVisible: true,
            parentTitle: 'Settings',
            breadcrum: [
              { label: 'Settings', link: '/settings/organization' },
              { label: 'Organization', link: '/settings/organization' },
            ],
          },
          {
            name: 'Artifact Repository',
            layout: '/afterLogin',
            icon: <SiAzureartifacts className="text-inherit h-6 w-6" />,
            path: '/settings/artifact-repository',
            isComing: false,
            component: <ArtifactRepository />,
            isVisible: true,
            parentTitle: 'Settings',
            breadcrum: [
              { label: 'Settings', link: '/settings/artifact-repository' },
              {
                label: 'Artifact Repository',
                link: '/settings/artifact-repository',
              },
            ],
          },
          {
            name: 'Kubernetes Token',
            layout: '/afterLogin',
            icon: (
              <MdOutlineGeneratingTokens className="text-inherit h-6 w-6" />
            ),
            path: '/settings/kubernete-token',
            isComing: false,
            component: <KuberneteToken />,
            isVisible: true,
            parentTitle: 'Settings',
            breadcrum: [
              { label: 'Settings', link: '/settings/kubernete-token' },
              {
                label: 'Kubernete Token',
                link: '/settings/kubernete-token',
              },
            ],
          },
          {
            name: `${id ? 'Conversation' : 'New Conversation'}`,
            layout: '/afterLogin',
            path: '/chatbot/:id?',
            isComing: false,
            component: <ChatContainer />,
            isVisible: false,
          },
          // {
          //   name: 'DNS',
          //   layout: '/afterLogin',
          //   icon: <MdOutlineDns className="text-inherit h-6 w-6" />,
          //   path: '/settings/dns',
          //   isComing: true,
          //   isVisible: true,
          //   parentTitle: 'Settings',
          // },
        ],
      },
    ])
  }, [projectName, id, applicationType, serviceName, application_id])

  return routes
}

export default RoutesComponent
