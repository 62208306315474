import loginImg from 'assets/img/layers/confixa-logo.png'
import appConstants from 'common/config/appConstants'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { handleBillingApiCall, getOneBillingData } from 'common/commonFunction'
import moment from 'moment'

const { SUBSCRIPTION_EXPIRED, EMAIL } = appConstants

/**
 * Function to handle fetching data for subscription status and rendering subscription details.
 */
function Subscription() {
  const navigate = useNavigate()
  /**
   * A description of the entire function.
   *
   */

  const fetchData = async () => {
    try {
      const checkLicenseData = await handleBillingApiCall()
      if (checkLicenseData && checkLicenseData === 'Valid') {
        navigate('/')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])
  const formattedDate = moment(getOneBillingData?.deactivatesOn).format(
    ' Do MMMM YYYY'
  )
  const activatedDate = moment(getOneBillingData?.activatedOn).format(
    ' Do MMMM YYYY'
  )
  const emailBody = encodeURIComponent(
    EMAIL.BODY.replace('{deactivateDate}', formattedDate)
      .replace('{activateDate}', activatedDate)
      .replace('{deactivateDate}', formattedDate)
  )

  return (
    <>
      <div className="flex h-full items-center justify-around overflow-hidden rounded-bl-[120px] bg-gradient-to-b from-purple-lightViolet via-purple-hanPurple to-purple-darkHanPurple pb-[90px] pt-[58px]">
        <div className="">
          <Link to="/">
            <img
              src={loginImg}
              alt="Confixa Logo"
              className="mb-4 h-12 w-full object-contain"
            />
          </Link>
          <div className="py-2 text-3xl text-white">
            {SUBSCRIPTION_EXPIRED.TITLE}
          </div>
        </div>
      </div>
      <div className="mt-[-50px] flex justify-center">
        <div className="h-[50%] w-[60%] rounded-2xl bg-white p-8">
          <span className="text-3xl font-bold text-orange-500">
            {SUBSCRIPTION_EXPIRED.END_TITLE}
          </span>
          {formattedDate && (
            <div>
              <span className="text-2xl font-bold text-orange-500">
                {SUBSCRIPTION_EXPIRED.EXPIRED_ON} {formattedDate}
              </span>
            </div>
          )}
          <div className="pt-6">
            <span className="font-semibold">
              {SUBSCRIPTION_EXPIRED.EXPIRED_TITLE}
            </span>
            <p className="pt-1">
              {SUBSCRIPTION_EXPIRED.EXPIRED_MESSAGE}{' '}
              <a
                href={`mailto:${SUBSCRIPTION_EXPIRED.CONTACT_EMAIL}?subject=${EMAIL.SUBJECT}&body=${emailBody}`}
                className="font-semibold text-brand-500"
              >
                {SUBSCRIPTION_EXPIRED.CONTACT_EMAIL}
              </a>
            </p>
          </div>

          <div className="py-6">
            <span className="font-semibold">
              {SUBSCRIPTION_EXPIRED.RENEW_MESSAGE}
            </span>
            <p>{SUBSCRIPTION_EXPIRED.CONNECTED_MESSAGE}</p>
          </div>

          {/* <div className="flex rounded-lg bg-gray-100 px-4 py-2">
            <div className="flex-shrink-0 font-semibold">Note :</div>
            <div className="pl-2">
              {SUBSCRIPTION_EXPIRED.NOTE}{' '}
              <a
                href={`mailto:${SUBSCRIPTION_EXPIRED.CONTACT_EMAIL}?subject=${EMAIL.SUBJECT}&body=${emailBody}`}
                className="font-semibold text-brand-500"
              >
                {SUBSCRIPTION_EXPIRED.CONTACT_EMAIL}
              </a>
            </div>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default Subscription
