import React, { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import Card from 'components/card'
import CustomSelect from 'common/CustomSelect'
import ReactQuill from 'react-quill'
import appConstants from 'common/config/appConstants'
import { GrAttachment } from 'react-icons/gr'
import { AiOutlineClose } from 'react-icons/ai'
import { useToaster } from 'common/Toaster'
import ApiCaller from 'common/services/apiServices'
import apiConfig from 'common/config/apiConfig'
import { formatFileSize } from 'common/commonFunction'
import { useSpinner } from 'common/SpinnerLoader'

const SubmitTicket = () => {
  const { LABEL, TOAST, SUBMIT_TICKET_TYPES, SUPPORT_PRIORITY_LIST } =
    appConstants
  const { addToast } = useToaster()
  const apiService = ApiCaller()
  const navigate = useNavigate()
  const [isEnvDisable, setIsEnvDisable] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  const type = SUBMIT_TICKET_TYPES
  const priority = SUPPORT_PRIORITY_LIST
  const [selectedFiles, setSelectedFiles] = useState([])
  const [attachments, setAttachments] = useState([])
  const { showSpinner, hideSpinner } = useSpinner()
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      subject: '',
      description: '',
      type: '',
      priority: '',
      attachments: [], // Initialize attachment as an empty array
    },
  })

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      ['clean'],
    ],
  }

  const onFormSubmit = async (formValue) => {
    setIsEnvDisable(true)
    showSpinner()
    try {
      const data = new FormData()
      if (attachments?.length > 0) {
        let totalSize = 0
        let errorMsg = ''
        const MAX_SIZE_MB = 20
        const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024
        let isFileSizeValid = true

        for (let i = 0; i < attachments.length; i++) {
          const fileSize = attachments[i].size

          if (fileSize > MAX_SIZE_BYTES) {
            errorMsg = `File ${attachments[i].name} exceeds the 20MB limit.`
            isFileSizeValid = false
            break
          }

          totalSize += fileSize
        }
        const totalSizeMB = (totalSize / (1024 * 1024)).toFixed(2)

        if (isFileSizeValid && totalSize <= MAX_SIZE_BYTES) {
          for (let i = 0; i < attachments.length; i++) {
            data.append('attachments[]', attachments[i]) // Append the attachments
          }
        } else if (isFileSizeValid) {
          setErrMsg(
            `Total size ${totalSizeMB}MB exceeds ${MAX_SIZE_MB}MB limit.`
          )
          setIsEnvDisable(false)
          hideSpinner()
          return
        } else {
          setErrMsg(errorMsg)
          setIsEnvDisable(false)
          hideSpinner()
          return
        }
      }
      data.append('subject', formValue?.subject)
      data.append('type', formValue?.type.value)
      data.append('description', formValue.description)
      data.append('priority', formValue.priority?.priorityValue)
      const apiUrl = apiConfig.SUPPORT_CREATE_TICKET
      const response = await apiService.apiCall('post', apiUrl, data)
      if (response?.data?.code === 200) {
        const ticketId = response.data?.data?.id
        setAttachments([])
        setSelectedFiles([])
        setErrMsg('')
        navigate(`/ticket-details/${ticketId}`)
        addToast({
          title: response?.data?.msg,
          type: 'success',
        })
      } else {
        addToast({
          title: TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      setIsEnvDisable(false)
      hideSpinner()
    }
  }

  const isLargeFile = (size) => size > 20 * 1024 * 1024

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files) // Convert FileList to an array
    const filesData = files.map((file) => ({
      name: file.name,
      size: formatFileSize(file.size),
      rawSize: file.size, // Store raw size for comparison
      file, // Store the file object
    }))
    // Update attachments state
    setAttachments((prevData) => [...prevData, ...files])
    // Update selectedFiles state
    setSelectedFiles((prevFiles) => [...prevFiles, ...filesData])
  }

  const removeFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index))
    setAttachments((prevFiles) => prevFiles.filter((_, i) => i !== index))
    setErrMsg('')
  }

  return (
    <>
      <Card extra="w-full h-full pb-[35px] pt-[3px]">
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <div className="pl-[33px] pr-[28px]">
            <div className="mb-2 mt-[31px]">
              Subject <span className="text-red-500">*</span>
            </div>
            <div className="flex w-full items-center gap-3 rounded-2xl border border-gray-200 outline-none dark:!border-white/10 dark:!bg-navy-800">
              <input
                type="text"
                id="subject"
                autoComplete="off"
                placeholder="Enter Subject"
                className="placeholder:text-lightFourth md:text-md h-full w-full rounded-2xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                {...register('subject', {
                  required: 'Subject is required',
                })}
              />
            </div>
            {errors.subject && (
              <span className="error-message">{errors.subject.message}</span>
            )}

            <div className="mb-2 mt-[31px]">
              Type <span className="text-red-500">*</span>
            </div>
            <div className="w-full items-center rounded-2xl border border-gray-200 outline-none dark:!border-white/10 dark:!bg-navy-800">
              <Controller
                name="type"
                control={control}
                rules={{ required: 'Type is required' }}
                render={({ field }) => (
                  <CustomSelect
                    {...field}
                    options={type}
                    placeholderText="Select Type"
                    onChange={(value) => field.onChange(value)}
                    isErrorField={errors.version ? true : false}
                  />
                )}
              />
            </div>
            {errors.type && (
              <span className="error-message">{errors.type.message}</span>
            )}

            <div className="mb-2 mt-[31px]">
              Priority <span className="text-red-500">*</span>
            </div>
            <div className="w-full items-center rounded-2xl border border-gray-200 outline-none dark:!border-white/10 dark:!bg-navy-800">
              <Controller
                name="priority"
                control={control}
                rules={{ required: 'Priority is required' }}
                render={({ field }) => (
                  <CustomSelect
                    {...field}
                    options={priority}
                    placeholderText="Select Priority"
                    onChange={(value) => field.onChange(value)}
                    isErrorField={errors.version ? true : false}
                  />
                )}
              />
            </div>
            {errors.priority && (
              <span className="error-message">{errors.priority.message}</span>
            )}

            <div className="mb-2 mt-[31px]">
              {LABEL.DESCRIPTION} <span className="text-red-500">*</span>
            </div>
            <div className="flex w-full items-center rounded-2xl border border-gray-200 outline-none dark:border dark:!border-white/10 dark:!bg-navy-800">
              <Controller
                name="description"
                control={control}
                rules={{
                  required: 'Description is required',
                  validate: (value) => {
                    if (value === '' || value === '<p><br></p>') {
                      return 'Description is required'
                    }
                  },
                }}
                render={({ field }) => (
                  <ReactQuill
                    {...field}
                    onBlur={(e) => field.onBlur()}
                    value={field.value || ''}
                    className="custom-quill w-full rounded-2xl text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                    theme="snow"
                    modules={modules}
                    placeholder="Start typing your text here..."
                  />
                )}
              />
            </div>
            {errors.description && (
              <span className="error-message">
                {errors.description.message}
              </span>
            )}

            <div className="mb-2 mt-[31px]">
              <button
                type="button"
                className="mt-2 flex items-center rounded-xl px-4 py-2 text-sm font-medium text-blueSecondary transition duration-200 focus:border focus:border-gray-200 dark:text-white"
                onClick={() => document.getElementById('attachment').click()}
              >
                <GrAttachment className="mr-1 text-black dark:text-white" />
                Attachment
              </button>
              <input
                type="file"
                id="attachment"
                multiple
                className="hidden"
                onChange={handleFileChange}
              />

              {selectedFiles.map((file, index) => (
                <div
                  key={index}
                  className={`mr-2 mt-2 flex inline-flex items-center border p-2 text-sm text-navy-700 dark:text-white ${
                    isLargeFile(file.rawSize)
                      ? 'border-red-500 line-through'
                      : 'border-gray-300'
                  }`}
                >
                  <button
                    type="button"
                    onClick={() => removeFile(index)}
                    className="mx-2 text-red-500 hover:text-red-700"
                  >
                    <AiOutlineClose />
                  </button>
                  <span>{file.name}</span>
                  <span className="ml-6">{file.size}</span>
                </div>
              ))}
            </div>
            {errMsg && <span class="error-message mt-3">{errMsg}</span>}
            <div className="mt-7 grid md:flex lg:flex">
              <div className="flex w-full items-center justify-end">
                <button
                  type="button"
                  className="rounded-xl bg-gray-400 px-8 py-2 text-sm font-medium capitalize text-black transition duration-200 hover:bg-gray-600 
                                                active:bg-gray-700 dark:bg-gray-400 dark:hover:bg-gray-300 md:ml-2"
                  onClick={() => navigate('/')}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isEnvDisable}
                  className={`rounded-xl bg-blueSecondary px-8 py-2 text-sm font-medium capitalize text-white transition duration-200 dark:bg-brand-400 
                     md:ml-2 ${
                       isEnvDisable
                         ? 'cursor-not-allowed opacity-50'
                         : 'hover:bg-brand-800 active:bg-brand-700 dark:hover:bg-brand-300'
                     }`}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </Card>
    </>
  )
}

export default SubmitTicket
