import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { MdEdit } from 'react-icons/md'
import TooltipHorizon from '../../../../components/tooltip'
import appConstants from 'common/config/appConstants'
import ApiCaller from 'common/services/apiServices'
import apiConfig from 'common/config/apiConfig'
import { useToaster } from 'common/Toaster'
import debounce from 'lodash.debounce'
import { checkDomainName } from 'common/commonFunction'
import { useParams } from 'react-router-dom'
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'
import { BsArrowDown, BsArrowUp } from 'react-icons/bs'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
} from '@chakra-ui/modal'
import { RxCrossCircled } from 'react-icons/rx'
import { BsFillCheckCircleFill } from 'react-icons/bs'

function SearchTableOrders(props) {
  const organizationData = JSON.parse(localStorage.getItem('organizationData'))
  const {
    tableData,
    onPageChange,
    toggleState,
    currentPage,
    totalDomainCount,
    getDomainManagementData,
  } = props

  const itemsPerPage = 10
  const apiService = ApiCaller()
  const { addToast } = useToaster()
  const { application_id } = useParams()
  const columns = useMemo(() => {
    return [
      {
        Header: 'SR.NO',
        accessor: 'index',
      },
      {
        Header: 'Branch',
        accessor: 'branchName',
      },
      {
        Header: 'Domain Name',
        accessor: 'domain',
      },
      ...(organizationData?.cloud_type === 'aws'
        ? [
            {
              Header: 'Artifact Name',
              accessor: 'artifactRepo',
            },
          ]
        : []),
      {
        Header: 'Actions',
        accessor: 'actions',
        id: 'actions',
      },
    ]
  }, [organizationData?.cloud_type])
  const [data, setData] = useState([])
  const [totalPageCount, setPageCount] = useState(0)
  const [pageIndex, setPageIndex] = useState(currentPage)
  const [domainName, setDomainName] = useState()
  const [domainRequiredErr, setDomainRequiredErr] = useState()
  const [domainErrors, setErrors] = useState()
  const [isLoadingDomainCheck, setIsLoadingDomainCheck] = useState(false)
  const [domainCheck, setDomainCheck] = useState()
  const [isOpen, setIsOpen] = useState(false)
  const [isBtnDisable, setIsBtnDisable] = useState(false)
  const [isFlag, setIsFlag] = useState(false)
  const [branchName, setBranchName] = useState()
  const [artifactHasValue, setArtifactHasValue] = useState(false)
  const [artifactRepoName, setArtifactRepoName] = useState()
  const [artifactError, setArtifactError] = useState('')
  const { NO_DATA_AVAIL, LABEL, WEB_SERVICES, BUTTON_TEXT, TOAST } =
    appConstants

  useEffect(() => {
    if (tableData) {
      const data = tableData[pageIndex]
      if (data) {
        setData(data)
      }
      setPageCount(totalDomainCount)
    }
  }, [tableData, pageIndex])

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )
  let count = 1
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    gotoPage,
  } = tableInstance

  const page = tableInstance.rows

  const changePage = (page) => {
    setPageIndex(page)
    gotoPage(page)
    onPageChange(page + 1)
  }

  const handleEdit = (data) => {
    setDomainName(data?.original?.domain)
    setBranchName(data?.original?.branchName)
    setArtifactRepoName(data?.original?.artifactRepo)
    data?.original?.artifactRepo
      ? setArtifactHasValue(true)
      : setArtifactHasValue(false)
    setIsOpen(true)
    setErrors('')
    setDomainRequiredErr('')
    setArtifactError('')
  }

  const updateDomainArtifact = async () => {
    setIsBtnDisable(true)
    try {
      const payload = {
        applicationId: application_id,
        organizationId: organizationData?.id,
        branchName: branchName,
        domainName: domainName,
      }
      if (organizationData?.cloud_type === 'aws' && artifactRepoName?.length) {
        payload.artifactRepo = artifactRepoName
      }
      const apiUrl = apiConfig.UPDATE_DOMAIN_ARTIFACT
      const response = await apiService.apiCall('put', apiUrl, payload)
      if (response?.data?.code === 200) {
        setIsOpen(false)
        getDomainManagementData()
      } else {
        addToast({
          title: response?.data?.msg || TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      setIsBtnDisable(false)
    }
  }

  const handleDomainChange = (e) => {
    setIsFlag(false)
    let value = e?.target?.value
    const regex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    let newErrors = ''
    if (!value.trim()) {
      setDomainRequiredErr('Domain is required')
    } else {
      setDomainRequiredErr('')
    }

    if (value && !regex.test(value)) {
      newErrors = 'Invalid domain name'
    } else {
      newErrors = ''
    }
    let newDomains = value.toLowerCase()
    setDomainName(newDomains)
    setErrors(newErrors)
    setIsLoadingDomainCheck(value.length !== 0)
    if (value.length !== 0 && !newErrors?.length) {
      handleCheckDomain(value)
    } else {
      setDomainCheck(false)
    }
  }

  const handleArtifactRepoChange = (e) => {
    const regex = /^[a-z-]*$/
    const value = e.target.value
    if (regex.test(value)) {
      setArtifactRepoName(value)
    }
    if (artifactHasValue && !value) {
      setArtifactError('Artifact name is required')
    } else {
      setArtifactError('')
    }
  }

  const handleCheckDomain = useCallback(
    debounce(async (data) => {
      try {
        const payload = {
          domainName: data,
        }
        const response = await checkDomainName(payload)
        if (response?.data?.code === 200) {
          setIsFlag(true)
          setDomainCheck(response.data.data?.isAvailableForCreation)
          if (
            !response.data.data?.isAvailableForCreation &&
            organizationData?.cloud_type !== 'aws'
          ) {
            setDomainRequiredErr('Domain already exists')
          } else {
            setDomainRequiredErr('')
          }
        } else {
          addToast({
            title: response.data.msg,
            type: 'error',
          })
        }
      } catch (error) {
        addToast({
          title: TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
      } finally {
        setIsLoadingDomainCheck(false)
      }
    }, 500),
    []
  )

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
        className="!z-[1010]"
      >
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="!z-[1004] !m-auto max-h-[550px] max-w-[450px] rounded-[20px] bg-white bg-clip-border pb-[18px] pt-[16px] dark:!bg-navy-800 dark:text-white">
          <div className="mb-[4px] flex w-full items-center justify-between px-[30px]">
            <ModalHeader className="!grow text-2xl font-bold ">
              {LABEL.UPDATE_DOMAIN}
            </ModalHeader>
            <ModalCloseButton />
          </div>
          <div className="my-4 border-b"></div>
          <ModalBody>
            <div className="px-[30px]">
              <div className="relative w-full">
                <div className="mb-2">{LABEL.BRANCH}</div>
                <div className="relative flex w-full items-center  gap-3 rounded-xl border border-gray-200 bg-lightPrimary outline-none dark:!border-white/10 dark:!bg-navy-800">
                  <input
                    type="text"
                    value={branchName || ''}
                    disabled={true}
                    className="placeholder:text-lightFourth md:text-md h-full w-full rounded-xl bg-lightPrimary p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                  />
                </div>
              </div>
              <div className="relative mt-4 w-full">
                <div className="mb-2">
                  {LABEL.DOMAIN_NAME} <span className="text-red-500">*</span>
                </div>
                <div className="relative flex w-full items-center gap-3 rounded-xl border border-gray-200 outline-none dark:!border-white/10 dark:!bg-navy-800">
                  <input
                    type="text"
                    value={domainName || ''}
                    onChange={(e) => handleDomainChange(e)}
                    className="placeholder:text-lightFourth md:text-md h-full w-full rounded-xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                    placeholder="Enter Domain Name"
                    pattern="^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                  />
                </div>
                {domainRequiredErr && (
                  <p className="text-sm text-red-500">{domainRequiredErr}</p>
                )}
                {domainErrors ? (
                  <p className="text-sm text-red-500">{domainErrors}</p>
                ) : (
                  domainName?.length > 0 &&
                  (isLoadingDomainCheck ? (
                    <span className="logs-loader absolute right-3 top-11 -translate-y-1/2 transform text-xl"></span>
                  ) : domainCheck && isFlag ? (
                    <BsFillCheckCircleFill className="success-message text-inherit absolute right-3 top-[3.4rem] h-6 w-6 -translate-y-1/2 transform" />
                  ) : (
                    isFlag &&
                    organizationData?.cloud_type !== 'aws' && (
                      <RxCrossCircled className="error-message text-inherit absolute right-3 top-[3.4rem] h-6 w-6 -translate-y-1/2 transform" />
                    )
                  ))
                )}

                {organizationData?.cloud_type === 'aws' && (
                  <div className="mt-4 w-full">
                    <div className="mb-2">
                      {LABEL.ARTIFACTS_REPOSITORY_NAME}
                    </div>
                    <div className="flex w-full items-center gap-3 rounded-xl border border-gray-200 outline-none dark:!border-white/10 dark:!bg-navy-800">
                      <input
                        type="text"
                        value={artifactRepoName || ''}
                        onChange={(e) => handleArtifactRepoChange(e)}
                        className="placeholder:text-lightFourth md:text-md h-full w-full rounded-xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                        placeholder="Enter Artifact Repository Name"
                      />
                    </div>
                    {artifactError && (
                      <p className="text-sm text-red-500">{artifactError}</p>
                    )}
                  </div>
                )}
              </div>

              <div className="mt-7 grid md:flex lg:flex">
                <div className="flex w-full items-center justify-end">
                  <button
                    disabled={
                      domainRequiredErr?.length ||
                      domainErrors?.length ||
                      isBtnDisable ||
                      artifactError.length
                    }
                    className={`rounded-xl bg-blueSecondary px-8 py-2 text-sm font-medium capitalize text-white transition duration-200 dark:bg-brand-400 md:ml-2 ${
                      domainRequiredErr?.length ||
                      domainErrors?.length ||
                      isBtnDisable ||
                      artifactError?.length
                        ? 'cursor-not-allowed opacity-50'
                        : 'hover:bg-brand-800 active:bg-brand-700 dark:hover:bg-brand-300'
                    } `}
                    onClick={updateDomainArtifact}
                  >
                    {BUTTON_TEXT.UPDATE}
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      {toggleState === WEB_SERVICES.DOMAIN_MANAGEMENT && (
        <div className="h-full w-full p-2 md:p-4">
          <div className="flex h-full w-full flex-col items-center justify-between !pt-0 md:flex-row md:py-2">
            <div className="mb-3 flex w-full w-full items-center rounded-full bg-white p-[5px] shadow-2xl shadow-white dark:!bg-navy-800 dark:shadow-none md:mb-0">
              {/* <div className="flex h-9 w-full flex-grow items-center rounded-full border-white bg-lightPrimary text-sm text-gray-600 dark:border dark:!bg-navy-900">
                <FiSearch className="mx-2 h-6 w-4 !text-gray-700 dark:!text-white" />
                <input
                  type="text"
                  placeholder="Search By Branch Name"
                  className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-full"
                  value={globalFilter}
                />
              </div> */}
            </div>
            {/* <button
              className={`
                'flex md:text-base' w-40 items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200
              `}
              onClick={() => navigate('/create-branch-management')}
            >
              <span>{BUTTON_TEXT.ADD}</span>
            </button> */}
          </div>
          <div className="mt-2 min-h-[150px] w-full overflow-x-scroll xl:overflow-hidden">
            <table {...getTableProps()} className="w-full">
              <thead className="w-full">
                {headerGroups.map((headerGroup, indexRow) => (
                  <tr
                    className="items-center border-b border-gray-200 dark:!border-white/10"
                    {...headerGroup.getHeaderGroupProps()}
                    key={indexRow}
                  >
                    {headerGroup.headers.map((column, index) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className="px-4 pb-[10px] text-left text-xs text-gray-600"
                        key={index}
                      >
                        <div className="flex items-center">
                          {column.render('Header')}
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <BsArrowDown className="ml-2" />
                            ) : (
                              <BsArrowUp className="ml-2" />
                            )
                          ) : (
                            ''
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, rowIndex) => {
                  prepareRow(row)
                  return (
                    <tr
                      className="border-b border-gray-200 dark:!border-white/10"
                      {...row.getRowProps()}
                      key={rowIndex}
                    >
                      {row.cells.map((cell, cellIndex) => {
                        let cellClassName = 'px-4'
                        let data

                        // Generalize rendering logic
                        if (cell.column.Header === 'SR.NO') {
                          cellClassName += ' w-24'
                          data = (
                            <div className="py-2 text-left font-medium text-navy-700 dark:text-white">
                              {pageIndex * itemsPerPage + count++}
                            </div>
                          )
                        } else if (cell.column.Header === 'Artifact Name') {
                          data = (
                            <div className="py-2 text-left font-medium text-navy-700 dark:text-white">
                              {cell.value || '-'}
                            </div>
                          )
                        }
                        // Action column handling
                        else if (cell.column.Header === 'Actions') {
                          data = (
                            <div className="flex space-x-2">
                              <TooltipHorizon
                                extra="border border-gray-200 dark:border-gray-700"
                                trigger={
                                  <p
                                    onClick={() => handleEdit(row)}
                                    className="flex cursor-pointer items-center rounded-full border p-1 font-bold text-[#000] hover:font-medium hover:text-black dark:text-white"
                                  >
                                    <MdEdit className="text-xl" />
                                  </p>
                                }
                                content="Edit"
                                placement="top"
                              />
                            </div>
                          )
                        } else {
                          // Default rendering for other columns
                          data = (
                            <div className="py-2 text-left font-medium text-navy-700 dark:text-white">
                              {cell.value}
                            </div>
                          )
                        }

                        return (
                          <td
                            className={cellClassName}
                            {...cell.getCellProps()}
                            key={cellIndex}
                          >
                            {data}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
            {page.length === 0 && (
              <div className="text-md mt-3 flex w-full justify-center text-center font-medium text-[#1B2559] dark:text-white">
                {NO_DATA_AVAIL}
              </div>
            )}

            {tableData.length > 0 && (
              <div className="mt-2 flex h-12 w-full items-center justify-between px-6">
                <div className="text-sm text-gray-700">
                  Showing {pageIndex * itemsPerPage + 1} to{' '}
                  {totalPageCount < pageIndex * itemsPerPage + data.length
                    ? totalPageCount
                    : pageIndex * itemsPerPage + data.length}{' '}
                  of {totalPageCount} entries
                </div>

                <div className="flex items-center">
                  <div className="ml-3 flex">
                    <button
                      className={`h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                        pageIndex === 0 ? 'cursor-not-allowed opacity-50' : ''
                      }`}
                      onClick={() => changePage(0)}
                      disabled={pageIndex === 0}
                    >
                      {'<<'}
                    </button>

                    <button
                      className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                        pageIndex === 0 ? 'cursor-not-allowed opacity-50' : ''
                      }`}
                      onClick={() => changePage(pageIndex - 1)}
                      disabled={pageIndex === 0}
                    >
                      {'<'}
                    </button>

                    {Array.from(
                      {
                        length: Math.min(
                          5,
                          Math.ceil(totalPageCount / itemsPerPage)
                        ),
                      },
                      (_, index) => {
                        const page =
                          Math.max(
                            0,
                            Math.min(
                              Math.ceil(totalPageCount / itemsPerPage) - 5,
                              pageIndex
                            )
                          ) + index
                        return (
                          <button
                            key={page}
                            className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                              pageIndex === page
                                ? 'cursor-not-allowed opacity-50'
                                : ''
                            }`}
                            onClick={() => changePage(page)}
                            disabled={pageIndex === page}
                          >
                            {page + 1}
                          </button>
                        )
                      }
                    )}

                    {/* Next button */}
                    <button
                      className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                        pageIndex >=
                        Math.ceil(totalPageCount / itemsPerPage) - 1
                          ? 'cursor-not-allowed opacity-50'
                          : ''
                      }`}
                      onClick={() => changePage(pageIndex + 1)}
                      disabled={
                        pageIndex >=
                        Math.ceil(totalPageCount / itemsPerPage) - 1
                      }
                    >
                      {'>'}
                    </button>

                    {/* Last button */}
                    <button
                      className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                        pageIndex >=
                        Math.ceil(totalPageCount / itemsPerPage) - 1
                          ? 'cursor-not-allowed opacity-50'
                          : ''
                      }`}
                      onClick={() =>
                        changePage(Math.ceil(totalPageCount / itemsPerPage) - 1)
                      }
                      disabled={
                        pageIndex >=
                        Math.ceil(totalPageCount / itemsPerPage) - 1
                      }
                    >
                      {'>>'}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default SearchTableOrders
