import Product from './components/Product'
import Card from 'components/card'
import { useNavigate, useParams } from 'react-router-dom'
import appConstants from 'common/config/appConstants'
import { getRepoName, isOnlyService } from 'common/commonFunction'

const { DEPLOYING_WEB_SERVICE } = appConstants

/**
 * Renders the DeployingWebServiceIndex component.
 *
 * @return {JSX.Element} The rendered component.
 */
const DeployingWebServiceIndex = () => {
  const { id, applicationType } = useParams()
  const navigate = useNavigate()
  let headerContent = null

  const checkOnlyService = isOnlyService(applicationType)
  if (checkOnlyService?.isNotService) {
    let FullBranchName = getRepoName()
    if (FullBranchName) {
      FullBranchName.replace(/"/g, '')
    } else {
      if (id) {
        navigate(`/new-service/${id}`)
      }
    }
    headerContent = (
      <h5 className="mb-7 mt-5 pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        {DEPLOYING_WEB_SERVICE.DEPLOYING_WEB_SERVICE_HEADER.replace(
          ':service',
          applicationType
        )}{' '}
        for .<span className="text-blueSecondary">{FullBranchName}.</span>
      </h5>
    )
  } else {
    headerContent = (
      <h5 className="mb-7 mt-5 pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        {DEPLOYING_WEB_SERVICE.DEPLOYING_WEB_SERVICE_HEADER.replace(
          ':service',
          applicationType
        )}
        .
      </h5>
    )
  }

  return (
    <div className="mt-3 h-full w-full">
      {headerContent}
      <Card extra={'h-full mx-auto pb-3'}>
        <div className="rounded-[20px]">
          <Product />
        </div>
      </Card>
    </div>
  )
}

export default DeployingWebServiceIndex
