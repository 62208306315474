import { useState } from 'react'
import Card from 'components/card'
import { MdEdit } from 'react-icons/md'
import { FiSearch } from 'react-icons/fi'
import { IoTrashOutline } from 'react-icons/io5'
import TooltipHorizon from '../../../../components/tooltip'
import { GrUpdate } from 'react-icons/gr'
import { useSpinner } from 'common/SpinnerLoader'
import ApiCaller from 'common/services/apiServices'
import apiConfig from 'common/config/apiConfig'
import { useToaster } from 'common/Toaster'
import appConstants from 'common/config/appConstants'
import { useDialogContext } from 'contexts/DialogProvider'

const { TOAST, LABEL, BUTTON_TEXT, NO_DATA } = appConstants
const apiService = ApiCaller()

const LabelCard = ({ labelList, labelListChange }) => {
  const { showSpinner, hideSpinner } = useSpinner()
  const { addToast } = useToaster()
  const { openDialog, onClose } = useDialogContext()
  const [newLabel, setNewLabel] = useState(false)
  const [editingLabelId, setEditingLabelId] = useState(null)
  const [showDropdown, setShowDropdown] = useState(false)
  const [labelName, setLabelName] = useState('')
  const [oldLabel, setOldLabel] = useState('')
  const [labelDescription, setLabelDescription] = useState('')
  const [labelColor, setLabelColor] = useState('#d73a4a')
  const [globalFilter, setGlobalFilter] = useState('')
  const [isButtonFlag, setIsButtonFlag] = useState(false)
  const colors = [
    '#B60205',
    '#D93F0B',
    '#FBCA04',
    '#0E8A16',
    '#006B75',
    '#1D76DB',
    '#0052CC',
    '#5319E7',
    '#E99695',
    '#F9D0C4',
    '#FEF2C0',
    '#C2E0C6',
    '#BFDADC',
    '#C5DEF5',
    '#BFD4F2',
    '#D4C5F9',
  ]

  /**
   * Sets the label color and hides the color dropdown.
   *
   * @param {string} color - The color to set.
   * @return {void}
   */
  const handleColorSelect = (color) => {
    setLabelColor(color)
    setShowDropdown(false)
  }
  const filteredData = labelList?.filter((item) =>
    item?.name?.toLowerCase().includes(globalFilter?.toLowerCase())
  )

  /**
   * Saves a label with the given type.
   *
   * @param {string} type - The type of label to save. Can be 'createLabel' or 'updateLabel'.
   * @return {Promise<void>} - A promise that resolves when the label is saved successfully, or rejects with an error.
   */
  const saveLable = async (type) => {
    try {
      setIsButtonFlag(true)
      showSpinner()
      const hexColor = labelColor
      const labelColorWithoutHash = hexColor.slice(1)
      const payload = {
        name: labelName,
        color: labelColorWithoutHash,
        description: labelDescription,
      }
      if (type === 'updateLabel') {
        payload.newName = labelName
        payload.name = oldLabel
      }
      const apiUrl = apiConfig.CREATE_LABEL.replace(':type', type)
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response.data.code === 200) {
        setLabelColor('#d73a4a')
        setLabelName('')
        labelListChange()
        setEditingLabelId(null)
        setNewLabel(false)
        addToast({
          title: response.data.msg,
          type: 'success',
        })
      } else {
        addToast({
          title: response.data.error,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
      setIsButtonFlag(false)
    }
  }

  /**
   * A function to handle the deletion of a label.
   *
   * @param {Object} label - The label object to delete.
   * @return {Promise<void>} A promise that resolves after the label is deleted, or rejects with an error.
   */
  const handleDeleteLabel = async (label) => {
    try {
      showSpinner()
      const payload = {
        name: label.name,
      }
      const apiUrl = apiConfig.DELETE_LABEL
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response.data.code === 200) {
        labelListChange()
        onClose()
        addToast({
          title: response.data.msg,
          type: 'success',
        })
      } else {
        addToast({
          title: response.data.error,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  /**
   * Handles the save changes button click for a label. Prompts the user with a confirmation dialog
   * to permanently delete the label. If the user confirms, calls the handleDeleteLabel function.
   *
   * @param {Object} label - The label object to delete.
   * @return {Promise<void>} A promise that resolves after the label is deleted, or rejects with an error.
   */
  const handleButtonSaveChanges = async (label) => {
    const confirmed = await new Promise((resolve) => {
      openDialog(
        'Are you sure?',
        <div className="text-lg">
          You want to permanently Delete?
          <div className="mt-6 flex justify-end">
            <button
              className="w-[23%] cursor-pointer rounded-xl bg-blueSecondary px-4 py-2 text-sm font-medium capitalize text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2 "
              type="button"
              onClick={() => resolve(true)}
            >
              Delete
            </button>
          </div>
        </div>
      )
    })
    if (confirmed) {
      handleDeleteLabel(label)
    }
  }

  /**
   * Updates the state variables to edit a label.
   *
   * @param {Object} label - The label object to edit.
   * @return {void} This function does not return anything.
   */
  const handleEditLabel = (label) => {
    setEditingLabelId(label.id)
    setLabelName(label.name)
    setLabelDescription(label.description)
    setLabelColor(`#${label.color}`)
    setNewLabel(false)
    setOldLabel(label.name)
    setShowDropdown(false)
  }

  /**
   * Generates a random color from the `colors` array and sets it as the label color.
   *
   * @return {void} This function does not return a value.
   */
  const colorChange = () => {
    const randomColor = colors[Math.floor(Math.random() * colors.length)]
    setLabelColor(randomColor)
  }

  /**
   * Updates the state variables to create a new label.
   *
   * @return {void} This function does not return anything.
   */
  const handleNewLabel = () => {
    setNewLabel(true)
    setEditingLabelId(null)
    setLabelName('')
    setLabelDescription('')
    setShowDropdown(false)
    setLabelColor('#d73a4a')
  }

  /**
   * Updates the state variables to cancel label editing.
   *
   * @return {void} This function does not return anything.
   */
  const handleCancelEdit = () => {
    setEditingLabelId(null)
    setLabelName('')
    setLabelDescription('')
    setLabelColor('#d73a4a')
  }

  return (
    <>
      <Card extra={'w-full h-full bg-white mt-3'}>
        <div className="h-full w-full p-2 md:px-4">
          <div className="flex h-full w-full flex-col items-center justify-between !pt-0  md:flex-row">
            {/* Search input */}
            <div className="mb-3 flex w-full items-center rounded-full bg-white p-[5px] shadow-2xl shadow-white dark:!bg-navy-800 dark:shadow-none md:mb-0 md:w-9/12">
              <div className="flex h-9 w-full flex-grow items-center rounded-full border-white bg-lightPrimary text-sm text-gray-600 dark:border dark:!bg-navy-900">
                <FiSearch className="mx-2 h-6 w-4 !text-gray-700 dark:!text-white" />
                <input
                  type="text"
                  placeholder="Search By Label Name"
                  className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white  sm:w-full"
                  value={globalFilter}
                  onChange={(e) => setGlobalFilter(e.target.value)}
                />
              </div>
            </div>
            <button
              onClick={() => handleNewLabel()}
              className={`'flex md:text-base' w-40 items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
            >
              <span>{LABEL.NEW_LABEL}</span>
            </button>
          </div>
        </div>

        <div className="h-full w-full p-2 md:px-4">
          {/* labels list Table */}
          <div className=" min-h-[150px] w-full overflow-x-scroll xl:overflow-hidden">
            {newLabel && (
              <div>
                <div>
                  <div className="mb-2 mt-2 pl-8">
                    <div
                      className="inline-block rounded-full p-1 px-2 text-white"
                      style={{ backgroundColor: labelColor }}
                    >
                      {labelName.length > 0 ? labelName : 'Label preview'}
                    </div>
                  </div>
                </div>
                <div className="align-center flex justify-center gap-[2.05rem] p-2">
                  <div>
                    <div className="mb-2">
                      {LABEL.LABEL_NAME}
                      <span className="text-red-500">*</span>
                    </div>
                    <div className="flex w-[200px] items-center rounded-2xl border  outline-none dark:!border-white/10 dark:!bg-navy-800">
                      <input
                        extra="mb-4 w-[200px]"
                        value={labelName}
                        onChange={(e) => setLabelName(e.target.value)}
                        className="placeholder:text-lightFourth md:text-md h-full w-[200px] rounded-2xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="mb-2">
                      {LABEL.DESCRIPTION}
                      <span className="text-red-500">*</span>
                    </div>
                    <div className="flex w-[200px] items-center rounded-2xl border outline-none dark:!border-white/10 dark:!bg-navy-800">
                      <input
                        extra="mb-4 w-[200px]"
                        value={labelDescription}
                        onChange={(e) => setLabelDescription(e.target.value)}
                        className="placeholder:text-lightFourth md:text-md h-full w-[200px] rounded-2xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                      />
                    </div>
                  </div>
                  <div className="pt-[8px]">
                    <span className="ml-[56px]">{LABEL.COLOR}</span>
                    <div className="flex ">
                      <button
                        onClick={colorChange}
                        className="h-fit rounded-md border p-3  text-white"
                        style={{ backgroundColor: labelColor }}
                      >
                        <GrUpdate />
                      </button>
                      <div className="relative ml-2 flex">
                        <div className="flex w-[200px] items-center rounded-2xl border outline-none dark:!border-white/10 dark:!bg-navy-800">
                          <input
                            extra="mb-4 w-[200px]"
                            readOnly
                            value={labelColor}
                            onClick={() => setShowDropdown(!showDropdown)}
                            className="placeholder:text-lightFourth md:text-md h-full w-[200px] rounded-2xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                          />
                        </div>
                        {showDropdown && (
                          <div
                            className={`absolute  ${
                              labelList.length < 2
                                ? 'bottom-[100%] top-auto'
                                : ''
                            } mt-[45px] w-[252px] border bg-white p-1 shadow-md dark:!bg-navy-800`}
                          >
                            <span className="mb-1 block">
                              {LABEL.DEFAULT_COLOR_TXT}
                            </span>
                            <div className="flex flex-wrap justify-center gap-1.5">
                              {colors.map((color) => (
                                <div
                                  key={color}
                                  className="h-[25px] w-[25px] cursor-pointer rounded-md p-2"
                                  style={{ backgroundColor: color }}
                                  onClick={() => handleColorSelect(color)}
                                ></div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={() => saveLable('createLabel')}
                      disabled={
                        !labelName?.length ||
                        !labelDescription.length ||
                        isButtonFlag
                      }
                      className={`
            mt-8 rounded-full bg-brand-500 px-4 py-2 text-white dark:bg-brand-400 
            ${
              !labelName?.length || !labelDescription.length || isButtonFlag
                ? 'cursor-not-allowed opacity-50'
                : ''
            }
            `}
                    >
                      {BUTTON_TEXT.SAVE_CHNG}
                    </button>
                    <button
                      onClick={() => {
                        setNewLabel(false)
                      }}
                      className="ml-2 mt-8 rounded-full bg-gray-300 px-4 py-2 text-black"
                    >
                      {BUTTON_TEXT.CANCEL}
                    </button>
                  </div>
                </div>
              </div>
            )}

            <table className="w-full">
              <tbody>
                {filteredData?.map((label, index) => (
                  <tr
                    key={index}
                    className="border-b border-gray-200 dark:!border-white/10"
                  >
                    <td className="relative mt-1 flex justify-start p-2 pl-[8%]">
                      {editingLabelId === label.id ? (
                        <div className="w-[200px]">
                          <div>
                            <div
                              className="inline-block rounded-full p-1 px-2 text-white"
                              style={{ backgroundColor: labelColor }}
                            >
                              {labelName.length > 0
                                ? labelName
                                : 'Label preview'}
                            </div>
                          </div>
                          <div className="pt-4">
                            <div className="mb-2">
                              {LABEL.LABEL_NAME}
                              <span className="text-red-500">*</span>
                            </div>
                            <div className="flex w-[200px] items-center rounded-2xl border outline-none dark:!border-white/10 dark:!bg-navy-800">
                              <input
                                extra="mb-4 w-[200px]"
                                value={labelName}
                                onChange={(e) => setLabelName(e.target.value)}
                                className="placeholder:text-lightFourth md:text-md h-full w-[200px] rounded-2xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{ backgroundColor: `#${label.color}` }}
                          className="rounded-full px-1 px-4 py-0 text-center font-medium"
                        >
                          {label.name}
                        </div>
                      )}
                    </td>
                    <td className="">
                      {editingLabelId === label.id ? (
                        <div className="ml-8 w-[200px] pt-12">
                          <div className="mb-2">
                            {LABEL.DESCRIPTION}
                            <span className="text-red-500">*</span>
                          </div>
                          <div className="flex w-[200px] items-center rounded-2xl border outline-none dark:!border-white/10 dark:!bg-navy-800">
                            <input
                              extra="mb-4 w-[200px]"
                              value={labelDescription}
                              onChange={(e) =>
                                setLabelDescription(e.target.value)
                              }
                              className="placeholder:text-lightFourth md:text-md h-full w-[200px] rounded-2xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                            />
                          </div>
                        </div>
                      ) : (
                        <span className="pr-[118px]">{label.description}</span>
                      )}
                    </td>
                    <td className="relative pt-12 ">
                      {editingLabelId === label.id && (
                        <div className="flex w-[230px]">
                          <button
                            onClick={colorChange}
                            className="mt-8 h-fit rounded-md border p-3 text-white"
                            style={{ backgroundColor: labelColor }}
                          >
                            <GrUpdate />
                          </button>
                          <div className="relative ml-2">
                            <div className="mb-2">{LABEL.COLOR}</div>
                            <div className="relative flex w-[180px] items-center rounded-2xl border outline-none dark:!border-white/10 dark:!bg-navy-800">
                              <input
                                extra="mb-4 w-[180px]"
                                value={labelColor}
                                readOnly
                                onClick={() => setShowDropdown(!showDropdown)}
                                onChange={(e) => setLabelColor(e.target.value)}
                                className="placeholder:text-lightFourth md:text-md h-full w-[180px] rounded-2xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                              />
                              {showDropdown && (
                                <div
                                  className={`absolute ${
                                    index === filteredData.length - 1 ||
                                    index === filteredData.length - 2
                                      ? 'bottom-[100%] top-auto'
                                      : 'top-[100%]'
                                  } left-0 z-20 mt-1 w-[252px] border bg-white p-1 shadow-md dark:!bg-navy-800 dark:text-white`}
                                >
                                  <span>{LABEL.DEFAULT_COLOR_TXT}</span>
                                  <div className="flex flex-wrap justify-center gap-1.5">
                                    {colors.map((color) => (
                                      <div
                                        key={color}
                                        className="h-[25px] w-[25px] cursor-pointer rounded-md p-2"
                                        style={{ backgroundColor: color }}
                                        onClick={() => handleColorSelect(color)}
                                      />
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </td>
                    <td className="w-[200px]">
                      <div className="flex justify-center">
                        {editingLabelId === label.id ? (
                          <div className="mt-10 flex justify-center">
                            <button
                              onClick={() => saveLable('updateLabel')}
                              disabled={
                                !labelName?.length ||
                                !labelDescription.length ||
                                isButtonFlag
                              }
                              className={`
                         mt-8 rounded-full bg-brand-500 px-4 text-white dark:bg-brand-400
                        ${
                          !labelName?.length ||
                          !labelDescription.length ||
                          isButtonFlag
                            ? 'cursor-not-allowed opacity-50'
                            : ''
                        }
                    `}
                            >
                              {BUTTON_TEXT.UPDATE}
                            </button>
                            <button
                              onClick={handleCancelEdit}
                              className="ml-2 mt-8 rounded-full bg-gray-300 px-4 py-2 text-black"
                            >
                              {BUTTON_TEXT.CANCEL}
                            </button>
                          </div>
                        ) : (
                          <>
                            <button
                              type="button"
                              onClick={() => handleButtonSaveChanges(label)}
                            >
                              <TooltipHorizon
                                extra="border border-gray-200 dark:border-gray-700"
                                trigger={
                                  <p className="flex cursor-pointer items-center rounded-full border p-1 font-bold text-[#000] hover:font-medium hover:text-black dark:text-white">
                                    <span>
                                      <IoTrashOutline className="text-xl" />
                                    </span>
                                  </p>
                                }
                                content="Delete"
                                placement="top"
                              />
                            </button>
                            <button
                              type="button"
                              onClick={() => handleEditLabel(label)}
                            >
                              <TooltipHorizon
                                extra="border border-gray-200 dark:border-gray-700"
                                trigger={
                                  <p className="ml-4 flex cursor-pointer items-center rounded-full border p-1 font-bold text-[#000] hover:font-medium hover:text-black dark:text-white">
                                    <span>
                                      <MdEdit className="text-xl" />
                                    </span>
                                  </p>
                                }
                                content="Edit"
                                placement="top"
                              />
                            </button>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {filteredData?.length === 0 && (
              <div className="text-md mt-3 flex w-full justify-center text-center text-gray-500 dark:text-gray-400">
                {NO_DATA}
              </div>
            )}
          </div>
        </div>
      </Card>
    </>
  )
}
export default LabelCard
