import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import Moment from 'moment'
import Card from 'components/card'
import { IoIosCloudDone } from 'react-icons/io'
import { AiFillCheckCircle } from 'react-icons/ai'
import { GiPoisonCloud } from 'react-icons/gi'
import React, { useState, useEffect } from 'react'
import { useToaster } from 'common/Toaster'
import appConstants from 'common/config/appConstants'
import { MdCached, MdCloudUpload } from 'react-icons/md'
import { GiSightDisabled } from 'react-icons/gi'
import { FiMinusCircle } from 'react-icons/fi'
import { FaCircleExclamation } from 'react-icons/fa6'
import { GoSkipFill } from 'react-icons/go'
import { FaRegTimesCircle } from 'react-icons/fa'
import { MdOutlineTimerOff } from 'react-icons/md'
import { GoGitCommit } from 'react-icons/go'
import { FaRegPlayCircle } from 'react-icons/fa'
import { RxCrossCircled } from 'react-icons/rx'
import { RxCross1 } from 'react-icons/rx'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import { IoIosInformationCircleOutline } from 'react-icons/io'
import { useParams } from 'react-router-dom'
import PaginationSkeleton from 'components/skeletons/PaginationSkeleton'

const apiService = ApiCaller()

/**
 * Renders Webservice Event Logs component.
 *
 * @param {Object} toggleState - The current state of toggling
 * @param {Function} setToggleState - A function to set the toggle state
 * @param {Array} workflowRuns - Array of workflow runs data
 * @return {JSX.Element} The Webservice Event Logs component
 */
const WebserviceEventLogs = ({
  toggleState,
  setToggleState,
  workflowRuns,
  workflowData,
  totalPageCount,
  currentPage,
  itemsPerPage,
  onPageChange,
  setWorkflowJobsDetailsPageData,
  orgRepo,
  orgName,
}) => {
  const { addToast } = useToaster()
  const [pageIndex, setPageIndex] = useState(currentPage)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [workFlowJobsData, setWorkflowJobsData] = useState()
  const [completedStatus, setCompletedStatus] = useState()
  const [WorkflowJobsDetailsData, setWorkflowJobsDetailsData] = useState()
  const [jobCompleted, setJobCompleted] = useState(false)
  const { application_id } = useParams()
  const {
    TOAST,
    BUTTON_TEXT,
    LABEL,
    WEB_SERVICES,
    WORK_FLOW_STATUS,
    WORK_FLOW_PROCESS_STATUS,
    GITHUB_URL,
  } = appConstants
  const [isAPILoading, setAPILoading] = useState(false)

  /**
   * A function that changes the page and triggers page change.
   *
   * @param {type} page - description of parameter
   * @return {type} description of return value
   */
  const changePage = (page) => {
    setPageIndex(page)
    onPageChange(page + 1)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const listingData =
    workflowRuns && Array.isArray(workflowRuns)
      ? workflowRuns.map((run, index) => ({
          icon:
            run.status === 'completed' && run.conclusion === 'success' ? (
              <IoIosCloudDone className="text-2xl text-green-600" />
            ) : run.status === 'queued || null' ||
              run.status === 'in_progress' ? (
              <MdCloudUpload className="text-2xl text-gray-350" />
            ) : run.status === 'completed' && run.conclusion === 'failed' ? (
              <GiPoisonCloud className="text-2xl text-red-600" />
            ) : run.status === 'completed' && run.conclusion === 'failure' ? (
              <GiPoisonCloud className="text-2xl text-red-600" />
            ) : (
              <GiPoisonCloud className="text-2xl text-red-600" />
            ),
          title: `${run.conclusion} `,
          name: `${run.display_title} `,
          indexCounter: `#${run.run_number}: `,
          commit: `${run.head_sha.substring(0, 7)}`,
          loginName: `${run.triggering_actor.login} `,
          branch: `${run.head_branch}`,
          pathText: `${run.path} `,
          time: run.updated_at,
          key: index,
          payload: {
            username: JSON.parse(localStorage.getItem('userData'))?.f_name,
            repo: orgRepo,
            org: orgName,
            runner_id: run.id,
          },
        }))
      : []

  useEffect(() => {
    const selectedPayload = listingData[selectedIndex]?.payload
    if (selectedPayload) {
      getDeploymentsLogs(selectedPayload)
    }
  }, [workflowRuns])

  const listingDataSkeletons = Array.from({ length: 3 })
  const deploymentLogsTopCardSkeletons = Array.from({ length: 5 })
  const deploymentLogsBottomCardSkeletons = Array.from({ length: 5 })

  useEffect(() => {
    let intervalId
    if (
      (toggleState === WEB_SERVICES.EVENTS ||
        toggleState === WEB_SERVICES.DEPLOYMENT_LOGS) &&
      !jobCompleted
    ) {
      intervalId = setInterval(() => {
        const selectedPayload = listingData[selectedIndex]?.payload
        if (selectedPayload) {
          getDeploymentsLogs(selectedPayload, intervalId)
        }
      }, 5000)
    } else {
      clearInterval(intervalId)
    }
    return () => clearInterval(intervalId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleState, listingData, jobCompleted])
  /**
   * A function that handles the click on a div element.
   *
   * @param {number} index - The index of the element clicked.
   * @return {void}
   */
  const handleDivClick = (index) => {
    setAPILoading(false)
    setToggleState(WEB_SERVICES.DEPLOYMENT_LOGS)
    setSelectedIndex(index)
    const selectedPayload = listingData[index].payload
    setWorkflowJobsData([])
    getDeploymentsLogs(selectedPayload)
  }

  /**
   * Navigates to the GitHub commit page for the specified index.
   *
   * @param {number} index - The index of the commit in the workflowRuns array.
   * @return {void} This function does not return a value.
   */
  const goToGitCommit = (index) => {
    const id = workflowRuns[index]?.head_commit?.id
    const url = `${GITHUB_URL}${orgName}/${orgRepo}/commit/${id}`
    window.open(url, '_blank')
  }
  const gitloginName = (loginName) => {
    const url = `${GITHUB_URL}${loginName}`
    window.open(url, '_blank')
  }

  /**
   * A function to get deployment logs.
   *
   * @param {Object} payload - The payload for the API call
   * @return {void}
   */
  const getDeploymentsLogs = async (payload, intervalId) => {
    const apiUrl = apiConfig.GET_WORK_FLOWS_JOBS.replace(
      ':application_id',
      application_id
    ).replace(':workflow_runs_id', payload.runner_id)
    try {
      const response = await apiService.apiCall('get', apiUrl)
      setAPILoading(true)
      if (response?.data?.code === 200) {
        setWorkflowJobsData(response?.data?.data?.allJobs?.result)
        setWorkflowJobsDetailsData(response?.data?.data?.runsInfo)
        setWorkflowJobsDetailsPageData(response?.data?.data?.runsInfo)
        setCompletedStatus(response?.data?.data?.allJobs?.result[0]?.status)
        const isJobCompleted =
          response?.data?.data?.allJobs?.result[0]?.status === 'completed'
        setJobCompleted(isJobCompleted)
        if (intervalId) clearInterval(intervalId)
      } else {
        addToast({
          title: TOAST.MESSAGES.ERROR.getDeploymentLogsError,
          type: 'error',
        })
      }
    } catch (error) {
      console.log('Error:', error)
    }
  }
  /**
   * Generate step icon based on the step status.
   *
   * @param {object} step - The step object containing status and conclusion.
   * @return {JSX.Element} The corresponding icon component for the step status.
   */
  const getStepIcon = (step) => {
    const iconMap = {
      'completed-success': (
        <AiFillCheckCircle className="mr-2 items-center text-8xl text-green-500 md:text-2xl lg:text-xl" />
      ),
      in_progress: (
        <span className="logs-loader mr-2 items-center text-8xl md:text-2xl lg:text-xl"></span>
      ),
      'completed-neutral': (
        <FiMinusCircle className="mr-2 items-center text-8xl text-red-500 md:text-2xl lg:text-xl " />
      ),
      'completed-skipped': (
        <GoSkipFill className="mr-2 items-center text-8xl text-red-500 md:text-2xl lg:text-xl " />
      ),
      'completed-timed_out': (
        <MdOutlineTimerOff className="mr-2 items-center text-8xl text-red-500 md:text-2xl lg:text-xl " />
      ),
      'completed-action_required': (
        <FaRegPlayCircle className="mr-2 items-center text-8xl text-red-500 md:text-2xl lg:text-xl " />
      ),
      'completed-cancelled': (
        <FaRegTimesCircle className="mr-2 items-center text-8xl text-red-500 md:text-2xl lg:text-xl " />
      ),
      'completed-failure': (
        <FaCircleExclamation className="mr-2 items-center text-8xl  text-blue-500 md:text-2xl lg:text-xl " />
      ),
      'completed-null': (
        <GiSightDisabled className="mr-2 items-center text-8xl  text-blue-500 md:text-2xl lg:text-xl " />
      ),
      default: (
        <IoIosInformationCircleOutline className="mr-2 items-center text-8xl text-fixaText md:text-2xl lg:text-xl" />
      ),
    }

    const key =
      step.status === 'completed' ? `completed-${step.conclusion}` : step.status

    return iconMap[key] || iconMap['default']
  }

  /**
   * Reruns the jobs by making a POST request to the API.
   *
   * @return {Promise<void>} A Promise that resolves when the request is complete.
   */
  const reRunJobs = async () => {
    const apiUrl = apiConfig.RERUN_JOBS.replace(
      ':applicationId',
      application_id
    ).replace(':jobId', workFlowJobsData[0].id)
    try {
      const response = await apiService.apiCall('post', apiUrl)
      if (response?.data?.code === 200) {
        let intervalId
        if (
          toggleState === WEB_SERVICES.EVENTS ||
          toggleState === WEB_SERVICES.DEPLOYMENT_LOGS
        ) {
          intervalId = setInterval(() => {
            const selectedPayload = listingData[selectedIndex]?.payload
            if (selectedPayload) {
              getDeploymentsLogs(selectedPayload, intervalId)
            }
          }, 5000)
        } else {
          clearInterval(intervalId)
        }
        addToast({
          title: TOAST.MESSAGES.SUCCESS.rerunJobs,
          type: 'success',
        })
      } else {
        addToast({
          title: TOAST.MESSAGES.ERROR.getDeploymentLogsError,
          type: 'error',
        })
      }
    } catch (error) {
      console.log('Error:', error)
    }
  }

  /**
   * Cancel jobs by sending a POST request to the API.
   *
   * @return {Promise<void>} A Promise that resolves when the request is complete.
   */
  const cancelJobs = async () => {
    const apiUrl = apiConfig.CANCEL_JOBS.replace(
      ':applicationId',
      application_id
    ).replace(':runId', listingData[selectedIndex]?.payload.runner_id)
    try {
      const response = await apiService.apiCall('post', apiUrl)
      if (response?.data?.code === 200) {
        addToast({
          title: TOAST.MESSAGES.SUCCESS.cancelJobs,
          type: 'success',
        })
        const selectedPayload = listingData[selectedIndex]?.payload
        if (selectedPayload) {
          getDeploymentsLogs(selectedPayload)
        }
      } else {
        addToast({
          title: TOAST.MESSAGES.ERROR.getDeploymentLogsError,
          type: 'error',
        })
      }
    } catch (error) {
      console.log('Error:', error)
    }
  }

  return (
    <>
      {/* events web services */}
      {toggleState === WEB_SERVICES.EVENTS && (
        <>
          <Card extra="w-full !pb-2">
            <div className="group">
              {!isAPILoading
                ? listingDataSkeletons.map((_item, index) => (
                    <div
                      key={index}
                      className={
                        'my-1 flex animate-pulse items-center justify-between border-b !px-4 !py-4 md:!px-5'
                      }
                    >
                      <div className="rounded-full bg-gray-200 p-3 dark:bg-gray-800"></div>
                      <div className="ml-4 flex w-full flex-col gap-y-2">
                        <div className="h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
                        <div className="flex gap-x-16">
                          <div className="mr-auto h-2 basis-1/2 rounded bg-gray-200 dark:bg-gray-800"></div>
                          <div className="h-2 basis-1/12 rounded bg-gray-200 dark:bg-gray-800"></div>
                          <div className="h-2 basis-1/12 rounded bg-gray-200 dark:bg-gray-800"></div>
                        </div>
                        <div className="h-2 w-1/6 rounded bg-gray-200 dark:bg-gray-800"></div>
                      </div>
                    </div>
                  ))
                : listingData?.length &&
                  listingData.map((item, index) => (
                    <div
                      key={index}
                      className={
                        'my-1 items-center justify-between border-b !px-4 !py-4 md:!px-5 lg:flex'
                      }
                    >
                      <div className="flex w-2/3 items-center">
                        <div title={item.title}>{item.icon && item.icon}</div>
                        <div className="ml-4">
                          <div className="flex flex-col">
                            <div
                              className="cursor-pointer"
                              onClick={() => handleDivClick(index)}
                            >
                              <span className="text-fixaText dark:text-white">
                                {item.name}
                              </span>
                            </div>
                            <span>
                              <span className="mr-0.5 text-gray-250">
                                {item.pathText}
                              </span>
                              <span className="mr-0.5 text-gray-150">
                                {item.indexCounter}
                              </span>
                              <span className="mr-0.5 text-gray-150">
                                Commit
                              </span>
                              <span
                                className={
                                  'mr-1 cursor-pointer text-gray-150 underline'
                                }
                                onClick={() => goToGitCommit(index)}
                              >
                                {' '}
                                {item.commit}
                              </span>
                              <span className="mr-0.5 text-gray-150">
                                Push By:
                              </span>
                              <span
                                className="cursor-pointer text-gray-150"
                                onClick={() => gitloginName(item.loginName)}
                              >
                                {item.loginName}
                              </span>
                            </span>
                          </div>
                          <div className="text-xs text-disableGray">
                            {Moment(item.time).format(
                              'MMMM Do YYYY, h:mm:ss a'
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-center rounded-lg border border-blueSecondary/60 bg-blueSecondary/5 px-3 py-1 ">
                        <div className="text-xs">
                          <span className="text-blueSecondary/80 dark:text-white">
                            {item.branch}
                          </span>
                        </div>
                      </div>
                      <div>
                        <div className="pb-2 text-right text-disableGray">
                          {Moment(item.time).startOf('HH').fromNow()}
                        </div>
                      </div>
                    </div>
                  ))}
            </div>

            {/* Pagination */}
            {!isAPILoading ? (
              <PaginationSkeleton />
            ) : (
              listingData.length > 0 && (
                <div className="mt-2 flex h-12 w-full items-center justify-between px-6">
                  <div className="text-sm text-gray-700">
                    {/* Display current range of entries */}
                    Showing {pageIndex * itemsPerPage + 1} to{' '}
                    {totalPageCount <
                    pageIndex * itemsPerPage + listingData.length
                      ? totalPageCount
                      : pageIndex * itemsPerPage + listingData.length}{' '}
                    of {totalPageCount} entries
                  </div>

                  <div className="flex items-center">
                    {/* Render pagination buttons */}
                    <div className="ml-3 flex">
                      {/* First button */}
                      <button
                        className={`h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                          pageIndex === 0 ? 'cursor-not-allowed opacity-50' : ''
                        }`}
                        onClick={() => changePage(0)}
                        disabled={pageIndex === 0}
                      >
                        {'<<'}
                      </button>

                      {/* Previous button */}
                      <button
                        className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                          pageIndex === 0 ? 'cursor-not-allowed opacity-50' : ''
                        }`}
                        onClick={() => changePage(pageIndex - 1)}
                        disabled={pageIndex === 0}
                      >
                        {'<'}
                      </button>

                      {/* Pagination buttons */}
                      {Array.from(
                        { length: Math.min(5, Math.ceil(totalPageCount / 10)) }, // Limit to a maximum of 5 pages
                        (_, index) => {
                          const page =
                            Math.max(
                              0,
                              Math.min(
                                Math.ceil(totalPageCount / 10) - 5,
                                pageIndex
                              )
                            ) + index
                          return (
                            <button
                              key={page}
                              className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                                pageIndex === page
                                  ? 'cursor-not-allowed opacity-50'
                                  : ''
                              }`}
                              onClick={() => changePage(page)}
                              disabled={pageIndex === page}
                            >
                              {page + 1}
                            </button>
                          )
                        }
                      )}

                      {/* Next button */}
                      <button
                        className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                          pageIndex >= Math.ceil(totalPageCount / 10) - 1
                            ? 'cursor-not-allowed opacity-50'
                            : ''
                        }`}
                        onClick={() => changePage(pageIndex + 1)}
                        disabled={
                          pageIndex >= Math.ceil(totalPageCount / 10) - 1
                        }
                      >
                        {'>'}
                      </button>

                      {/* Last button */}
                      <button
                        className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                          pageIndex >= Math.ceil(totalPageCount / 10) - 1
                            ? 'cursor-not-allowed opacity-50'
                            : ''
                        }`}
                        onClick={() =>
                          changePage(Math.ceil(totalPageCount / 10) - 1)
                        }
                        disabled={
                          pageIndex >= Math.ceil(totalPageCount / 10) - 1
                        }
                      >
                        {'>>'}
                      </button>
                    </div>
                  </div>
                </div>
              )
            )}
          </Card>
        </>
      )}
      {/* deployment logs */}
      {toggleState === WEB_SERVICES.DEPLOYMENT_LOGS && (
        <>
          {isAPILoading ? (
            <div className="flex items-center">
              {completedStatus === 'in_progress' ? (
                <span className="logs-loader mr-2 items-center text-8xl md:text-2xl lg:text-xl"></span>
              ) : (
                <span>
                  {WorkflowJobsDetailsData?.status === 'success' ? (
                    <BsFillCheckCircleFill className="text-green-500 md:text-2xl lg:text-xl" />
                  ) : (
                    <RxCrossCircled className="text-red-500 md:text-2xl lg:text-xl" />
                  )}
                </span>
              )}
              <span className="black-80 ml-2 text-lg font-bold dark:text-white">{`${WorkflowJobsDetailsData?.message}`}</span>
              <span className="ml-2 mr-auto text-lg font-bold text-gray-80">{`#${WorkflowJobsDetailsData?.runNumber}`}</span>
              {WORK_FLOW_STATUS.includes(WorkflowJobsDetailsData?.status) && (
                <button
                  className="flex cursor-pointer items-center rounded-xl bg-blueSecondary px-4 py-2 text-sm font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
                  type="button"
                  onClick={reRunJobs}
                >
                  <MdCached className="mr-2" />
                  {BUTTON_TEXT.RERUN_JOB}
                </button>
              )}
              {WORK_FLOW_PROCESS_STATUS.includes(
                WorkflowJobsDetailsData?.status
              ) && (
                <button
                  className="flex cursor-pointer items-center rounded-xl bg-blueSecondary px-4 py-2 text-sm font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:ml-2"
                  type="button"
                  onClick={cancelJobs}
                >
                  <MdCached className="mr-2" />
                  {BUTTON_TEXT.CANCEL_JOB}
                </button>
              )}
            </div>
          ) : (
            <div className="flex animate-pulse items-center ">
              <div className="mr-2 rounded-full bg-gray-200 p-3 dark:bg-gray-800"></div>
              <div className="mr-auto h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
              <div className="h-8 w-32 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
            </div>
          )}

          <Card extra={'w-full !py-4 !pb-2 !px-4 md:!px-4'}>
            {isAPILoading ? (
              <div className="gap-30 grid grid-cols-5">
                <>
                  <div className="col-span-1 flex flex-col ">
                    <span className="gray-80 w-80 text-xs font-medium">{`${WorkflowJobsDetailsData?.runTime}`}</span>
                    <span className="darkBlue flex items-center text-sm font-bold">
                      <a
                        href={`${GITHUB_URL}${WorkflowJobsDetailsData?.author}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {`${WorkflowJobsDetailsData?.author || '-'} `}
                      </a>
                      <span className="ml-1">Pushed</span>
                      <span className="ml-2 flex items-center ">
                        <a
                          href={` ${GITHUB_URL}${orgName}/${orgRepo}/commit/${WorkflowJobsDetailsData?.headSha}
                        `}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center space-x-1"
                        >
                          <GoGitCommit />
                          <span className="text-gray-10">
                            {WorkflowJobsDetailsData?.headSha?.substring(
                              0,
                              7
                            ) || '-'}
                          </span>
                        </a>
                        <a
                          href={`${GITHUB_URL}${orgName}/${orgRepo}/tree/${WorkflowJobsDetailsData?.headBranch}
                        `}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center space-x-1"
                        >
                          <span className="ml-2.5 rounded-xl border border-blueSecondary/60 bg-blueSecondary/5 px-2 py-1 text-xs font-[500] text-blueSecondary/80 hover:bg-blueSecondary/90 hover:font-[500] hover:text-white">
                            {WorkflowJobsDetailsData?.headBranch || '-'}{' '}
                          </span>
                        </a>
                      </span>
                    </span>
                  </div>
                  <div className="col-span-1 ml-[70%] flex flex-col">
                    <span className="gray-80 text-xs font-medium">
                      {LABEL.STATUS}
                    </span>
                    <span className="darkBlue py-1.5 text-sm font-bold capitalize">
                      {WorkflowJobsDetailsData?.status !== null
                        ? WorkflowJobsDetailsData?.status
                        : '-'}
                    </span>
                  </div>
                  <div className="col-span-1 ml-[30%] flex flex-col">
                    <span className="gray-80 text-xs font-medium">
                      {LABEL.TOTAL_DUR}
                    </span>
                    <span className="darkBlue py-1.5 text-sm font-bold">
                      {WorkflowJobsDetailsData?.totalDuration !== 'NaNs' &&
                      WorkflowJobsDetailsData?.totalDuration
                        ? WorkflowJobsDetailsData?.totalDuration
                        : '-'}
                    </span>
                  </div>
                  {WorkflowJobsDetailsData?.billiableTime && (
                    <div className="col-span-1 ml-[30%] flex  flex-col">
                      <span className="gray-80 text-xs font-medium">
                        {LABEL.BILLABLE_TIME}
                      </span>
                      <span className="darkBlue py-1.5 text-sm font-bold">{`${WorkflowJobsDetailsData?.billiableTime}`}</span>
                    </div>
                  )}
                  <div className="col-span-1 flex flex-col">
                    <span className="gray-80 text-xs font-medium">
                      {LABEL.ARTIFACTS}
                    </span>
                    <span className="darkBlue py-1.5 text-sm font-bold">-</span>
                  </div>
                </>
              </div>
            ) : (
              <div className="grid animate-pulse grid-cols-5 gap-y-4 pb-2">
                {deploymentLogsTopCardSkeletons.map((_item, index) => (
                  <>
                    <div
                      key={index}
                      className="h-2 w-2/3 rounded bg-gray-200 dark:bg-gray-800"
                    ></div>
                    <div
                      key={index}
                      className="h-2 w-2/3 rounded bg-gray-200 dark:bg-gray-800"
                    ></div>
                  </>
                ))}
              </div>
            )}
          </Card>
          {isAPILoading ? (
            <div className="mt-2 h-[300px] overflow-y-auto rounded-3xl bg-deploymentLogBg px-4 pt-2 text-white">
              {workFlowJobsData?.length > 0 &&
                workFlowJobsData.map((workFlowJobs, runIndex) => (
                  <ul key={runIndex}>
                    {workFlowJobs?.steps?.length ? (
                      workFlowJobs.steps.map((step, index) => (
                        <li className="my-3" key={index}>
                          <div className="flex items-center justify-start">
                            <div
                              className="w-36 gap-5 text-sm text-xs tracking-normal text-gray-30"
                              key={index}
                            >
                              {step.started_at &&
                              Moment(step.started_at).isValid()
                                ? Moment(step.started_at).format(
                                    'MMM D hh : mm : ss A'
                                  )
                                : Moment().format('MMM D hh : mm : ss A')}
                            </div>
                            {getStepIcon(step)}
                            <div className="ml-1 flex items-center text-xs">
                              <span className="mr-1 text-xs tracking-normal">
                                {step.name}
                              </span>
                              {step.status === 'completed' &&
                              step.name.toLowerCase().includes('complete job')
                                ? ' 🎉'
                                : ''}
                            </div>
                          </div>
                        </li>
                      ))
                    ) : (
                      <li className="flex flex-col items-center justify-center pt-[100px]">
                        <span className="mb-2">
                          <RxCross1 className="text-3xl text-red-500" />
                        </span>
                        <span className="text-2xl text-white">
                          This job failed
                        </span>
                      </li>
                    )}
                  </ul>
                ))}
              {isAPILoading && workFlowJobsData?.length === 0 && (
                <div className="flex h-full w-full flex-col items-center justify-center gap-y-2">
                  <div className="text-[1.75rem] font-bold">
                    This workflow graph cannot be shown
                  </div>
                  <div className="text-xl">
                    A graph will be generated the next time this workflow is
                    run.
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="mt-2 flex animate-pulse flex-col gap-y-2 overflow-y-auto rounded-3xl bg-deploymentLogBg p-4 text-white">
              {deploymentLogsBottomCardSkeletons.map((_item, index) => (
                <div key={index} className="flex items-center">
                  <div className="mr-8 h-2 w-28 rounded bg-gray-800"></div>
                  <div className="mr-4 rounded-full bg-gray-800 p-3"></div>
                  <div className="h-2 w-1/4 rounded bg-gray-800"></div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </>
  )
}

export default WebserviceEventLogs
