import React, { useState } from 'react'
import appConstants from 'common/config/appConstants'
import { FaRegCopy } from 'react-icons/fa'
import { IoMdEye } from 'react-icons/io'
import { BiHide } from 'react-icons/bi'
import TooltipHorizon from '../../../components/tooltip'
import Card from 'components/card'

const KubernetesTokenComponent = () => {
  const KuberneteToken = process.env.REACT_APP_KUBENETE_TOKEN
  const { SUBSCRIPTION, LABEL } = appConstants
  const [showCopiedText, setShowCopiedText] = useState(false)
  const [showFullKey, setShowFullKey] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  const getToken = () => {
    if (KuberneteToken) {
      navigator.clipboard.writeText(KuberneteToken)
      setShowCopiedText(true)
      setTimeout(() => {
        setShowCopiedText(false)
      }, 1000)
    }
  }

  const renderMaskedKey = (key) => {
    if (!key) return ''

    const keyLength = key.length

    if (keyLength <= 12) {
      return key
    }

    const firstSix = key.slice(0, 6)
    const lastSix = key.slice(-6)
    const maskedMiddle = '*'.repeat(keyLength - 12)

    return `${firstSix}${maskedMiddle}${lastSix}`
  }

  return (
    <>
      <Card extra="w-full p-10">
        <div className="col-span-2">
          <div className="flex items-center pb-1">
            <div className="flex items-center">
              <div className="mr-2 font-bold">{LABEL.KUBERNETES_TOKEN_LBL}</div>
            </div>
            <div className="flex items-center">
              {showCopiedText ? (
                <span className="text-gray mr-[10px]">{SUBSCRIPTION.COPY}</span>
              ) : (
                <TooltipHorizon
                  extra="border border-gray-200 dark:border-gray-700"
                  trigger={
                    <p
                      className="mr-[10px] flex cursor-pointer items-center rounded-full border p-1 font-bold text-[#000] hover:font-medium hover:text-black dark:text-white"
                      onClick={getToken}
                    >
                      <span>
                        <FaRegCopy className="text-xl " />
                      </span>
                    </p>
                  }
                  content="Copy"
                  placement="top"
                />
              )}
              {showFullKey ? (
                <TooltipHorizon
                  extra="border border-gray-200 dark:border-gray-700"
                  trigger={
                    <p
                      className="mr-[20px] flex cursor-pointer items-center rounded-full border p-1 font-bold text-[#000] hover:font-medium hover:text-black dark:text-white"
                      onClick={() => setShowFullKey(false)}
                    >
                      <span>
                        <BiHide className="text-xl" />
                      </span>
                    </p>
                  }
                  content="Hide"
                  placement="top"
                />
              ) : (
                <TooltipHorizon
                  extra="border border-gray-200 dark:border-gray-700"
                  trigger={
                    <p
                      className="mr-[20px] flex cursor-pointer items-center rounded-full border p-1 font-bold text-[#000] hover:font-medium hover:text-black dark:text-white"
                      onClick={() => setShowFullKey(true)}
                    >
                      <span>
                        <IoMdEye className="text-xl " />
                      </span>
                    </p>
                  }
                  content="View"
                  placement="top"
                />
              )}
            </div>
          </div>
          {showFullKey && (
            <div
              className="w-[98%] break-words break-all"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              {isHovered
                ? KuberneteToken
                : renderMaskedKey(KuberneteToken || '-')}
            </div>
          )}
        </div>
      </Card>
    </>
  )
}

export default KubernetesTokenComponent
