import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'
import SignInLayout from 'layouts/signIn'
import 'assets/css/Plugins.css'
import { useState, useEffect } from 'react'
import ScrollToTop from 'common/ScrollToTop'
import SignInGuard from 'common/guard/signInGuard'
import AfterLoginLayout from 'layouts/afterLogin'
import { useTheme } from './contexts/ThemeContext'
import RoutesComponent from './routes'
import { getOrganization, handleBillingApiCall } from 'common/commonFunction'
import { useSpinner } from 'common/SpinnerLoader'
import { useToaster } from 'common/Toaster'
import appConstants from 'common/config/appConstants'
import SubscriptionPage from 'layouts/subscription'
/**
 * Create a new context and set up the theme state. When the theme state changes, this effect will update the CSS variables in the document's root element.
 *
 * @return {JSX.Element} The JSX for the entire component
 */
const App = () => {
  const routes = RoutesComponent()
  const { themeApp } = useTheme()
  const [mini, setMini] = useState(false)
  const location = useLocation() // Get the current location
  const navigate = useNavigate()
  const { addToast } = useToaster()
  const { TOAST } = appConstants
  const { showSpinner, hideSpinner } = useSpinner()

  useEffect(() => {
    /**
     * Asynchronously fetches data and performs actions based on the response and error handling.
     *
     */
    const fetchData = async () => {
      try {
        showSpinner()
        if (
          location.pathname !== '/' &&
          location.pathname !== '/settings/kubernete-token' &&
          location.pathname !== '/ticket-create' &&
          location.pathname !== '/ticket-list' &&
          !location.pathname.startsWith('/ticket-details') &&
          location.pathname !== '/sign-in' &&
          location.pathname !== '/subscription-expired' &&
          location.pathname !== '/settings/organization' &&
          !location.pathname.includes('web-services')
        ) {
          const licenseAPIData = await handleBillingApiCall()
          if (licenseAPIData && licenseAPIData === 'Valid') {
            const isOrganizationSet = getOrganizationDetails()
            isOrganizationSet.then((res) => {
              if (res?.data?.code !== 200) {
                navigate(`/settings/organization`)
              }
            })
          } else if (licenseAPIData && licenseAPIData !== 'Valid') {
            localStorage.clear()
            navigate('/subscription-expired')
          }
        }
      } catch (error) {
        addToast({
          title: TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
      } finally {
        hideSpinner()
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, addToast])

  // When the theme state changes, this effect will update the CSS variables in the document's root element
  useEffect(() => {
    let color
    for (color in themeApp) {
      document.documentElement.style.setProperty(color, themeApp[color])
    }
    //eslint-disable-next-line
  }, [themeApp])

  /**
   * Retrieves organization details asynchronously.
   *
   * @return {Promise} A promise that resolves to the organization details.
   */
  const getOrganizationDetails = async () => {
    try {
      const response = await getOrganization()
      return response
    } catch (error) {
      console.log('error-', error)
    }
  }

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={
            <AfterLoginLayout setMini={setMini} mini={mini} theme={themeApp} />
          }
        >
          {routes &&
            routes.map((route, index) => {
              if (route.collapse) {
                return route.items.map((nestedRoute, nestedIndex) => {
                  return (
                    <Route
                      key={nestedIndex}
                      path={nestedRoute.path}
                      element={nestedRoute.component}
                    />
                  )
                })
              } else {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.component}
                  />
                )
              }
            })}
        </Route>
        <Route path="/subscription-expired" element={<SubscriptionPage />} />
        <Route
          path="sign-in/"
          element={
            <SignInGuard from="sign-in">
              <SignInLayout />
            </SignInGuard>
          }
        />
      </Routes>
    </>
  )
}

export default App
