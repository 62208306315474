import Card from 'components/card'
import { useForm } from 'react-hook-form'
import { useToaster } from 'common/Toaster'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import { useSpinner } from 'common/SpinnerLoader'
import appConstants from 'common/config/appConstants'
import CustomSelect from 'common/CustomSelect'
import React, { useState } from 'react'
import debounce from 'lodash.debounce'
import { RxCrossCircled } from 'react-icons/rx'
import { BsFillCheckCircleFill } from 'react-icons/bs'

const apiService = ApiCaller()
const { TOAST, LABEL, BUTTON_TEXT } = appConstants

const CreateBranch = ({ showBranchData, title, BranchData, getBranchList }) => {
  const { showSpinner, hideSpinner } = useSpinner()
  const { addToast } = useToaster()
  const [dnsProvider, setDnsProvider] = useState(
    BranchData?.topLevelDomain?.dnsProvider || ''
  )
  const [isBranchAvailable, setIsBranchAvailable] = useState(false)
  const organizationData = JSON.parse(localStorage.getItem('organizationData'))
  const [branchName, setBranchName] = useState('')

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      branchName: BranchData?.name || '',
      server: BranchData?.k8Cluster?.server || '',
      credentialType: BranchData?.k8Cluster?.credentialType || '',
      clusterName: BranchData?.k8Cluster?.name || '',
      domainName: BranchData?.topLevelDomain?.name || '',
      applicationKey: BranchData?.topLevelDomain?.applicationKey || '',
      applicationSecret: BranchData?.topLevelDomain?.applicationSecret || '',
    },
  })

  const dnsProviderLabel = [
    { label: 'Cloudflare', value: 'Cloudflare' },
    { label: 'Amazon Route 53', value: 'Amazon Route 53' },
    { label: 'Cloud DNS', value: 'Cloud DNS' },
  ]

  /**
   * Sets the DNS provider based on the selected option.
   *
   * @param {type} selectedOption - The selected option containing the new DNS provider value
   * @return {type} void
   */
  const handleChange = (selectedOption) => {
    setDnsProvider(selectedOption.value)
  }

  const onFormSubmit = async (formValue) => {
    const type = title === 'Add' ? 'CreateBranch' : 'edit'
    const apiType = title === 'Add' ? 'post' : 'put'
    const apiUrl = apiConfig.CREATE_BRANCH.replace(':type', type).replace(
      ':id',
      organizationData?.id
    )
    const payloadData = {
      env: {
        name: formValue.branchName,
        k8Cluster: {
          server: formValue.server,
          credentialType: formValue.credentialType,
          name:
            formValue.clusterName !== ''
              ? formValue.clusterName
              : `https:/kubernetes.${formValue.branchName?.toLowerCase()}.svc`,
        },
        topLevelDomain: {
          name:
            formValue.domainName !== ''
              ? formValue.domainName
              : `${formValue.branchName?.toLowerCase()}.com`,
          dnsProvider: dnsProvider,
          applicationKey: formValue.applicationKey,
          applicationSecret: formValue.applicationSecret,
        },
        envType: 'Default',
      },
    }
    try {
      showSpinner()
      const response = await apiService.apiCall(apiType, apiUrl, payloadData)
      if (response.data.code === 200) {
        getBranchList()
        addToast({
          title:
            response?.data?.msg || TOAST.MESSAGES.SUCCESS.organizationEdited,
          type: 'success',
        })
        showBranchData(false)
      } else {
        addToast({
          title: response?.data?.error,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  /**
   * Updates the branch name state and checks if the branch name is not empty.
   *
   * @param {Event} e - The event object triggered by the input change.
   * @return {void} This function does not return anything.
   */
  const handleBranchName = (e) => {
    setBranchName(e.target.value)
    const value = e.target.value
    if (value.length !== 0) {
      checkBranchName(value)
    }
  }

  const checkBranchName = debounce(async (event) => {
    try {
      const payload = {
        organizationId: organizationData?.id,
        branchName: event,
      }
      const cwsApiUrl = apiConfig.CHECK_BRANCH_NAME
      const response = await apiService.apiCall('post', cwsApiUrl, payload)
      if (response?.data?.code === 200) {
        setIsBranchAvailable(response?.data?.data?.isAvailableForBranchCreation)
      } else {
        addToast({
          title: response?.data?.msg || TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    }
  }, 500)

  return (
    <Card extra={'w-full h-full pb-[35px] pt-[35px] pr-[28px] pl-[33px]'}>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="font-bold text-brand-500">{title} Environment</div>
        <div className="my-4 mt-[15px] grid">
          <div>
            <div className="mb-2">
              Name <span className="text-red-500">*</span>
            </div>
            <div className="relative flex w-full items-center rounded-2xl border border-gray-200 bg-lightPrimary outline-none dark:!border-white/10 dark:!bg-navy-800">
              <input
                type="text"
                className={`placeholder:text-lightFourth md:text-md h-full w-full rounded-2xl p-3 pr-10 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white ${
                  title !== 'Add' ? 'bg-lightPrimary' : ''
                }`}
                placeholder="Enter Environment Name"
                id="branchName"
                onChange={handleBranchName}
                disabled={title !== 'Add'}
                {...register('branchName', {
                  required: 'Environment name is required',
                  onChange: handleBranchName,
                })}
              />
              {branchName.length > 0 && (
                <span className="absolute right-3 top-1/2 -translate-y-1/2 transform text-xl">
                  {isBranchAvailable ? (
                    <BsFillCheckCircleFill className="text-green-500" />
                  ) : (
                    <RxCrossCircled className="text-red-500" />
                  )}
                </span>
              )}
            </div>
            {branchName.length ? (
              <div
                className={`${
                  isBranchAvailable ? 'success-message' : 'error-message'
                } mb-2 text-right`}
              >
                {isBranchAvailable
                  ? 'The branch name is available'
                  : 'The branch name is already in use.'}
              </div>
            ) : (
              ''
            )}
            <div className="error-message mb-2 text-right">
              {errors.branchName?.message}
            </div>
          </div>
        </div>

        {/* Kubernetes cluster */}
        <div className="mt-6">
          <div className="font-bold text-brand-500">
            {LABEL.KUBERNETES_CLUSTER}
          </div>
          <div className="my-4 mt-[15px] grid grid-cols-2 gap-4">
            <div>
              <div className="mb-2">{LABEL.SERVER}</div>
              <div className="flex w-full items-center gap-3 rounded-2xl border border-gray-200 bg-lightPrimary  outline-none dark:!border-white/10 dark:!bg-navy-800">
                <input
                  extra="mb-4 w-full"
                  type="text"
                  className="placeholder:text-lightFourth md:text-md h-full w-full rounded-2xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                  placeholder="Enter Server"
                  {...register('server')}
                />
              </div>
            </div>
            <div>
              <div className="mb-2">{LABEL.CREDENTIALS_TYPE}</div>
              <div className="flex w-full items-center gap-3 rounded-2xl border border-gray-200 bg-lightPrimary  outline-none dark:!border-white/10 dark:!bg-navy-800">
                <input
                  extra="mb-4 w-full"
                  type="text"
                  className="placeholder:text-lightFourth md:text-md h-full w-full rounded-2xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                  placeholder="Enter Credentials Type"
                  {...register('credentialType')}
                />
              </div>
            </div>
          </div>
          <div className="my-4 mt-[15px] grid grid-cols-2 pt-6">
            <div>
              <div className="mb-2">{LABEL.CLUSTER_NAME}</div>
              <div className="flex w-full items-center rounded-2xl border border-gray-200 bg-lightPrimary  outline-none dark:!border-white/10 dark:!bg-navy-800">
                <input
                  extra="mb-4 w-full"
                  type="text"
                  className="placeholder:text-lightFourth md:text-md h-full w-full rounded-2xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                  placeholder="Enter Cluster Name"
                  {...register('clusterName')}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Top-level domain management */}
        <div className="mt-6">
          <div className="mb-2 font-bold text-brand-500">
            {LABEL.TOP_LEVEL_DOMAIN_MANAGEMENT}
          </div>
          <div className="my-4 mt-[15px] grid">
            <div>
              <div className="mb-2">{LABEL.DOMAIN_NAME}</div>
              <div className="flex w-full items-center rounded-2xl border border-gray-200 bg-lightPrimary  outline-none dark:!border-white/10 dark:!bg-navy-800">
                <input
                  extra="mb-4 w-full"
                  type="text"
                  className="placeholder:text-lightFourth md:text-md h-full w-full rounded-2xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                  placeholder="Enter Domain Name"
                  {...register('domainName')}
                />
              </div>
            </div>
          </div>
          <div className="my-4 mt-[15px] grid grid-cols-2 gap-4 pt-6">
            <div>
              <div className="mb-2">{LABEL.DNS_PROVIDER}</div>
              <div className="flex w-full items-center gap-3 rounded-2xl border border-gray-200  outline-none dark:!border-white/10 dark:!bg-navy-800">
                <CustomSelect
                  options={dnsProviderLabel}
                  onChange={handleChange}
                  value="dnsProvider"
                  placeholderText="Select DNS Provider"
                  extra="mb-4 w-full"
                  className="placeholder:text-lightFourth md:text-md h-full w-full rounded-2xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                />
              </div>
            </div>
            <div>
              <div className="mb-2">{LABEL.APPLICATION_KEY}</div>
              <div className="flex w-full items-center gap-3 rounded-2xl border border-gray-200 bg-lightPrimary  outline-none dark:!border-white/10 dark:!bg-navy-800">
                <input
                  extra="mb-4 w-full"
                  type="text"
                  className="placeholder:text-lightFourth md:text-md h-full w-full rounded-2xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                  placeholder="Enter Application Key"
                  {...register('applicationKey')}
                />
              </div>
            </div>
          </div>
          <div className="my-4 mt-[15px] flex grid grid-cols-2 gap-3 pt-6">
            <div>
              <div className="mb-2">{LABEL.APPLICATION_SECRET}</div>
              <div className="flex w-full items-center gap-3 rounded-2xl border border-gray-200 bg-lightPrimary  outline-none dark:!border-white/10 dark:!bg-navy-800">
                <input
                  extra="mb-4 w-full"
                  type="text"
                  className="placeholder:text-lightFourth md:text-md h-full w-full rounded-2xl p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                  placeholder="Enter Application Secret"
                  {...register('applicationSecret')}
                />
              </div>
            </div>
            <div className="mt-8 flex justify-end">
              <button
                onClick={() => showBranchData(false)}
                className="flex w-40 items-center justify-center rounded-2xl border border-brand-500 p-3 text-sm text-brand-500 transition duration-200 hover:cursor-pointer hover:bg-brand-600 hover:bg-brand-600 hover:text-white active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:text-base"
              >
                <span>{BUTTON_TEXT.BACK}</span>
              </button>
              <button
                type="submit"
                className="ml-2 flex w-40 items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:text-base"
              >
                <span> {title === 'Add' ? 'Submit' : 'Update'}</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </Card>
  )
}

export default CreateBranch
