import React, { useMemo, useState, useEffect } from 'react'
import TooltipHorizon from '../../../../components/tooltip'
import { IoMdEye } from 'react-icons/io'
import { SiArgo } from 'react-icons/si'
import appConstants from 'common/config/appConstants'

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
  isHelmLoading,
} from 'react-table'
import { BsArrowDown, BsArrowUp } from 'react-icons/bs'

/**
 * Renders a table component for displaying search results.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Array} props.columnsData - The data for the table columns.
 * @param {Array} props.tableData - The data for the table rows.
 * @param {string} props.toggleState - The current toggle state.
 * @return {JSX.Element} The rendered table component.
 */
function SearchTableOrders(props) {
  const { columnsData, tableData, toggleState } = props
  const columns = useMemo(() => columnsData, [columnsData])
  const [data, setData] = useState([])
  const tableSkeletons = Array.from({ length: 4 })

  useEffect(() => {
    if (tableData) {
      setData(tableData)
    }
  }, [tableData])

  /**
   * Opens a new window with the Helm URL from the data object.
   *
   * @param {Object} data - The data object containing the helmUrl.
   * @return {void} Opens a new window.
   */
  const handleViewClick = (data) => {
    window.open(data?.original?.helmUrl, '_blank')
  }

  /**
   * A function that opens a new window with the ArgoCD URL from the data object.
   *
   * @param {Object} data - The data object containing the argoUrl.
   * @return {void} Opens a new window.
   */
  const handleArgoCdClick = (data) => {
    window.open(data?.original?.argoUrl, '_blank')
  }

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    tableInstance

  const { NO_DATA_AVAIL, TABS } = appConstants

  return (
    <>
      {toggleState === `${TABS.HELM_CHART_TAB}` && (
        <div className="h-full w-full p-2 md:p-4">
          {/* Table */}
          {isHelmLoading ? (
            <div className="flex flex-col">
              {tableSkeletons.map((_item, index) => (
                <div className="flex animate-pulse border-b border-gray-200 py-4 dark:!border-white/10">
                  <div className="w-1/5">
                    <div className="ml-4 h-2 w-1/6 rounded bg-gray-200 dark:bg-gray-800"></div>
                  </div>
                  <div className="w-2/5">
                    <div className="ml-4 h-2 w-1/3 rounded bg-gray-200 dark:bg-gray-800"></div>
                  </div>
                  <div className="w-2/5">
                    <div className="ml-8 h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="mt-2 min-h-[150px] w-full overflow-x-scroll xl:overflow-hidden">
              <table {...getTableProps()} className="w-full">
                <thead className="w-full">
                  {headerGroups.map((headerGroup, indexRow) => (
                    <tr
                      className="items-center border-b border-gray-200 dark:!border-white/10"
                      {...headerGroup.getHeaderGroupProps()}
                      key={indexRow}
                    >
                      {headerGroup.headers.map((column, index) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className={`px-4 pb-[10px] text-left text-xs text-gray-600 ${
                            column.render('Header') === 'SR' ? 'w-24' : ''
                          }`}
                          key={index}
                        >
                          <div className="flex items-center">
                            {column.render('Header')}
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <BsArrowDown className="ml-2" />
                              ) : (
                                <BsArrowUp className="ml-2" />
                              )
                            ) : (
                              ''
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, rowIndex) => {
                    prepareRow(row)
                    return (
                      <tr
                        className="border-b border-gray-200 dark:!border-white/10"
                        {...row.getRowProps()}
                        key={rowIndex}
                      >
                        {row.cells.map((cell, cellIndex) => (
                          <td
                            className="px-4"
                            {...cell.getCellProps()}
                            key={cellIndex}
                          >
                            {cell.column.Header === 'SR' ? (
                              <div className="w-24 text-left font-medium text-navy-700  dark:text-white">
                                {rowIndex + 1}
                              </div>
                            ) : cell.column.Header === 'ENV' ? (
                              <div className="text-left font-medium text-navy-700 dark:text-white">
                                {cell.value}
                              </div>
                            ) : (
                              <div className="flex space-x-2">
                                <div className="w-max rounded-xl py-1 text-sm">
                                  <TooltipHorizon
                                    extra="border border-gray-200 dark:border-gray-700"
                                    trigger={
                                      <p
                                        className="flex cursor-pointer items-center rounded-full border p-1 font-bold text-[#000] hover:font-medium hover:text-black dark:text-white"
                                        onClick={() => handleViewClick(row)}
                                      >
                                        <span>
                                          <IoMdEye className="text-xl" />
                                        </span>
                                      </p>
                                    }
                                    content="View"
                                    placement="top"
                                  />
                                </div>
                                <div className="w-max rounded-xl py-1 text-sm">
                                  <TooltipHorizon
                                    extra="border border-gray-200 dark:border-gray-700"
                                    trigger={
                                      <p
                                        className="flex cursor-pointer items-center rounded-full border p-1 font-bold text-[#000] hover:font-medium hover:text-black dark:text-white"
                                        onClick={() => handleArgoCdClick(row)}
                                      >
                                        <span>
                                          <SiArgo className="text-xl" />
                                        </span>
                                      </p>
                                    }
                                    content="Argo CD"
                                    placement="top"
                                  />
                                </div>
                              </div>
                            )}
                          </td>
                        ))}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              {/* Display message if no data */}
              {!isHelmLoading && rows.length === 0 && (
                <div className="text-md mt-3 flex w-full justify-center text-center text-gray-500 dark:text-gray-400">
                  {NO_DATA_AVAIL}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default SearchTableOrders
