import React, { useMemo, useState, useEffect } from 'react'
import { FiSearch } from 'react-icons/fi'
import GetStartedButton from './GetStartedButton'
import CardMenu from 'components/card/CardMenu'
import moment from 'moment'
import debounce from 'lodash.debounce'
import { useToaster } from 'common/Toaster'
import appConstants from 'common/config/appConstants'
import { getAllApplicationData } from 'common/commonFunction'
import ApiCaller from 'common/services/apiServices'
import apiConfig from 'common/config/apiConfig'
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'
import { BsArrowDown, BsArrowUp } from 'react-icons/bs'
import { ImSpinner6 } from 'react-icons/im'
import PaginationSkeleton from 'components/skeletons/PaginationSkeleton'
import WebServices from 'assets/img/users/web-services.png'
import StaticSites from 'assets/img/users/static-sites.png'
import Cron from 'assets/img/users/cron.png'
import PostgreSQL from 'assets/img/users/postgre-sql.png'
import Redis from 'assets/img/users/redis.png'
import TooltipHorizon from 'components/tooltip'
import { RxCross2 } from 'react-icons/rx'
import { IoMdCheckmark } from 'react-icons/io'

function SearchTableOrders(props) {
  const { addToast } = useToaster()
  const { TOAST, NO_DATA_AVAIL } = appConstants
  const {
    columnsData,
    projectId,
    totalCount,
    onPageChange,
    itemsPerPage,
    currentPage,
    isApplicationLoading,
    setApplicationLoading,
  } = props
  const apiService = ApiCaller()
  const columns = useMemo(() => columnsData, [columnsData])
  const [data, setData] = useState([])
  const [pageIndex, setPageIndex] = useState(currentPage)
  const [globalFilter, setGlobalFilter] = useState('')
  const [totalPageCount, setPageCount] = useState(0)
  const tableSkeletons = Array.from({ length: 4 })
  const [editingPortRow, setEditingPortRow] = useState(null)
  const [newPortValue, setNewPortValue] = useState('')
  const [isUpdatePort, setUpdatePort] = useState(false)
  useEffect(() => {
    fetchData(pageIndex, globalFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, globalFilter])

  useEffect(() => {
    if (totalCount) {
      setPageCount(totalCount)
    }
  }, [totalCount])

  const handlePortEdit = (rowIndex, initialValue) => {
    setEditingPortRow(rowIndex)
    setNewPortValue(initialValue.port)
  }

  const handlePortChange = (event) => {
    setNewPortValue(event.target.value)
  }

  const handlePortSave = (applicationId) => {
    updatePort(newPortValue, applicationId)
  }

  const updatePort = async (newPortValue, applicationId) => {
    try {
      setUpdatePort(true)
      const organizationData = JSON.parse(
        localStorage.getItem('organizationData')
      )
      const apiUrl = apiConfig.UPDATE_PORT
      const payload = {
        organizationId: organizationData?.id,
        applicationId: applicationId,
        port: parseInt(newPortValue),
      }
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.code === 200) {
        addToast({
          title: response?.data?.msg,
          type: 'success',
        })
      }
      setUpdatePort(false)
      setEditingPortRow(null)
      fetchData(pageIndex, globalFilter)
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    }
  }

  /**
   * Fetches data based on pageIndex and globalFilter.
   *
   * @param {number} pageIndex - The current page index.
   * @param {string} globalFilter - The global filter criteria.
   * @return {Promise} A promise that resolves to the fetched data.
   */
  const fetchData = async (pageIndex, globalFilter) => {
    try {
      setApplicationLoading(true)
      const response = await getAllApplicationData(
        projectId,
        pageIndex + 1,
        globalFilter
      )
      if (response?.data?.code === 200) {
        setData(response?.data.data.results)
      } else {
        addToast({
          title: response?.data?.msg || TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
      }
    } catch (err) {
      console.log('Error fetching data:', err)
    } finally {
      setApplicationLoading(false)
    }
  }

  const debouncedSetGlobalFilter = useMemo(
    () =>
      debounce((value) => {
        setGlobalFilter(value)
      }),
    []
  )

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: itemsPerPage },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  let count = 1

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    gotoPage,
  } = tableInstance

  const page = tableInstance.page

  /**
   * Updates the page index and navigates to the specified page.
   *
   * @param {number} page - The page number to navigate to.
   * @return {void} This function does not return anything.
   */
  const changePage = (page) => {
    setPageIndex(page)
    gotoPage(page)
    onPageChange(page + 1)
  }

  /**
   * Returns a string representing the type of application based on the given column value.
   *
   * @param {Object} row - The row object.
   * @return {string|undefined} The type of application or undefined if the column value is not recognized.
   */
  const applicationType = (row) => {
    const appType = row?.original?.appType
    switch (appType) {
      case 'webApp':
        return {
          img: WebServices,
          title: 'Web Service',
        }
      case 'frontend':
        return {
          img: StaticSites,
          title: 'Static Sites',
        }
      case 'cron':
        return {
          img: Cron,
          title: 'Cron Jobs',
        }
      case 'helm': {
        const helmName = row?.original?.helm?.name
        // Nested switch case for helm types
        switch (helmName) {
          case 'postgresql':
            return {
              img: PostgreSQL,
              title: `Helm (PostgreSQL - ${row?.original?.helm?.version})`,
            }
          case 'redis':
            return {
              img: Redis,
              title: `Helm (Redis - ${row?.original?.helm?.version})`,
            }
          default:
            return {
              img: null, // Handle default case where no match is found
              title: `Helm (${helmName} - ${row?.original?.helm?.version})`,
            }
        }
      }
      default:
        return {
          img: null,
          title: null,
        }
    }
  }

  return (
    <>
      <div className="h-full w-full p-2 md:p-4">
        <div className="flex h-full w-full flex-col items-center justify-between !pt-0 md:flex-row md:py-2">
          {/* Search input */}
          <div className="mb-3 flex w-full items-center rounded-full bg-white p-[5px] shadow-2xl shadow-white dark:!bg-navy-800 dark:shadow-none md:mb-0 md:w-9/12">
            <div className="flex h-9 w-full flex-grow items-center rounded-full border-white bg-lightPrimary text-sm text-gray-600 dark:border dark:!bg-navy-900">
              <FiSearch className="mx-2 h-6 w-4 !text-gray-700 dark:!text-white" />
              <input
                type="text"
                placeholder="Search By Service Name"
                className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white  sm:w-full"
                value={globalFilter}
                onChange={(e) => debouncedSetGlobalFilter(e.target.value)}
              />
            </div>
          </div>
          {/* Create Services button */}
          <GetStartedButton
            className="mt-4 w-full md:mt-3"
            buttonText={'Create Services'}
            isCreateService={true}
            projectId={projectId}
          />
        </div>

        {/* Table */}
        {isApplicationLoading ? (
          <div className="flex flex-col">
            {tableSkeletons.map((_item, index) => (
              <div className="flex animate-pulse border-b border-gray-200 py-4 dark:!border-white/10">
                <div className="w-1/5">
                  <div className="ml-4 h-2 w-1/6 rounded bg-gray-200 dark:bg-gray-800"></div>
                </div>
                <div className="w-2/5">
                  <div className="ml-4 h-2 w-1/3 rounded bg-gray-200 dark:bg-gray-800"></div>
                </div>
                <div className="w-2/5">
                  <div className="ml-8 h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="mt-2 min-h-[150px] w-full overflow-x-scroll xl:overflow-hidden">
            <table {...getTableProps()} className="w-full">
              <thead className="w-full">
                {headerGroups.map((headerGroup) => (
                  <tr
                    className="items-center border-b border-gray-200 dark:!border-white/10"
                    {...headerGroup.getHeaderGroupProps()}
                    key={headerGroup.id}
                  >
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className="px-4 pb-[10px] text-left text-xs text-gray-600"
                        key={column.id}
                      >
                        <div className="flex items-center">
                          {column.render('Header')}
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <BsArrowDown className="ml-2" />
                            ) : (
                              <BsArrowUp className="ml-2" />
                            )
                          ) : (
                            ''
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, rowIndex) => {
                  prepareRow(row)
                  return (
                    <tr
                      className={`border-b border-gray-200 dark:!border-white/10 ${
                        row?.values?.appType === 'helm' ? 'h-10' : ''
                      }`}
                      {...row.getRowProps()}
                      key={rowIndex}
                    >
                      {row.cells.map((cell, cellIndex) => {
                        let cellClassName = 'px-4'
                        let data = ''
                        if (cell.column.Header === 'SERVICE') {
                          data = (
                            <div className="flex items-center">
                              <div className="m-1 w-8 rounded-lg bg-lightPrimary p-1 dark:bg-navy-700">
                                <TooltipHorizon
                                  extra="border border-gray-200 dark:border-gray-700"
                                  trigger={
                                    <img
                                      src={applicationType(row).img}
                                      className="rounded-full"
                                      alt="img"
                                    />
                                  }
                                  content={applicationType(row).title}
                                  placement="top"
                                />
                              </div>
                              {row?.original?.name}
                            </div>
                          )
                        } else if (cell.column.Header === 'S.NO') {
                          cellClassName += ' w-24'
                          data = (
                            <div className="text-left font-medium text-navy-700 dark:text-white">
                              {pageIndex * itemsPerPage + count++}
                            </div>
                          )
                        } else if (cell.column.Header === 'PORT') {
                          data =
                            editingPortRow === rowIndex ? (
                              <div className="my-1 flex text-left font-medium text-navy-700 dark:text-white">
                                <input
                                  type="number"
                                  value={newPortValue}
                                  onChange={handlePortChange}
                                  onInput={(e) =>
                                    (e.target.value = e.target.value.slice(
                                      0,
                                      5
                                    ))
                                  }
                                  className="w-20 rounded-md border px-2 py-1 text-sm dark:bg-gray-700 dark:text-white"
                                />
                              </div>
                            ) : (
                              <div className="text-left font-medium text-navy-700 dark:text-white">
                                {cell.value}
                              </div>
                            )
                        } else if (cell.column.Header === 'LAST DEPLOYED') {
                          data = (
                            <div className="text-left text-sm text-navy-700 dark:text-white">
                              {moment(cell.value).format('lll')}
                            </div>
                          )
                        } else if (cell.column.Header === 'STATUS ORDER') {
                          data = (
                            <div
                              className={`flex h-7 w-[110px] items-center justify-center text-sm ${
                                cell.value === 'Completed'
                                  ? 'bg-green-100 dark:bg-green-50'
                                  : 'bg-red-100 dark:bg-red-50'
                              } rounded-[10px] text-base font-bold `}
                            >
                              <div
                                className={`${
                                  cell.value === 'Completed'
                                    ? 'text-green-500 '
                                    : 'text-red-500'
                                } uppercase `}
                              >
                                {cell.value}
                              </div>
                            </div>
                          )
                        } else {
                          data =
                            editingPortRow === rowIndex ? (
                              !isUpdatePort ? (
                                <div className="flex text-left font-medium text-navy-700 dark:text-white">
                                  <div
                                    className="mr-2 flex cursor-pointer items-center rounded-full border p-1  font-bold text-[#000] hover:font-medium hover:text-black dark:text-white "
                                    onClick={() =>
                                      handlePortSave(row?.original?._id)
                                    }
                                  >
                                    <span>
                                      <TooltipHorizon
                                        extra="border border-gray-200 dark:border-gray-700"
                                        trigger={
                                          <p>
                                            <span>
                                              <IoMdCheckmark className="text-xl" />
                                            </span>
                                          </p>
                                        }
                                        content="Save"
                                        placement="top"
                                      />
                                    </span>
                                  </div>
                                  <div
                                    className="flex cursor-pointer items-center rounded-full border p-1 font-bold  text-[#000] hover:font-medium hover:text-black dark:text-white"
                                    onClick={() => setEditingPortRow(null)}
                                  >
                                    <span>
                                      <TooltipHorizon
                                        extra="border border-gray-200 dark:border-gray-700"
                                        trigger={
                                          <p>
                                            <span>
                                              <RxCross2 className="text-xl" />
                                            </span>
                                          </p>
                                        }
                                        content="Cancel"
                                        placement="top"
                                      />
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <ImSpinner6 className="animate-spin text-center text-xl text-brand-500" />
                              )
                            ) : (
                              <div
                                className="text-sm font-medium text-brand-500 underline dark:text-white"
                                id={cell.value}
                              >
                                <CardMenu
                                  cellValue={row.original}
                                  rowIndex={rowIndex}
                                  handlePortEdit={handlePortEdit}
                                />
                              </div>
                            )
                        }
                        return (
                          <td
                            className={cellClassName}
                            {...cell.getCellProps()}
                            key={cellIndex}
                          >
                            {data}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
            {/* Display message if no data */}
            {!isApplicationLoading && page.length === 0 && (
              <div className="text-md mt-3 flex w-full justify-center text-left text-gray-500 dark:text-gray-400">
                {NO_DATA_AVAIL}
              </div>
            )}
          </div>
        )}

        {/* Pagination */}
        {isApplicationLoading ? (
          <PaginationSkeleton />
        ) : (
          page.length > 0 && (
            <div className="mt-2 flex h-12 w-full items-center justify-between px-6">
              <div className="text-sm text-gray-700">
                {/* Display current range of entries */}
                Showing {pageIndex * itemsPerPage + 1} to{' '}
                {totalPageCount < pageIndex * itemsPerPage + data.length
                  ? totalPageCount
                  : pageIndex * itemsPerPage + data.length}{' '}
                of {totalPageCount} entries
              </div>

              <div className="flex items-center">
                {/* Render pagination buttons */}
                <div className="ml-3 flex">
                  {/* First button */}
                  <button
                    className={`h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                      pageIndex === 0 ? 'cursor-not-allowed opacity-50' : ''
                    }`}
                    onClick={() => changePage(0)}
                    disabled={pageIndex === 0}
                  >
                    {'<<'}
                  </button>

                  {/* Previous button */}
                  <button
                    className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                      pageIndex === 0 ? 'cursor-not-allowed opacity-50' : ''
                    }`}
                    onClick={() => changePage(pageIndex - 1)}
                    disabled={pageIndex === 0}
                  >
                    {'<'}
                  </button>

                  {/* Pagination buttons */}
                  {Array.from(
                    { length: Math.min(5, Math.ceil(totalPageCount / 10)) }, // Limit to a maximum of 5 pages
                    (_, index) => {
                      const page =
                        Math.max(
                          0,
                          Math.min(
                            Math.ceil(totalPageCount / 10) - 5,
                            pageIndex
                          )
                        ) + index
                      return (
                        <button
                          key={page}
                          className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                            pageIndex === page
                              ? 'cursor-not-allowed opacity-50'
                              : ''
                          }`}
                          onClick={() => changePage(page)}
                          disabled={pageIndex === page}
                        >
                          {page + 1}
                        </button>
                      )
                    }
                  )}

                  {/* Next button */}
                  <button
                    className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                      pageIndex >= Math.ceil(totalPageCount / 10) - 1
                        ? 'cursor-not-allowed opacity-50'
                        : ''
                    }`}
                    onClick={() => changePage(pageIndex + 1)}
                    disabled={pageIndex >= Math.ceil(totalPageCount / 10) - 1}
                  >
                    {'>'}
                  </button>

                  {/* Last button */}
                  <button
                    className={`ml-3 h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 ${
                      pageIndex >= Math.ceil(totalPageCount / 10) - 1
                        ? 'cursor-not-allowed opacity-50'
                        : ''
                    }`}
                    onClick={() =>
                      changePage(Math.ceil(totalPageCount / 10) - 1)
                    }
                    disabled={pageIndex >= Math.ceil(totalPageCount / 10) - 1}
                  >
                    {'>>'}
                  </button>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </>
  )
}

export default SearchTableOrders
