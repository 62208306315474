import Card from 'components/card'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect, useCallback } from 'react'
import { useToaster } from 'common/Toaster'
import apiConfig from 'common/config/apiConfig'
import ApiCaller from 'common/services/apiServices'
import appConstants from 'common/config/appConstants'
import { useSpinner } from 'common/SpinnerLoader'
import { getOrganization, toKebabCase } from 'common/commonFunction'
import { useDialogContext } from 'contexts/DialogProvider'
import BranchesComponent from './OrganizationData'
import { ImCross } from 'react-icons/im'
import debounce from 'lodash.debounce'
import { RxCrossCircled } from 'react-icons/rx'
import { BsFillCheckCircleFill } from 'react-icons/bs'
import Switch from 'components/switch'
import { FaGithub } from 'react-icons/fa'

const apiService = ApiCaller()
const { TOAST, REQUIRED, LABEL, TABS } = appConstants
const baseUrl = process.env.REACT_APP_NEW_API_BASE_URL
const CreateOrganization = () => {
  const navigate = useNavigate()
  const [organizationName, setOrganization] = useState('')
  const [repositoryName, setRepositoryName] = useState('')
  const [GithubToken, setGithubToken] = useState('')
  const [githubTokenDATA, setGithubTokenDATA] = useState('')
  const [branchName, setBranchName] = useState('')
  const [isUpdate, setIsUpdate] = useState(true)
  const [flag, setFlag] = useState('')
  const [repoFlag, setRepoFlag] = useState('')
  const [userInteracted, setUserInteracted] = useState(false)
  const [userInteractedWithRepo, setUserInteractedWithRepo] = useState(false)
  const [branchLists, setBranchLists] = useState([])
  const { showSpinner, hideSpinner } = useSpinner()
  const { addToast } = useToaster()
  const [organizationError, setOrganizationError] = useState('')
  const [repositoryError, setRepositoryError] = useState('')
  const [GithubError] = useState('')
  const [branchListError, setBranchListError] = useState('')
  const [inputDisabled, setInputDisabled] = useState(false)
  const {
    openDialog,
    setModalCloseHandler,
    setModalSubmitHandler,
    onClose,
    isOpen,
  } = useDialogContext()
  const [isLoading, setIsLoading] = useState(false)
  const [isRepoApiLoading, setIsRepoApiLoading] = useState(false)
  const [toggleState, setToggleState] = useState(`${TABS.BRANCH_DETAIL}`)
  const [isAPILoading, setAPILoading] = useState(false)
  const [gitUser, setGitUser] = useState()
  const valuesSkeletons = Array.from({ length: 1 })
  const [checked, setChecked] = useState(true)
  const toggleTab = (value) => {
    setToggleState(value)
  }

  /**
   * Adds a new branch to the branchLists array if it is not a duplicate.
   *
   * @param {string} branch - The name of the branch to be added.
   * @return {void} This function does not return anything.
   */
  const addBranch = (branch) => {
    const newBranch = {
      name: branch,
      k8Cluster: {
        server: '',
        credentialType: '',
        name: `https:/kubernetes.${branch?.toLowerCase()}.svc`,
      },
      topLevelDomain: {
        name: `${branch?.toLowerCase()}.com`,
        dnsProvider: '',
        applicationKey: '',
        applicationSecret: '',
      },
    }
    const isDuplicate = branchLists.some((b) => b.name === branch)
    if (!isDuplicate) {
      setBranchLists((prevBranchLists) => [...prevBranchLists, newBranch])
      setBranchListError('')
      setBranchName('')
    }
  }

  const debouncedGetOrganizationData = useCallback(
    debounce(async (payload) => {
      try {
        const apiUrl = apiConfig.GET_ORGANIZATION_DATA
        const response = await apiService.apiCall('post', apiUrl, payload)
        if (response?.data?.code === 200) {
          setFlag(response?.data?.data?.isAvailableForCreation)
        } else {
          // setCompletedStatus('in_progress');
        }
      } finally {
        setIsLoading(false)
      }
    }, 300),
    []
  )

  const debouncedCheckRepository = useCallback(
    debounce(async (payload) => {
      try {
        const apiUrl = apiConfig.CHECK_REPO
        const response = await apiService.apiCall('post', apiUrl, payload)
        if (response?.data?.code === 200) {
          setRepoFlag(response?.data?.data?.isAvailableForCreation)
        }
      } finally {
        setIsRepoApiLoading(false)
      }
    }, 300),
    []
  )

  /**
   * Update the organization with the provided data.
   *
   * @param {Event} e - The event object.
   * @return {Promise<void>} A promise that resolves once the organization is updated.
   */
  const UpdateOrganization = async (e) => {
    e.preventDefault() // Prevents the form from submitting
    if (branchLists.length === 0) {
      setBranchListError(REQUIRED.branchRequired)
    } else {
      setBranchListError('')
    }
    const apiUrl = apiConfig.SET_ORGANIZATION
    const payloadData = {
      name: organizationName,
      repoName: repositoryName,
      // githubToken: GithubToken,
      env: branchLists,
      isOrg: checked,
    }
    try {
      showSpinner()
      const response = await apiService.apiCall('post', apiUrl, payloadData)
      if (response.data.code === 200) {
        getOrganizationDetails()
        addToast({
          title:
            response?.data?.msg || TOAST.MESSAGES.SUCCESS.organizationCreated,
          type: 'success',
        })
      } else {
        addToast({
          title: response?.data?.msg,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  const removeBranch = (value) => {
    setBranchLists(branchLists?.filter((item) => item.name !== value))
  }

  useEffect(() => {
    getOrganizationDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleGitHubAuth = async () => {
    try {
      const payload = {
        custom_value: {
          frontendUrl: window.location.origin + '/settings/organization',
          backendUrl: baseUrl.replace(/\/$/, ''),
        },
      }
      const apiUrl = apiConfig.GITHUB_AUTH
      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response?.data?.code === 200) {
        window.open(response?.data?.data, '_self')
      } else {
        onClose()
        addToast({
          title: TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
      }
    } catch (error) {
      onClose()
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const getEnvironment = async (envType) => {
    try {
      const apiUrl = `${apiConfig.ORGANIZATION_ENV}${
        envType === 'Default' ? '?envType=Default' : ''
      }`
      const response = await apiService.apiCall('get', apiUrl)
      if (response?.data?.code === 200) {
        setBranchLists(response?.data?.data)
      } else {
        addToast({
          title: TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    }
  }

  const handleDialogClose = () => {
    navigate(`/`)
  }

  useEffect(() => {
    if (isOpen) {
      setModalCloseHandler(() => handleDialogClose)
    }
  }, [isOpen, setModalCloseHandler])

  /**
   * Function to fetch organization details asynchronously.
   *
   */
  const getOrganizationDetails = async () => {
    try {
      showSpinner()
      setAPILoading(true)
      const response = await getOrganization()
      if (response?.data?.code === 200 && response?.data?.data?.name) {
        const responseData = response?.data?.data
        setInputDisabled(true)
        setOrganization(responseData.name)
        setRepositoryName(responseData.repoName)
        setGithubTokenDATA(responseData.githubToken)
        setGithubToken(responseData.githubToken)
        setGitUser(responseData?.gitUser)
        const org_Data = {
          org_name: responseData?.name,
          repo_name: responseData?.repoName,
          branch_name: responseData?.env?.map((env) => env.name),
          id: responseData?._id,
          github_token: responseData?.githubToken,
          cloud_type: responseData?.cloudType,
        }
        localStorage.setItem('organizationData', JSON.stringify(org_Data))
        getEnvironment('Default')
      } else {
        setInputDisabled(false)
        if (!response?.data?.data?.githubToken) {
          // Open Dialog and ask user to create organization
          openDialog(
            'Connect via!',
            <>
              <div className="flex flex-col  space-y-4">
                <button
                  onClick={() => handleGitHubAuth()}
                  className="flex items-center justify-center rounded-2xl border border-black py-3 text-sm text-black transition duration-200 hover:cursor-pointer hover:bg-brand-600 hover:bg-brand-600 hover:text-white active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:text-base"
                >
                  <FaGithub className="mr-3 text-xl" />
                  {/* <img
                    src={GithubSquare}
                    alt="GitHub"
                    className="mr-2 h-6 w-6"
                  /> */}
                  <span>Sign in with GitHub</span>
                </button>
                {/* <button
                  className="flex items-center justify-center rounded-2xl border border-brand-500 p-3 text-sm text-brand-500 transition duration-200 hover:cursor-pointer hover:bg-brand-600 hover:bg-brand-600 hover:text-white active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:text-base"
                >
                  <img src={Bitbucket} alt="GitHub" className="w-6 h-6 mr-2" />
                  <span>Sign in with Bitbucket</span>
                </button> */}
              </div>
            </>
          )
          // Set a function which Returns another function so that it is not called immediately.
          // This function will be called once the model is closed.
          setModalCloseHandler(() => () => {
            localStorage.setItem('isCreateOrgWarningModalClosed', true)
          })
          setModalSubmitHandler(() => () => {
            localStorage.setItem('isCreateOrgWarningModalClosed', true)
            onClose()
          })
        } else {
          setGithubTokenDATA(response?.data?.data.githubToken)
          setGithubToken(response?.data?.data.githubToken)
          setGitUser(response?.data?.data?.gitUser)
        }
      }
      setAPILoading(false)
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      hideSpinner()
    }
  }

  useEffect(() => {
    setIsUpdate(true)
    if (organizationName && repositoryName && branchLists.length > 0) {
      setIsUpdate(false)
    }
  }, [organizationName, repositoryName, GithubToken, branchLists])

  /**
   * Handles the change event of the organization input field.
   *
   * @param {Event} e - The change event object.
   * @return {void} This function does not return a value.
   */
  const handleOrganizationChange = (e) => {
    const value = e.target.value
    setUserInteracted(true)
    if (!value.trim()) {
      setOrganizationError(REQUIRED.organizationRequired)
    } else {
      setOrganizationError('')
      if (value.trim() !== '') {
        setIsLoading(true)
        debouncedGetOrganizationData({ organizationName: value })
      }
    }
    setOrganization(toKebabCase(value))
  }
  const handleRepositoryChange = (e) => {
    const value = e.target.value
    setUserInteractedWithRepo(true)
    // Validation logic for repository name
    if (!value.trim()) {
      setRepositoryError(REQUIRED.RepositoryRequired)
    } else {
      setRepositoryError('')
      if (value.trim() !== '') {
        setIsRepoApiLoading(true)
        debouncedCheckRepository({ repoName: value })
      }
    }
    setRepositoryName(toKebabCase(value))
  }

  const handleChange = (event) => {
    setChecked(event.target.checked)
  }

  const maskInputValue = (value) => {
    if (value === '' || value === null || value === undefined) return ''
    if (value) {
      const visibleLength = 5
      const maskedLength = value.length - visibleLength * 2
      const maskedValue =
        value.substring(0, visibleLength) +
        '*'.repeat(maskedLength) +
        value.substring(value.length - visibleLength)
      return maskedValue
    }
  }

  return (
    <div>
      {inputDisabled && (
        <div className="mb-1 flex w-full flex-col items-center rounded-xl xl:mb-3">
          <div className="mb-1 flex w-full justify-start gap-8 overflow-hidden text-3xl">
            <div
              className={
                toggleState === `${TABS.BRANCH_DETAIL}`
                  ? 'flex items-center gap-3 border-b-[4px] border-brand-500 pb-1 hover:cursor-pointer dark:border-brand-400'
                  : 'flex items-center gap-3 border-b-[4px] border-none pb-1 hover:cursor-pointer dark:!border-navy-800'
              }
              onClick={() => toggleTab(`${TABS.BRANCH_DETAIL}`)}
            >
              <p
                className={
                  toggleState === `${TABS.BRANCH_DETAIL}`
                    ? 'text-[18px] font-bold text-navy-700 dark:text-white'
                    : 'text-[18px] text-disableGray dark:text-white'
                }
              >
                {TABS.DETAILS}
              </p>
            </div>

            <div
              className={
                toggleState === `${TABS.ENVIRONMENT_LIST}`
                  ? 'flex items-center gap-3 border-b-[4px] border-brand-500 pb-1 hover:cursor-pointer dark:border-brand-400'
                  : 'flex items-center gap-3 border-b-[4px] border-none pb-1 hover:cursor-pointer dark:!border-navy-800'
              }
              onClick={() => toggleTab(`${TABS.ENVIRONMENT_LIST}`)}
            >
              <p
                className={
                  toggleState === `${TABS.ENVIRONMENT_LIST}`
                    ? 'text-[18px] font-bold text-navy-700 dark:text-white'
                    : 'text-[18px] text-disableGray dark:text-white'
                }
              >
                {TABS.ENVIRONMENTS}
              </p>
            </div>
          </div>
          <BranchesComponent
            toggleState={toggleState}
            setToggleState={setToggleState}
            branchLists={branchLists}
            fetchOrganization={getOrganizationDetails}
            handleChangeEnviroment={getEnvironment}
          />
        </div>
      )}
      {toggleState === `${TABS.BRANCH_DETAIL}` && (
        <Card extra={'w-full h-full pb-[35px] pt-[3px] pr-[28px] pl-[33px]'}>
          {isAPILoading ? (
            <div className="flex animate-pulse flex-col gap-y-8 p-6">
              <div className="flex flex-col gap-y-4">
                {valuesSkeletons.map((_item, index) => (
                  <div key={index} className="flex gap-x-4">
                    <div className="flex w-full flex-col gap-y-2">
                      <div className="h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
                      <div className="h-8 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                    <div className="flex w-full flex-col gap-y-2">
                      <div className="h-2 w-1/4 rounded bg-gray-200 dark:bg-gray-800"></div>
                      <div className="h-8 rounded-xl bg-gray-200 dark:bg-gray-800"></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <>
              {!inputDisabled && (
                <div className="mt-[15px]">
                  <div className="flex">
                    <Switch
                      checked={checked}
                      onChange={handleChange}
                      color="primary" // Change the color (primary, secondary, etc.)
                    />
                    <label className="text-md ml-2 text-navy-700 dark:text-white">
                      As {checked ? 'Organisation' : 'User'}
                    </label>
                  </div>
                </div>
              )}
              <div className="my-1 mt-[15px] grid grid-cols-2 gap-4">
                <div>
                  <div className="mb-2">
                    {checked ? LABEL.ORGANIZATION_NAME : LABEL.USER_NAME}
                    {!inputDisabled && (
                      <span className="pl-[3px] text-red-500">*</span>
                    )}
                  </div>
                  <div className="flex w-full items-center gap-3 rounded-2xl border border-gray-200 bg-lightPrimary pl-[20px] outline-none dark:!border-white/10 dark:!bg-navy-800">
                    <input
                      extra="mb-4 w-full"
                      value={organizationName}
                      onChange={handleOrganizationChange}
                      type="text"
                      autoComplete="off"
                      disabled={inputDisabled}
                      className="placeholder:text-lightFourth md:text-md h-full w-full rounded-2xl bg-lightPrimary p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                      placeholder={
                        checked ? 'Enter Organization Name' : 'Enter User Name'
                      }
                    />
                  </div>
                  {!inputDisabled && (
                    <>
                      {organizationError && (
                        <div className="mt-1 text-sm text-red-500">
                          {organizationError}
                        </div>
                      )}
                      {userInteracted && organizationName.trim() !== '' && (
                        <span className="ml-2">
                          {isLoading ? (
                            <span className="logs-loader absolute right-[52%] top-[6.3rem]  -translate-y-1/2 transform text-xl "></span>
                          ) : flag ? (
                            <BsFillCheckCircleFill className="text-inherit absolute right-[52%]  top-[7rem] h-6 w-6 -translate-y-1/2 transform text-xl text-green-500" />
                          ) : (
                            <RxCrossCircled className="text-inherit absolute right-[52%] top-[7rem] h-6 w-6 -translate-y-1/2 text-xl text-red-500" />
                          )}
                        </span>
                      )}
                      {userInteracted && organizationName.trim() !== '' && (
                        <span className="ml-2">
                          {isLoading ? (
                            <span className="text-sm text-gray-250">
                              Checking availability...
                            </span>
                          ) : flag ? (
                            <span className="text-sm text-green-500">
                              {organizationName} is available.
                            </span>
                          ) : (
                            <span className="text-sm text-red-500">
                              This {checked ? 'organization' : 'user'} name{' '}
                              {organizationName} already exists.
                            </span>
                          )}
                        </span>
                      )}
                    </>
                  )}
                </div>
                <div>
                  <div className="mb-2">
                    {LABEL.REPOSITORY_NAME}
                    {!inputDisabled && (
                      <span className="pl-[3px] text-red-500">*</span>
                    )}
                  </div>
                  <div className="flex w-full items-center gap-3 rounded-2xl border border-gray-200 bg-lightPrimary pl-[20px] outline-none dark:!border-white/10 dark:!bg-navy-800">
                    <input
                      extra="mb-4 w-full"
                      value={repositoryName}
                      onChange={handleRepositoryChange}
                      type="text"
                      disabled={inputDisabled}
                      autoComplete="off"
                      className="placeholder:text-lightFourth md:text-md h-full w-full rounded-2xl bg-lightPrimary p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                      placeholder="Enter repository name"
                    />
                  </div>
                  {!inputDisabled && (
                    <>
                      {repositoryError && (
                        <div className="mt-1 text-sm text-red-500">
                          {repositoryError}
                        </div>
                      )}
                      {userInteractedWithRepo &&
                        repositoryName.trim() !== '' && (
                          <span className="ml-2 ">
                            {isRepoApiLoading ? (
                              <span className="logs-loader absolute right-[4%] top-[6.3rem]  -translate-y-1/2 transform text-xl "></span>
                            ) : repoFlag ? (
                              <BsFillCheckCircleFill className="text-inherit absolute right-[4%]  top-[7rem] h-6 w-6 -translate-y-1/2 transform text-xl text-green-500" />
                            ) : (
                              <RxCrossCircled className="text-inherit absolute right-[4%] top-[7rem] h-6 w-6 -translate-y-1/2 text-xl text-red-500" />
                            )}
                          </span>
                        )}
                      {userInteractedWithRepo &&
                        repositoryName.trim() !== '' && (
                          <span className="ml-2">
                            {isRepoApiLoading ? (
                              <span className="text-sm text-gray-250">
                                Checking availability...
                              </span>
                            ) : repoFlag ? (
                              <span className="text-sm text-green-500">
                                {repositoryName} is available.
                              </span>
                            ) : (
                              <span className="text-sm text-red-500">
                                This repository name {repositoryName} already
                                exists.
                              </span>
                            )}
                          </span>
                        )}
                    </>
                  )}
                </div>
              </div>
              {!githubTokenDATA && toggleState === `${TABS.BRANCH_DETAIL}` && (
                <div className="mb-2">
                  <p className="text-xs font-medium text-gray-20 dark:text-gray-600">
                    Note : <b>{checked ? 'Organization' : 'User'} Name</b> and{' '}
                    <b>Repository Name</b> in Kebab case or it will convert
                    automatically. (Example - "ConfixaProject convert it into
                    confixa-project")
                  </p>
                </div>
              )}
              {toggleState === `${TABS.BRANCH_DETAIL}` && (
                <div className="my-1 mt-[15px] grid grid-cols-2 gap-4">
                  <div>
                    <div className="mb-2 mt-2">{LABEL.GITHUB_USER}</div>
                    <div className="flex w-full items-center gap-3 rounded-2xl border border-gray-200 bg-lightPrimary pl-[20px] outline-none dark:!border-white/10 dark:!bg-navy-800">
                      <input
                        extra="mb-4 w-full"
                        value={gitUser}
                        type="text"
                        disabled={true}
                        autoComplete="off"
                        className="placeholder:text-lightFourth md:text-md h-full w-full rounded-2xl bg-lightPrimary p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="mb-2 mt-2">{LABEL.GITHUB_TOKEN}</div>
                    <div className="flex w-full items-center gap-3 rounded-2xl border border-gray-200 bg-lightPrimary pl-[20px] outline-none dark:!border-white/10 dark:!bg-navy-800">
                      <input
                        extra="mb-4 w-full"
                        value={maskInputValue(GithubToken)}
                        type="text"
                        autoComplete="off"
                        disabled={true}
                        className="placeholder:text-lightFourth md:text-md h-full w-full rounded-2xl bg-lightPrimary p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                        placeholder="Enter Github Token"
                      />
                    </div>
                    {GithubError && (
                      <div className="mt-1 text-sm text-red-500">
                        {GithubError}
                      </div>
                    )}
                  </div>
                </div>
              )}
              {!inputDisabled && (
                <div>
                  <div className="mt-[15px]">
                    <div>
                      <div className="mb-2">{LABEL.BRANCH_NAME}</div>
                      <div className=" flex w-full items-center gap-3 rounded-2xl border border-gray-200 bg-lightPrimary pl-[20px] outline-none dark:!border-white/10 dark:!bg-navy-800">
                        <input
                          extra="mb-4 w-full"
                          value={branchName}
                          onChange={(e) => setBranchName(e.target.value)}
                          type="text"
                          autoComplete="off"
                          className="placeholder:text-lightFourth md:text-md h-full w-full bg-lightPrimary p-3 text-sm text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                          placeholder="Enter Branches"
                        />
                        <button
                          className="flex w-40 items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:text-base"
                          onClick={() => addBranch(branchName)}
                        >
                          <span>{LABEL.ADD_LABEL}</span>
                        </button>
                      </div>
                    </div>
                    {branchListError && (
                      <div className="mt-1 text-sm text-red-500">
                        {branchListError}
                      </div>
                    )}
                  </div>

                  <div className="mt-4 flex">
                    {branchLists.map((item, index) => (
                      <div
                        key={index}
                        className="mr-4 flex items-center rounded-lg border border-gray-200 p-1 px-4"
                      >
                        {' '}
                        {item.name}
                        {!inputDisabled && (
                          <button
                            className="ml-4 h-[20px] w-[20px] justify-center rounded-full bg-black p-1 text-xs text-white"
                            onClick={() => removeBranch(item.name)}
                          >
                            <ImCross />
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {!inputDisabled && (
                <div className="mt-2 flex justify-end">
                  <button
                    type="button"
                    onClick={UpdateOrganization}
                    disabled={isUpdate || !flag || !repoFlag}
                    className={`
                    'flex md:text-base' w-40 items-center justify-center rounded-2xl bg-brand-500 p-3 text-sm text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200
                    ${
                      isUpdate === true || !flag || !repoFlag
                        ? 'cursor-not-allowed opacity-50'
                        : ''
                    }`}
                  >
                    <span>{LABEL.CREATE_LABEL}</span>
                  </button>
                </div>
              )}
            </>
          )}
        </Card>
      )}
    </div>
  )
}

export default CreateOrganization
