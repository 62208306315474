import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import ArtifactForm from './components/ArtifactForm'
import Card from 'components/card'
import { PiArrowSquareOutBold } from 'react-icons/pi'
import appConstants from 'common/config/appConstants'
import { getOrganization } from 'common/commonFunction'
import { useToaster } from 'common/Toaster'

const { ARTIFACT_REPOSITORY, TOAST } = appConstants

/**
 * Renders the main component for the artifact repository settings.
 *
 * @return {JSX.Element} The JSX element representing the main component.
 */
const ArtifactRepoMain = () => {
  const navigate = useNavigate()
  const { addToast } = useToaster()

  useEffect(() => {
    const fetchOrganizationData = async () => {
      try {
        const response = await getOrganization()
        if (response?.data?.code !== 200 || !response?.data?.data?.name) {
          navigate(`/settings/organization`)
        }
      } catch (error) {
        addToast({
          title: TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
        console.error('Error fetching organization data:', error)
      }
    }

    fetchOrganizationData()
  }, [navigate, addToast]) // Added dependencies to the effect

  const cloud_artifactRegistry = process.env.REACT_APP_CLOUD_ARTIFACT_REGISTRY

  return (
    <div className="mt-1 h-full w-full">
      <div className="flex items-center">
        <a
          href={cloud_artifactRegistry}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center text-brand-500 dark:text-brand-400"
        >
          <span className="mx-2 my-3 text-brand-500 dark:text-brand-400">
            {ARTIFACT_REPOSITORY.ARTIFACT_REPOSITORY_URL}
          </span>
          <span className="ml-1">
            <PiArrowSquareOutBold />
          </span>
        </a>
      </div>
      <Card extra="h-full mx-auto pb-3">
        <div className="rounded-[20px]">
          <ArtifactForm />
        </div>
      </Card>
    </div>
  )
}

export default ArtifactRepoMain
