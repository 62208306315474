import React, { useState, useEffect } from 'react'
import ReactQuill from 'react-quill'
import Card from 'components/card'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { GrAttachment } from 'react-icons/gr'
import CustomSelect from 'common/CustomSelect'
import appConstants from 'common/config/appConstants'
import { AiOutlineGlobal } from 'react-icons/ai'
import { MdEdit } from 'react-icons/md'
import TooltipHorizon from '../../components/tooltip'
import { useToaster } from 'common/Toaster'
import ApiCaller from 'common/services/apiServices'
import apiConfig from 'common/config/apiConfig'
import { AiOutlineClose } from 'react-icons/ai'
import { CiFileOn } from 'react-icons/ci'
import moment from 'moment'
import { formatFileSize } from 'common/commonFunction'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
} from '@chakra-ui/modal'
import { FaAnglesLeft } from 'react-icons/fa6'
import { useSpinner } from 'common/SpinnerLoader'

const TicketDetails = () => {
  let navigate = useNavigate()
  const { id } = useParams()
  const { addToast } = useToaster()
  const apiService = ApiCaller()
  const { SUPPORT_PRIORITY_LIST, TOAST, SUBMIT_TICKET_TYPES } = appConstants
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      description: '',
      attachments: [], // Initialize attachment as an empty array
    },
  })
  const [showEditor, setShowEditor] = useState(false)
  const [isEnvDisable, setIsEnvDisable] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])
  const type = SUBMIT_TICKET_TYPES
  const priority = SUPPORT_PRIORITY_LIST
  const [selectedPriority, setSelectedPriority] = useState()
  const [selectedType, setSelectedType] = useState()
  const [isOpen, setIsOpen] = useState(false)
  const [ticketDetails, setTicketDetails] = useState()
  const [initialType, setInitialType] = useState(null)
  const [initialPriority, setInitialPriority] = useState(null)
  const [isTicketsLoading, setIsTicketsLoading] = useState(false)
  const [selectedAttachments, setSelectedAttachments] = useState([])
  const [conversationData, setConversationData] = useState([])
  const [attachments, setAttachments] = useState([])
  const userName = JSON.parse(localStorage.getItem('userData'))
  const conversationSortByList = [
    { label: 'Newest first', value: 'Newest first' },
    { label: 'Oldest first', value: 'Oldest first' },
  ]
  const [selectedSortByOrderList, setSelectedSortByOrderList] = useState([
    conversationSortByList[0],
  ])
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      ['clean'],
    ],
  }
  const { showSpinner, hideSpinner } = useSpinner()
  const [errMsg, setErrMsg] = useState('')

  const TimeAgo = ({ createdAt }) => {
    const date = moment(createdAt)
    // Check if the date is valid
    if (!date.isValid()) {
      return <span>Invalid date</span>
    }
    const timeAgo = date.fromNow()
    return <span>{timeAgo}</span>
  }

  const fetchData = async () => {
    try {
      setIsTicketsLoading(true)
      const apiUrl = apiConfig.SUPPORT_TICKET_DETAILS.replace(':ticketId', id)
      const response = await apiService.apiCall('get', apiUrl)
      if (response?.data?.code === 200) {
        const responseData = response?.data?.data
        switch (responseData?.status) {
          case 2:
            responseData.statusLabel = 'Open'
            break
          case 3:
            responseData.statusLabel = 'Pending'
            break
          case 4:
            responseData.statusLabel = 'Resolved'
            break
          case 5:
            responseData.statusLabel = 'Closed'
            break
          default:
            responseData.statusLabel = 'Unknown'
        }
        setTicketDetails(responseData)
        const filesWithDisplaySize = responseData?.attachments?.map((file) => {
          return {
            ...file,
            displaySize: formatFileSize(file.size),
          }
        })
        setSelectedAttachments(filesWithDisplaySize)

        const getType = type.find((type) => type.value === responseData?.type)
        const getPriority = priority.find(
          (data) => data.priorityValue === responseData?.priority
        )
        setSelectedType(getType)
        setSelectedPriority(getPriority)
        setInitialType(getType)
        setInitialPriority(getPriority)
      } else {
        addToast({
          title: response?.data?.msg || TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
      }
    } catch (err) {
      console.log('Error fetching data:', err)
    } finally {
      setIsTicketsLoading(false)
    }
  }

  const fetchConversation = async () => {
    try {
      setIsTicketsLoading(true)
      const apiUrl = apiConfig.SUPPORT_TICKET_CONVERSATION.replace(
        ':ticketId',
        id
      )
      const response = await apiService.apiCall('get', apiUrl)
      if (response?.data?.code === 200) {
        const conversationData = response?.data?.data?.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at)
        })
        setConversationData(conversationData)
      } else {
        addToast({
          title: response?.data?.msg || TOAST.MESSAGES.ERROR.swwError,
          type: 'error',
        })
      }
    } catch (err) {
      console.log('Error fetching data:', err)
    } finally {
      setIsTicketsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
    fetchConversation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isLargeFile = (size) => size > 20 * 1024 * 1024

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files) // Convert FileList to an array
    const filesData = files?.map((file) => ({
      name: file.name,
      size: formatFileSize(file.size),
      rawSize: file.size, // Store raw size for comparison
      file, // Store the file object
    }))

    // Update attachments state
    setAttachments((prevData) => [...prevData, ...files])

    // Update selectedFiles state
    setSelectedFiles((prevFiles) => [...prevFiles, ...filesData])
  }

  const handleUpdateClick = () => {
    const hasTypeChanged = selectedType !== initialType
    const hasPriorityChanged = selectedPriority !== initialPriority

    if (hasTypeChanged || hasPriorityChanged) {
      updateTicket(false)
    } else {
      addToast({
        title: 'No changes detected.',
        type: 'info',
      })
    }
  }

  const updateTicket = async (closeIssue) => {
    setIsEnvDisable(true)
    showSpinner()
    try {
      const apiUrl = apiConfig.SUPPORT_TICKET_UPDATE.replace(':ticketId', id)
      let payload = {
        ...(selectedPriority && { priority: selectedPriority.priorityValue }),
        ...(selectedType && { type: selectedType.value }),
      }

      if (closeIssue) {
        payload.status = 5
      }

      const response = await apiService.apiCall('post', apiUrl, payload)
      if (response.data.code === 200) {
        addToast({
          title: response?.data?.msg,
          type: 'success',
        })
        fetchData()
        setIsOpen(false)
      } else {
        addToast({
          title: response.data.error,
          type: 'error',
        })
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      setIsEnvDisable(false)
      hideSpinner()
    }
  }

  const removeFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index))
    setAttachments((prevFiles) => prevFiles.filter((_, i) => i !== index))
    setErrMsg('')
  }

  const sortConversations = (conversationData, sortOption) => {
    return conversationData.sort((a, b) => {
      if (sortOption.value === 'Newest first') {
        return new Date(b.created_at) - new Date(a.created_at) // Descending order
      } else if (sortOption.value === 'Oldest first') {
        return new Date(a.created_at) - new Date(b.created_at) // Ascending order
      }
      return 0 // Default case, no sorting
    })
  }

  const handleConversationSorting = (sortOption) => {
    const sortedConversations = sortConversations(conversationData, sortOption)
    setConversationData(sortedConversations)
    setSelectedSortByOrderList(sortOption)
  }

  const newConversation = async (formValue) => {
    setIsEnvDisable(true)
    showSpinner()
    try {
      if (formValue.description || attachments.length > 0) {
        const data = new FormData()
        if (attachments?.length > 0) {
          let totalSize = 0
          let errorMsg = ''
          const MAX_SIZE_MB = 20
          const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024
          let isFileSizeValid = true

          for (let i = 0; i < attachments.length; i++) {
            const fileSize = attachments[i].size

            if (fileSize > MAX_SIZE_BYTES) {
              errorMsg = `File ${attachments[i].name} exceeds the 20MB limit.`
              isFileSizeValid = false
              break
            }

            totalSize += fileSize
          }
          const totalSizeMB = (totalSize / (1024 * 1024)).toFixed(2)

          if (isFileSizeValid && totalSize <= MAX_SIZE_BYTES) {
            for (let i = 0; i < attachments.length; i++) {
              data.append('attachments[]', attachments[i]) // Append the attachments
            }
          } else if (isFileSizeValid) {
            setErrMsg(
              `Total size ${totalSizeMB}MB exceeds ${MAX_SIZE_MB}MB limit.`
            )
            setIsEnvDisable(false)
            hideSpinner()
            return
          } else {
            setErrMsg(errorMsg)
            setIsEnvDisable(false)
            hideSpinner()
            return
          }
        }
        data.append('ticketId', id)
        if (formValue.description) {
          data.append('body', formValue.description)
        }
        const apiUrl = apiConfig.SUPPORT_CREATE_CONVERSATION
        const response = await apiService.apiCall('post', apiUrl, data)
        if (response.data.code === 200) {
          fetchConversation()
          setAttachments([])
          setSelectedFiles([])
          setErrMsg('')
          reset()
          setShowEditor(false)
          addToast({
            title: response?.data?.msg,
            type: 'success',
          })
        } else {
          addToast({
            title: response.data.error,
            type: 'error',
          })
        }
      }
    } catch (error) {
      addToast({
        title: TOAST.MESSAGES.ERROR.swwError,
        type: 'error',
      })
    } finally {
      setIsEnvDisable(false)
      hideSpinner()
    }
  }

  // const handleDownload = async (url, name) => {
  //   try {
  //     const response = await axios.get(url, {
  //       responseType: 'blob', // Important for downloading files
  //       timeout: 5000,
  //     });

  //     const blob = new Blob([response.data], { type: response.headers['content-type'] });
  //     const link = document.createElement('a');
  //     link.href = URL.createObjectURL(blob);
  //     link.download = name;

  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //     URL.revokeObjectURL(link.href); // Clean up
  //   } catch (error) {
  //     console.error('Download failed:', error.response ? error.response.data : error.message);
  //   }
  // }

  const handleBackBtn = () => {
    navigate(`/ticket-list`)
  }

  return (
    <Card extra={`flex flex-col w-full h-full bg-white`}>
      <>
        <Modal
          closeOnOverlayClick={false}
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false)
          }}
          className="!z-[1010]"
        >
          <ModalOverlay className="bg-[#000] !opacity-30" />
          <ModalContent className="!z-[1004] !m-auto max-h-[550px] max-w-[450px] rounded-[20px] bg-white bg-clip-border pb-[18px] pt-[16px] dark:!bg-navy-800 dark:text-white">
            <div className="mb-[4px] flex w-full items-center justify-between px-[30px]">
              <ModalHeader className="!grow text-2xl font-bold ">
                Update Ticket Details
              </ModalHeader>
              <ModalCloseButton />
            </div>
            <div className="my-4 border-b"></div>
            <ModalBody>
              <div className="px-[30px]">
                <div className="mb-2">Type</div>
                <div className="w-full items-center rounded-2xl border border-gray-200 outline-none dark:!border-white/10 dark:!bg-navy-800">
                  <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                      <CustomSelect
                        {...field}
                        value={selectedType}
                        options={type}
                        placeholderText="Select Type"
                        onChange={(value) => {
                          field.onChange(value)
                          setSelectedType(value)
                        }}
                      />
                    )}
                  />
                </div>

                <div className="mb-2 mt-[31px]">Priority</div>
                <div className="w-full items-center rounded-2xl border border-gray-200 outline-none dark:!border-white/10 dark:!bg-navy-800">
                  <Controller
                    name="priority"
                    control={control}
                    render={({ field }) => (
                      <CustomSelect
                        {...field}
                        options={priority}
                        value={selectedPriority}
                        placeholderText="Select Priority"
                        onChange={(value) => {
                          field.onChange(value)
                          setSelectedPriority(value)
                        }}
                      />
                    )}
                  />
                </div>

                <div className="flex justify-end">
                  <button
                    disabled={isEnvDisable}
                    className={`mt-4 rounded-xl bg-blueSecondary px-8 py-2 text-sm font-medium capitalize text-white transition duration-200 dark:bg-brand-400 
                               md:ml-2 ${
                                 isEnvDisable
                                   ? 'cursor-not-allowed opacity-50'
                                   : 'hover:bg-brand-800 active:bg-brand-700 dark:hover:bg-brand-300'
                               }`}
                    onClick={() => handleUpdateClick()}
                  >
                    Update
                  </button>
                </div>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>

        <div className="flex h-full w-full p-2 md:p-4">
          <div className="3xl:p-![18px] w-full !p-4">
            <button
              onClick={handleBackBtn}
              className="mb-[20px] flex w-fit items-center gap-2 text-sm font-bold text-blueSecondary transition hover:translate-x-1 dark:text-white"
            >
              <FaAnglesLeft strokeWidth={0.5} />
              <span>Back</span>
            </button>
            {isTicketsLoading ? (
              <>
                <div className="my-1 mt-2 flex animate-pulse items-center justify-between py-4 ">
                  <div className="flex items-center">
                    <div className="h-8 w-8 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                    <div className="ml-4 flex flex-col">
                      <div className="h-10 w-80 rounded bg-gray-300 dark:bg-gray-700"></div>
                      <div className="mt-2 h-3 w-60 rounded bg-gray-300 dark:bg-gray-700"></div>
                    </div>
                  </div>
                  <div className="h-8 w-8 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                </div>

                <div className="mt-8 animate-pulse">
                  <div className="mb-4 flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="h-8 w-8 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                      <div className="ml-4">
                        <div className="h-4 w-72 rounded bg-gray-300 dark:bg-gray-700"></div>
                      </div>
                      <div className="ml-2 h-6 w-20 rounded bg-gray-300 dark:bg-gray-700"></div>
                    </div>
                    <div className="flex">
                      <div className="mr-2 h-8 w-16 rounded bg-gray-300 dark:bg-gray-700"></div>
                      <div className="h-8 w-20 rounded bg-gray-300 dark:bg-gray-700"></div>
                    </div>
                  </div>

                  <div className="pl-14 pt-4">
                    <div className="mb-2 h-4 w-1/2 rounded bg-gray-300 dark:bg-gray-700"></div>
                  </div>

                  <div className="mt-4 flex items-center">
                    <div className="mr-2 h-8 w-8 rounded-full bg-gray-300 dark:bg-gray-700"></div>
                    <div className="h-10 flex-1 rounded-md border bg-gray-300 px-4 py-2 dark:border-gray-600 dark:bg-gray-700"></div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div>
                  <div className="flex justify-between">
                    <div className="flex py-3">
                      <div>
                        <AiOutlineGlobal className="mr-2 mt-1 h-8 w-8" />
                      </div>
                      <div className="ml-2">
                        <div className="text-4xl font-medium text-blueSecondary">
                          {ticketDetails?.subject} #{id}
                        </div>
                        <div className="text-gray-250">
                          Created on{' '}
                          {moment(ticketDetails?.created_at).format(
                            'ddd, D MMM, YYYY [at] h:mm A'
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="w-max rounded-xl py-1 text-sm">
                      <TooltipHorizon
                        extra="border border-gray-200 dark:border-gray-700"
                        trigger={
                          <p
                            className="flex cursor-pointer items-center rounded-full border p-1 font-bold text-[#000] hover:font-medium hover:text-black dark:text-white"
                            onClick={() => setIsOpen(true)}
                          >
                            <span>
                              <MdEdit className="text-xl" />
                            </span>
                          </p>
                        }
                        content="Edit"
                        placement="top"
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-8">
                  <div className="flex items-center justify-between">
                    <div className="flex">
                      <div className="mr-2 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-400 uppercase text-black dark:bg-brand-400">
                        {userName?.f_name.charAt(0)}
                      </div>
                      <div className="flex items-center font-semibold capitalize">
                        {userName?.f_name} {userName?.l_name}
                      </div>
                      <div className="ml-2 flex items-center">
                        <span className="mr-1">reported</span>{' '}
                        <TimeAgo createdAt={ticketDetails?.created_at} />
                      </div>
                      <div
                        className={`ml-2 flex inline-flex items-center rounded border px-1 text-sm  ${
                          ticketDetails?.statusLabel === 'Open'
                            ? 'border-lightOrangeRed bg-OrangeRed text-darkOrangeRed'
                            : 'border-gray-400 bg-gray-200 text-gray-800'
                        }`}
                      >
                        {ticketDetails?.statusLabel}
                      </div>
                      <div
                        className={`ml-2 flex inline-flex items-center rounded border border-yellow-400 bg-yellow-200 px-1 text-sm text-yellow-800`}
                      >
                        {initialPriority?.label}
                      </div>
                      <div
                        className={`ml-2 flex inline-flex items-center rounded border border-green-400 bg-green-200 px-1 text-sm text-green-800`}
                      >
                        {ticketDetails?.type}
                      </div>
                    </div>
                    {ticketDetails?.status !== 5 && (
                      <div className="flex">
                        {/* <button
                          type="button"
                          onClick={() => setShowEditor(true)}
                          className="flex items-center gap-2 rounded-md bg-blueSecondary px-4 py-2 text-sm font-medium text-white transition duration-200 hover:bg-brand-800 dark:bg-brand-400 dark:hover:bg-brand-300"
                        >
                          Reply
                        </button> */}
                        <button
                          type="button"
                          onClick={() => updateTicket(true)}
                          className="text-Black ml-2 flex items-center rounded-md border border-gray-400 bg-gray-200 px-4 py-2 text-sm font-medium transition duration-200 dark:text-black"
                        >
                          Close ticket
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="pl-10 pt-2">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: ticketDetails?.description,
                      }}
                    />
                    {selectedAttachments?.length !== 0 && (
                      <div className="row mt-4">
                        <div>Attachments ({selectedAttachments?.length})</div>

                        {selectedAttachments?.map((file, index) => (
                          <div
                            key={index}
                            className="mr-2 mt-2 flex inline-flex w-1/4 cursor-pointer items-center gap-2 border text-sm text-navy-700 dark:text-white"
                          >
                            <a
                              href={file.attachment_url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="flex"
                            >
                              {/* <button
                              onClick={() => handleDownload(file.attachment_url, file.name)}
                              // href={data.attachment_url}
                              // target="_blank"
                              // rel="noopener noreferrer"
                              className="flex"
                            // download
                            > */}
                              {file.content_type.startsWith('image/') ? (
                                <div className="p-2">
                                  <img
                                    src={file.attachment_url}
                                    alt={file.name}
                                    className="h-12 w-12"
                                  />
                                </div>
                              ) : (
                                <div className="relative flex items-center justify-center">
                                  <CiFileOn className="h-16 w-16 text-lightBlack dark:text-white" />
                                  <span className="absolute text-sm text-lightBlack dark:text-white">
                                    {file.name.split('.').pop()}
                                  </span>
                                </div>
                              )}
                              <div className="mt-3">
                                <div>{file.name}</div>
                                <div>{file.displaySize}</div>
                              </div>
                              {/* </button> */}
                            </a>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  {conversationData?.length !== 0 && (
                    <div className="conversationSortByList mt-2 flex items-center justify-end">
                      <div>Sorted by </div>
                      <div className="w-38 flex items-center gap-3 rounded-2xl outline-none dark:!border-white/10 dark:!bg-navy-800">
                        <CustomSelect
                          options={conversationSortByList}
                          onChange={handleConversationSorting}
                          value={selectedSortByOrderList}
                          extra="mb-4 w-38"
                          className="placeholder:text-lightFourth md:text-md w-38 h-full rounded-2xl p-3 text-sm text-brand-400 outline-none dark:!bg-navy-800 dark:text-white"
                        />
                      </div>
                    </div>
                  )}
                  {conversationData?.map((conversation, index) => (
                    <div className="w-full">
                      <div className="mt-4 flex">
                        <div className="mr-2 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-gray-400 uppercase text-black dark:bg-brand-400">
                          {conversation?.userName.charAt(0)}
                        </div>
                        <div className="w-full rounded-md bg-gray-100 p-3 dark:bg-gray-800">
                          <div className="flex">
                            <div className="flex items-center text-sm font-semibold capitalize">
                              {conversation?.userName}
                            </div>
                            <div className="ml-2 flex items-center  text-sm">
                              <span className="mr-1">said</span>{' '}
                              <TimeAgo createdAt={conversation?.created_at} />
                            </div>
                          </div>
                          <div
                            className="mt-3"
                            dangerouslySetInnerHTML={{
                              __html: conversation?.body,
                            }}
                          />
                          {conversation?.attachments?.length !== 0 && (
                            <div className="mt-2 text-sm">
                              Attachments ({conversation?.attachments?.length})
                            </div>
                          )}
                          {conversation?.attachments?.map((data, index) => (
                            <div
                              key={index}
                              className="mt-2 flex w-1/4 cursor-pointer items-center gap-2 border text-sm text-navy-700 dark:text-white"
                            >
                              {/* <button
                                onClick={() => handleDownload(data.attachment_url, data.name)}
                                // href={data.attachment_url}
                                // target="_blank"
                                // rel="noopener noreferrer"
                                className="flex items-center"
                              // download
                              > */}
                              <a
                                href={data.attachment_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center"
                              >
                                {data.content_type.startsWith('image/') ? (
                                  <div className="p-2">
                                    <img
                                      src={data.attachment_url}
                                      alt={data.name}
                                      className="h-12 w-12 "
                                    />
                                  </div>
                                ) : (
                                  <div className="relative flex items-center justify-center">
                                    <CiFileOn className="h-16 w-16 text-lightBlack dark:text-white" />
                                    <span className="absolute text-sm text-lightBlack dark:text-white">
                                      {data.name.split('.').pop()}
                                    </span>
                                  </div>
                                )}
                                <div className="mt-1">
                                  <div>{data.name}</div>
                                  <div>{formatFileSize(data.size)}</div>
                                </div>
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}

                  {ticketDetails?.status !== 5 && (
                    <form onSubmit={handleSubmit(newConversation)}>
                      <div className="mt-4 flex w-full">
                        <div className="mr-2 flex h-8 w-8  cursor-pointer items-center justify-center rounded-full bg-gray-400 uppercase text-black dark:bg-brand-400">
                          {userName?.f_name.charAt(0)}
                        </div>
                        {!showEditor && (
                          <button
                            className="flex w-full justify-start border px-4 py-2 font-medium"
                            onClick={() => setShowEditor(true)}
                          >
                            Click here to add comment
                          </button>
                        )}
                        {showEditor && (
                          <div className="w-full">
                            <div className="w-full rounded-2xl border border-gray-200 outline-none dark:border dark:!border-white/10 dark:!bg-navy-800">
                              <Controller
                                name="description"
                                control={control}
                                rules={{
                                  required: 'Description is required',
                                  validate: (value) => {
                                    if (
                                      value === '' ||
                                      value === '<p><br></p>'
                                    ) {
                                      return 'Description is required'
                                    }
                                  },
                                }}
                                render={({ field }) => (
                                  <ReactQuill
                                    {...field}
                                    onBlur={(e) => field.onBlur()}
                                    value={field.value || ''}
                                    className="custom-quill w-full rounded-2xl text-sm  text-navy-700 outline-none dark:!bg-navy-800 dark:text-white"
                                    theme="snow"
                                    modules={modules}
                                    placeholder="Start typing your text here..."
                                  />
                                )}
                              />
                            </div>
                            {errors.description && (
                              <span className="error-message">
                                {errors.description.message}
                              </span>
                            )}

                            <div className="mb-2 mt-[20px]">
                              <button
                                type="button"
                                className="mt-2 flex items-center rounded-xl px-4 py-2 text-sm font-medium text-blueSecondary transition duration-200 focus:border focus:border-gray-200 dark:text-white"
                                onClick={() =>
                                  document.getElementById('attachment').click()
                                }
                              >
                                <GrAttachment className="mr-1 text-black dark:text-white" />
                                Attachment
                              </button>
                              <input
                                type="file"
                                id="attachment"
                                multiple
                                className="hidden"
                                onChange={handleFileChange}
                              />

                              {selectedFiles?.map((file, index) => (
                                <div
                                  key={index}
                                  className={`mr-2 mt-2 flex inline-flex items-center border p-2 text-sm text-navy-700 dark:text-white ${
                                    isLargeFile(file.rawSize)
                                      ? 'border-red-500 line-through'
                                      : 'border-gray-300'
                                  }`}
                                >
                                  <button
                                    type="button"
                                    onClick={() => removeFile(index)}
                                    className="mx-2 text-red-500 hover:text-red-700"
                                  >
                                    <AiOutlineClose />
                                  </button>
                                  <span>{file.name}</span>
                                  <span className="ml-6">{file.size}</span>
                                </div>
                              ))}
                            </div>
                            {errMsg && (
                              <span class="error-message mt-3">{errMsg}</span>
                            )}
                            <div className="mt-7 grid md:flex lg:flex">
                              <div className="flex w-full items-center">
                                <button
                                  disabled={isEnvDisable}
                                  className={`rounded-xl bg-blueSecondary px-8 py-2 text-sm font-medium capitalize text-white transition duration-200 dark:bg-brand-400 
                                                md:${
                                                  isEnvDisable
                                                    ? 'cursor-not-allowed opacity-50'
                                                    : 'hover:bg-brand-800 active:bg-brand-700 dark:hover:bg-brand-300'
                                                }`}
                                  type="submit"
                                >
                                  Reply
                                </button>
                                <button
                                  type="button"
                                  className="rounded-xl bg-gray-400 px-8 py-2 text-sm font-medium capitalize text-black transition duration-200 hover:bg-gray-600 
                                                active:bg-gray-700 dark:bg-gray-400 dark:hover:bg-gray-300 md:ml-2"
                                  onClick={() => setShowEditor(false)}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </form>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </>
    </Card>
  )
}

export default TicketDetails
